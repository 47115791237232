<template>
  <div class="product-item">
    <Card
      :title="product.name"
      type="image"
      v-bind:class="{ selected, unpurchasable: isUnpurchasable() }"
    >
      <VueLoadImage>
        <img slot="image" :src="product.image" draggable="false" />
        <div slot="preloader">
          <img
            src="@/assets/images/preloader.png"
            class="preloader"
            draggable="false"
          />
        </div>
        <img
          slot="error"
          src="@/assets/images/staking/placeholder.gif"
          draggable="false"
        />
      </VueLoadImage>
      <span class="price" v-bind:class="{ selected }">
        {{ totalPrice }} $xSLIME<span v-if="quantity > 1">
          for {{ quantity }}
        </span>
      </span>
      <span v-if="product.redeemable || isLootboxProduct(product)"
        ><!-- hide counter --></span
      >
      <span
        class="supply"
        v-bind:class="{ selected }"
        v-else-if="product.remaining >= 0 && product.supply !== null"
      >
        {{ product.remaining }} / {{ product.supply }} LEFT
      </span>
      <span
        class="purchases"
        v-bind:class="{ selected }"
        v-else-if="product.remaining === null && product.purchases > 0"
      >
        {{ product.purchases }} TICKETS
      </span>

      <div class="selection" v-bind:class="{ selected }">
        <span v-if="selected === true">&check;</span>
        <span v-else>&plus;</span>
      </div>
      <div
        class="product-description"
        v-if="product.description"
        v-html="productDescriptionFormatted(product)"
      ></div>
      <div class="product-expiry" v-if="product.expiresAt">
        {{ productExpiryFormatted(product) }}
      </div>
      <ul class="product-links">
        <li v-if="product.twitter">
          <a :href="product.twitter" target="_blank" class="social twitter">
            <TwitterSvg
              :style="{ width: '24px !important', height: '24px !important' }"
            />
          </a>
        </li>
        <li v-if="product.discord">
          <a :href="product.discord" target="_blank" class="social discord">
            <DiscordSvg
              :style="{ width: '24px !important', height: '24px !important' }"
            />
          </a>
        </li>
      </ul>
      <div v-if="isWhitelistProduct(product)" class="whitelisted">
        <Button @click="$emit('whitelistOf', product)">{{
          whitelistButtonText(product)
        }}</Button>
      </div>
    </Card>
    <div v-if="!isUnpurchasable() && purchaseLimit !== 1" class="buy-now-group">
      <Button
        :class="{ minus: true, disabled: quantity - 1 < 1 }"
        @click="decrease()"
        v-long-press="500"
        @long-press-start="longDecrease"
        @long-press-stop="killTimers"
        >-</Button
      >
      <Button class="buy-now" @click="$emit('click', product, quantity)">
        {{ $t("generic.buy_now_qty", { quantity }) }}
      </Button>
      <Button
        :class="{ plus: true, disabled: quantity + 1 > purchaseLimit }"
        @click="increase()"
        v-long-press="500"
        @long-press-start="longIncrease"
        @long-press-stop="killTimers"
        >+</Button
      >
    </div>
    <div v-else-if="!isUnpurchasable()" class="buy-now-group buy-now-group-100">
      <Button class="buy-now" @click="$emit('click', product, quantity)">
        {{ $t("generic.buy_now") }}
      </Button>
    </div>
    <div v-else class="buy-now-group buy-now-group-100 unpurchasable">
      <Button :disabled="true">{{ $t("generic.unavailable") }}</Button>
    </div>
  </div>
</template>

<script>
import Card from "../ui/Card";
import Button from "../ui/Button";
import moment from "moment";
import { marked } from "marked";
import VueLoadImage from "vue-load-image";
import LongPress from "vue-directive-long-press";

import TwitterSvg from "../../assets/images/socials/Twitter.svg";
import DiscordSvg from "../../assets/images/socials/Discord.svg";

export default {
  name: "ProductItem",
  props: {
    product: Object,
    selected: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    "long-press": LongPress,
  },
  computed: {
    totalPrice() {
      return this.product.price * this.quantity;
    },
    purchaseLimit() {
      return this.product.purchaseLimit ?? 999;
    },
  },
  components: {
    Card,
    Button,
    VueLoadImage,
    TwitterSvg,
    DiscordSvg,
  },
  methods: {
    productExpiryFormatted(product) {
      const expiresAt = moment(product.expiresAt).utc();
      const word = expiresAt.diff(moment()) > 0 ? "Ends" : "Ended";
      return `${word} ${expiresAt.fromNow()}`;
    },
    productDescriptionFormatted(product) {
      return marked.parse(product.description);
    },
    isWhitelistProduct(product) {
      return [1, 2, 3, 4, 5, 6] // hardcoded category id
        .some((c) => product.categories.map((pc) => pc.id).includes(c));
    },
    isLootboxProduct(product) {
      return [9] // hardcoded category id
        .some((c) => product.categories.map((pc) => pc.id).includes(c));
    },
    whitelistButtonText(product) {
      return product.categories.map((pc) => pc.id).includes(1)
        ? "View Whitelist"
        : "View Tickets";
    },
    isUnpurchasable() {
      const vm = this;
      const expiresAt = moment(vm.product.expiresAt).utc();
      return (
        (vm.product.remaining !== null && vm.product.remaining <= 0) ||
        expiresAt.diff(moment()) <= 0
      );
    },
    increase() {
      const vm = this;
      if (vm.quantity + 1 <= vm.purchaseLimit) {
        vm.quantity += 1;
        vm.$emit("change", vm.quantity);
      }
    },
    longIncrease() {
      const vm = this;
      vm.killTimers();
      vm.quantityTimer = setInterval(() => vm.increase(), 75);
    },
    decrease() {
      const vm = this;
      if (vm.quantity - 1 >= 1) {
        vm.quantity -= 1;
        vm.$emit("change", vm.quantity);
      }
    },
    longDecrease() {
      const vm = this;
      vm.killTimers();
      vm.quantityTimer = setInterval(() => vm.decrease(), 75);
    },
    killTimers() {
      const vm = this;
      clearInterval(vm.quantityTimer);
      vm.quantityTimer = null;
    },
  },
  data() {
    return {
      quantityTimer: null,
      quantity: 1,
    };
  },
};
</script>

<style lang="scss">
.product-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &.unpurchasable {
      opacity: 0.5;
      + button {
        opacity: 0.5;
      }
    }
  }
  .card-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: $font-sm;
    @include threedee-text(2px);
    margin-bottom: 1.5rem;
    min-height: 3rem;
  }
  .card-body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 400px;
    height: 100%;
    max-width: 300px;
    background: $white !important;
    transition: all 0.2s;
    // cursor: pointer;
    // transform: scale(0.95);
    width: 100%;
    > img {
      height: 200px !important;
      width: 100% !important;
      object-fit: cover;
      border-bottom: 2px solid $black;
    }
    // &:hover {
    //   transform: scale(1) translateY(0.5rem);
    //   .selection {
    //     opacity: 1;
    //   }
    // }
    .price,
    .supply,
    .purchases {
      padding: 0.75rem 0.7rem;
      border-radius: 25px;
      position: absolute;
      font-size: $font-xxxs;
    }
    .price {
      top: 0.5rem;
      left: 0.5rem;
      background: rgba($green, 0.75);
      &.selected {
        background: rgba($pink, 1);
        color: $white;
      }
    }
    .supply,
    .purchases {
      top: 175px;
      right: 0.5rem;
      background: rgba($purple, 0.75);
      color: $white;
      &.selected {
        background: rgba($purple, 1);
        // background: rgba($green, 1);
      }
    }
    .purchases {
      background: rgba($blue, 0.75);
    }
    .selection {
      opacity: 0;
      transition: opacity 0.2s;
      &.selected {
        opacity: 1;
      }
    }
  }

  .selected .card-body {
    @include threedee-box(3px, $pink-hot);
    background: $pink-hot;
    transform: scale(1); // translateY(0.5rem);
    > img {
      border-bottom: 2px solid $pink-hot;
    }
  }

  .selection {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    border-radius: 100px;
    background: rgba($white, 0.5);
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    &.selected {
      background: $pink;
      border: 2px solid $white;
      box-sizing: border-box;
    }
  }

  .product-description,
  .product-expiry {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    font-size: 11px;
    line-height: 150%;
    text-align: center;
    color: $black;
    font-weight: 600;

    p {
      padding: 0;
      margin-top: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .product-expiry {
    line-height: normal;
    padding: 0 0 0.5rem 0;
    color: $red;
  }
  .product-links {
    display: flex;
    list-style: none;
    padding: 0;
    margin: -1px -1px;
    padding-bottom: 1rem;
    justify-content: center;
    li {
      margin: 0 0.25rem;
      a {
        text-decoration: none;
        img {
          filter: brightness(0);
        }
      }
    }
  }
  .whitelisted {
    padding: 0 1rem 1rem 1rem;
    margin-top: -1rem;
    button {
      font-size: $font-xxxs;
      @include threedee-box(2px, $black);
    }
  }
  .buy-now-group {
    width: 100%;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    margin-top: 2rem;

    .minus,
    .plus {
      font-size: $font-xxs;
      padding: 0;
      padding: 0rem;
      width: unset;
      height: 35px;
      margin: 0;
      color: $black;
    }

    // .minus {
    //   background: $red;
    // }
    // .plus {
    //   background: $green;
    // }

    .buy-now {
      background: $green;
      margin: 0 0.8rem;
      font-size: $font-xs;
      // padding: 0rem;
      width: unset;
    }

    &.unpurchasable {
      opacity: 0.5;
      button {
        font-size: $font-xs;
        &.disabled {
          opacity: 1;
        }
      }
    }

    &-100 {
      grid-template-columns: 100%;
      > .buy-now {
        margin: 0;
      }
    }
  }
}
</style>
