<template>
  <div class="marketplace-hero">
    <Navbar />
    <div class="container">
      <div class="page-title">
        <h2>{{ $t("marketplace.header") }}</h2>
        <div class="checkout" v-if="$store.state.token !== null">
          <div class="balance">
            {{ $t("generic.balance") }}: {{ user.claim.balance }} $xSLIME
          </div>
          <div class="rewards">
            {{ $t("generic.farmed") }}: {{ user.claim.rewards }} $xSLIME
          </div>
          <router-link to="/purchases" class="purchases">
            {{ $t('marketplace.view_order_history')}}
          </router-link>
          <router-link to="/settings" class="settings"> {{ $t("generic.settings") }} </router-link>
        </div>
        <div class="connect-wallet" v-else>
          <Button @click="connectWallet">
            {{ $t("generic.connect_wallet") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "./Navbar";
import Button from "../ui/Button.vue";

export default {
  name: "MarketplaceHero",
  props: {},
  components: {
    Navbar,
    Button,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    async connectWallet() {
      const vm = this;

      vm.$store.state.transactionModal = "authenticate";
      vm.$store.state.tx.started = true;
      vm.$store.state.tx.statusText = "Authenticating your wallet...";

      try {
        if (
          !(await vm.$store.dispatch("authenticate", { action: "marketplace" }))
        ) {
          throw { message: "Unable to authenticate wallet" };
        }

        vm.$store.state.tx.completed = true;
        vm.$store.state.tx.statusText = `Successfully logged in!`;
      } catch (err) {
        vm.$store.state.tx.failed = true;
        vm.$store.state.tx.failedMsg =
          err.message || "Transaction failed, unknown error";
        console.log(err);
      }
    },
  },
  mounted() {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.marketplace-hero {
  padding: 1rem 0 0 0;
  min-height: 15rem;
  position: relative;
  // overflow: hidden;

  > .container {
    padding-top: 5rem;
    display: grid;

    .page-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      h2 {
        transform: rotate(-5deg);
        color: $gold;
        position: relative;
        small {
          position: absolute;
          right: 0;
          bottom: -18px;
          font-size: $font-sm;
          color: $blue;
        }
      }

      .connect-wallet {
        button {
          font-size: $font-xs;
          @include threedee-box(2px, $black);
        }
      }

      .checkout {
        display: flex;
        align-self: center;
        gap: 1rem;

        .rewards,
        .balance,
        .purchases,
        .settings {
          padding: 0.75rem 1.25rem;
          font-size: $font-xxxs;
          font-weight: 600;
          background: $white;
          border-radius: 10px;
          text-decoration: none;
          transition: all 0.2s;
        }
        .purchases {
          &:hover {
            transform: scale(1.03);
          }
        }
        .balance {
          background: $green;
          color: $black;
          @include threedee-box(3px, $black);
        }
        .rewards {
          background: $pink;
          color: $black;
          @include threedee-box(3px, $black);
        }
        .purchases {
          background: $sand;
          color: $black;
          @include threedee-box(3px, $black);
          cursor: pointer;
        }
        .settings {
          background: $pink-alt;
          color: $black;
          @include threedee-box(3px, $black);
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
  .marketplace-hero {
    min-height: 5rem;
    > .container {
      .page-title {
        flex-direction: row;

        h2 {
        }

        .connect-wallet {
          padding-bottom: 1.5rem;
          button {
          }
        }

        .checkout {
          gap: 1rem;
          .rewards,
          .balance,
          .purchases,
          .settings {
            font-size: $font-xxs;
            margin-left: 1rem;
            margin-bottom: 1rem;
          }
          .balance {
          }
          .rewards {
          }
        }
      }
    }
  }
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
