<template>
  <div class="bridge-item">
    <Card
      type="image"
      v-bind:class="{ selected }"
      @click="$emit('click', item)"
    >
      <img
        :src="inventoryItemImage(item)"
        @error="replaceByDefault"
        draggable="false"
      />
      <span v-if="!hideQuantity" class="quantity" v-bind:class="{ selected }"
        >QTY {{ item.quantity }}</span
      >
      <div class="selection" v-bind:class="{ selected }">
        <span v-if="selected === true">&times;</span>
        <span v-else>&plus;</span>
      </div>
    </Card>
    <div class="name">{{ item.name }}</div>
  </div>
</template>

<script>
import Card from "../ui/Card";
export default {
  name: "BridgeItem",
  props: {
    item: Object,
    selected: Boolean,
    hideQuantity: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Card,
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = require("@/assets/images/staking/placeholder.gif");
    },
    inventoryItemImage(item) {
      if (item.tokenId !== null) {
        return require(`@/assets/images/lootbox/items/${item.tokenId}.png`);
      } else {
        try {
          return require(`@/assets/images/lootbox/contracts/${item.contractId}.png`);
        } catch (err) {
          return require(`@/assets/images/lootbox/contracts/${item.contractId}.gif`);
        }
      }
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.bridge-item {
  .card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card-title {
    font-size: $font-lg;
    margin-bottom: 1.5rem;
  }

  .card-body {
    max-width: 300px;
    transition: all 0.2s;
    cursor: pointer;
    background: $white !important;
    // transform: scale(0.95);

    &:hover {
      // transform: scale(1.05);
      .selection {
        opacity: 1;
      }
    }
    .quantity {
      padding: 1rem 0.75rem;
      border-radius: 25px;
      bottom: 0.5rem;
      right: 0.5rem;
      position: absolute;
      color: $white;
      background: rgba($black, 0.75);
      &.selected {
        background: rgba($pink, 1);
        color: $white;
      }
    }
    .selection {
      opacity: 0;
      transition: opacity 0.2s;
      &.selected {
        opacity: 1;
      }
    }
  }

  .selected .card-body {
    @include threedee-box(3px, $pink-hot);
    background: $pink-hot;
    // transform: scale(1.05);
  }

  .selection {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    border-radius: 100px;
    background: rgba($black, 0.5);
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    &.selected {
      background: $pink;
      border: 2px solid $white;
      box-sizing: border-box;
    }
  }

  .name {
    text-align: center;
    margin-top: 1rem;
  }
}
</style>
