<template>
  <div id="app">
    <router-view />
    <Footer />
    <StakeModal v-if="$store.state.stakeModal" />
    <TransactionModal v-if="$store.state.transactionModal" />
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import StakeModal from "./components/MintModal/StakeModal.vue";
import TransactionModal from "./components/MintModal/TransactionModal.vue";

export default {
  name: "App",
  components: {
    Footer,
    StakeModal,
    TransactionModal,
  },
};
</script>

<style lang="scss">
html {
  height: 100%;
  background: black;
}

body {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  font-family: $font-secondary;
}

.container {
  max-width: $screen-lg;
  margin: 0 auto;
  position: relative;
}

h2 {
  font-family: $font-primary;
  color: $white;
  font-size: $font-lg;
  font-weight: 600;
  text-transform: uppercase;
  @include threedee-text(3px, $black);
  margin: 1rem 0 2rem 0;
}

h3 {
  font-family: $font-primary;
  color: $white;
  font-size: $font-md;
  font-weight: 600;
  text-transform: uppercase;
  @include threedee-text(2px, $black);
}

@media screen and (min-width: $screen-md) {
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
