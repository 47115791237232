<template>
  <div class="bridge-loot">
    <BridgeItemOutModal
      v-if="bridgeItemOutModal !== null"
      @understood="bridgeItemOutUnderstood"
      @closePrompt="bridgeItemOutModal = null"
    />
    <div class="swap swap-items">
      <Card title="withdraw loot">
        <div class="item-container">
          <p class="tip">
            {{ $t("inventory.same_loot_withdraw_tip") }}
          </p>
          <div class="onchain">
            <BridgeItem
              v-for="(item, idx) in inventory"
              :key="idx"
              :item="item"
              :selected="selectedItem(item)"
              @click="selectItem"
              :hideQuantity="true"
            />
            <div class="no-items" v-if="!inventory.length">
              {{ $t("inventory.no_inventory") }}
            </div>
          </div>
        </div>
        <form @submit.prevent="bridgeItemOutModal = true">
          <Button
            v-if="$store.state.account === null"
            type="button"
            :disabled="true"
          >
            {{ $t("generic.claim") }}
          </Button>
          <Button v-else>{{ $t("generic.claim") }}</Button>
        </form>
      </Card>
    </div>
    <div class="disclaimer">
      <p>&middot; {{ $t("inventory.disclaimer.loot.p1") }}</p>
      <p>&middot; {{ $t("inventory.disclaimer.loot.p2") }}</p>
    </div>
  </div>
</template>

<script>
import Card from "../../ui/Card";
import Button from "../../ui/Button";
import BridgeItem from "../BridgeItem";
import BridgeItemOutModal from "./BridgeLootOutModal";
import axios from "axios";
import SquishiverseLootboxPool from "../../../data/abi/SquishiverseLootboxPool.json";
import { BigFloat } from "bigfloat.js";
export default {
  components: { Card, Button, BridgeItem, BridgeItemOutModal },
  name: "BridgeLoot",
  computed: {
    inventory() {
      return (this.$store.state.user.tokenInventory || []).sort(
        (a, b) => b.contractId - a.contractId
      );
    },
  },
  methods: {
    selectItem(item) {
      // this.selection = this.selection === item ? null : item;
      const vm = this;
      if (vm.selectedItem(item)) {
        vm.selection = [...vm.selection].filter((e) => e.id !== item.id);
      } else {
        if (vm.selection.map((e) => e.contractId).includes(item.contractId)) {
          vm.selection = [...vm.selection, item];
        } else {
          vm.selection = [item];
        }
      }
    },
    selectedItem(item) {
      return this.selection.map((e) => e.id).includes(item.id);
    },
    bridgeItemOutUnderstood() {
      const vm = this;
      vm.bridgeItemOutModal = null;
      vm.swapOffchainItemToOnchain();
    },
    async swapOffchainItemToOnchain() {
      console.log("Swap Loot Off-Chain");
      const vm = this;

      // show modal
      vm.$store.state.transactionModal = "bridge_lootbox";
      await vm.$store.commit("setTransactionState", {
        started: true,
        statusText: "Retrieving signature...",
      });

      try {
        // tokens quantity
        console.log(vm.selection);

        // try to fetch the signature and stuff
        const { data: bridgeOutwardData } = await axios.post(
          `${vm.$apiUrl}/bridge/token/outward`,
          { tokenInventoryIds: vm.selection.map((s) => s.id) },
          { headers: { Authorization: `Bearer ${vm.$store.state.token}` } }
        );
        const bridgeOutward = bridgeOutwardData.message;

        if (bridgeOutward.old === true) {
          await vm.$store.commit("setTransactionState", {
            statusText: `An nft claim was made previously. Please claim it.`,
          });
        } else {
          await vm.$store.commit("setTransactionState", {
            statusText: `Claiming NFTs...`,
          });
        }

        // begin txn
        const sender = vm.$store.state.account;
        const contract = new vm.$store.state.web3.eth.Contract(
          SquishiverseLootboxPool,
          process.env.VUE_APP_LOOTBOX_POOL_CONTRACT_ADDRESS
        );
        const gasPrice = await vm.$store.state.web3.eth.getGasPrice();
        let contractFunction = contract.methods.claimErc721(
          sender,
          bridgeOutward.contractAddress,
          bridgeOutward.tokenIds,
          bridgeOutward.oldBlock,
          bridgeOutward.newBlock,
          bridgeOutward.signature
        );
        const gas = await contractFunction.estimateGas({ from: sender });
        const gasAmped = new BigFloat(gasPrice)
          .mul(vm.$gasPriceBoost)
          .floor()
          .toString();
        console.log({ gasPrice, gas, gasAmped });
        await new Promise((resolve) => {
          contractFunction
            .send({
              from: sender,
              gas: parseInt(vm.$gasLimitBoost * gas),
              gasPrice: gasAmped,
            })
            .on("transactionHash", function (hash) {
              vm.$store.commit("setTransactionState", {
                statusText: `Claiming NFT...`,
                hash,
              });
            })
            .on("receipt", function (receipt) {
              console.log("TX RECEIPT");
              console.log(receipt);
              vm.$store.commit("setTransactionState", {
                statusText: `${bridgeOutward.quantity} NFTs have been claimed...`,
                completed: true,
              });
              vm.$store.dispatch("fetchUserTokenInventory");
              vm.amount = "";
              vm.selection = [];
              resolve(true);
            })
            .on("error", function (error) {
              vm.$store.state.tx.failed = true;
              vm.$store.state.tx.failedMsg =
                error.message || "Transaction Failed. Please try again.";
              resolve(false);
            });
        });
      } catch (err) {
        console.log(err);
        vm.handleTransactionError(err);
      }
    },
    handleTransactionError(err) {
      const vm = this;
      vm.$store.state.tx.failed = true;
      if (err.response) {
        const { message } = err.response.data;
        vm.$store.state.tx.failedMsg =
          message || "Transaction failed, unknown error";
        return;
      }
      try {
        const message = /execution reverted:\s(.*)/.exec(err.message)[1];
        vm.$store.state.tx.failedMsg = message;
      } catch (regerr) {
        const possibleError = err.message || "";
        if (possibleError.includes("insufficient funds")) {
          vm.$store.state.tx.failedMsg = "Not enough funds";
        } else {
          vm.$store.state.tx.failedMsg = "Transaction failed, unknown error";
        }
      }
    },
  },
  data() {
    return {
      selection: [],
      bridgeItemOutModal: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.bridge-loot {
  .swap {
    display: grid;
    width: 100%;

    .card {
      .card-title {
        font-size: $font-lg;
        color: $green;
      }
    }

    .tip {
      margin-top: 1rem;
    }

    .coin-container {
      text-align: center;
      margin-bottom: 1rem;
      .coin {
        width: 64px;
      }
    }
    .form-group {
      display: flex;
      flex-direction: row;
      text-align: left;

      label {
        margin: 0.5rem 0 0.5rem 0;
      }

      input:disabled {
        opacity: 0.8;
        cursor: not-allowed;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      button {
        height: 50px;
        border: 3px solid $black;
        box-shadow: none !important;
        // @include threedee-box(3px, transparent);
      }

      .form-input {
        width: 100%;
        position: relative;
        margin-right: 0.5rem;
        input {
          width: 100%;
          color: $black;
          padding: 0.75rem 1rem;
          border-radius: $radius-md;
          font-size: $font-xs;
          border: 3px solid $black;
          box-sizing: border-box;
        }
        .max {
          position: absolute;
          right: 1rem;
          bottom: 0.75em;
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }

    .chain-select {
      // width: 100%;
      width: 100px;
      text-align: center;
    }

    .arrow {
      font-size: $font-md;
      text-align: center;
      margin-top: 1rem;

      > span {
        color: $black;
        display: inline-block;
      }
    }

    .balance-down,
    .receive-down {
      margin-top: 1rem;
    }

    .balance-down,
    .balance-up {
      opacity: 0.75;
      font-weight: normal;
    }

    p {
      text-align: center;
    }

    .l1 {
      color: #37367b;
    }

    .l2 {
      color: $pink-hot;
    }

    button {
      margin-bottom: 1rem;
      background: $blue;
      &.chain {
        background: $yellow;
        padding: 0;
        font-size: $font-xs;
        font-weight: 600;
        @include threedee-box(2px, $black);
        // display: inline-block;
        // max-width: 50px;
        white-space: nowrap;
        margin: 0;
      }
    }

    .swap-cta {
      display: grid;
      grid-template-columns: 80% 20%;
      button {
        @include threedee-box(3px, $black);
        margin: 1rem 0;
        width: unset;
        &:first-child {
          margin-right: 1rem;
        }
        &:hover {
          transform: scale(1.02);
        }
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
}

@media screen and (min-width: $screen-md) {
  .bridge-loot {
    .swap {
      grid-template-columns: repeat(3, 1fr);
      .card {
        grid-column-start: 2;
      }

      &.swap-items {
        grid-template-columns: repeat(6, 1fr);
        .card {
          grid-column: 2 / span 4;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-lg) {
}

@media screen and (min-width: $screen-xlg) {
}

@media screen and (min-width: $screen-xxlg) {
}
</style>