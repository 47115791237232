<template>
  <div class="soft-nft">
    <Card
      :title="`#${nft.id}`"
      type="image"
      v-bind:class="{ staked }"
      @click="$emit('click', nft)"
    >
      <VueLoadImage>
        <img slot="image" :src="nft.img" draggable="false" />
        <div slot="preloader">
          <img
            src="@/assets/images/preloader.png"
            class="preloader"
            draggable="false"
          />
        </div>
        <img
          slot="error"
          src="@/assets/images/staking/placeholder.gif"
          draggable="false"
        />
      </VueLoadImage>

      <span class="reward" v-bind:class="{ staked }"
        >{{ nft.rate }} $xSLIME / DAY</span
      >
      <div class="selection" v-bind:class="{ staked }">
        <span v-if="staked === true">&check;</span>
        <span v-else>&times;</span>
      </div>

      <span v-if="staked === false" class="legacy" v-bind:class="{ staked }">
        DELIST TO STAKE
      </span>

      <div class="downloads" v-show="false">
        <div class="download-button" @click="download(nft.id, 0)">2D</div>
        <div class="download-button" @click="download(nft.id, 1)">3D</div>
        <div class="download-button" @click="download(nft.id, 2)">NO BG</div>
      </div>
    </Card>
  </div>
</template>

<script>
import Card from "../ui/Card";
import VueLoadImage from "vue-load-image";
export default {
  name: "SoftNft",
  props: {
    nft: Object,
    staked: Boolean,
  },
  components: {
    Card,
    VueLoadImage,
  },
  methods: {
    async download(id, type = 0) {
      // 0 = 2D, 1 = 3D, 2 = No Background
      // workaround to force download of the image instead of just opening
      let link = document.createElement('a');
      if (type === 0) {
        const blob = await fetch(`https://cloudflare-ipfs.com/ipfs/QmRVe5qRrhCrHqehqs8JAnEk3b5to4VLdzcABp6AW1S3jw/${id}.png`).then(res => res.blob());
        link.href = URL.createObjectURL(blob);
        link.download = `${id}.png`;
      } else if (type === 1) {
        const blob = await fetch(`https://cloudflare-ipfs.com/ipfs/bafybeibi65s3x3gayx4g3hbjq62bpy6dubkmkeb4o3gburkzwz3egtpuqi/${id}.png`).then(res => res.blob());
        link.href = URL.createObjectURL(blob);
        link.download = `${id}_3d.png`;
      } else if (type === 2) {
        const blob = await fetch(`https://cloudflare-ipfs.com/ipfs/QmPj5wqaEfFuCDu38ryXnRCDCFdn16tf8qC5aPJD7fevXT/${id}.png`).then(res => res.blob());
        link.href = URL.createObjectURL(blob);
        link.download = `${id}_transparent.png`;
      } else {
        return;
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.soft-nft {
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card-title {
    font-size: $font-lg;
    margin-bottom: 1.5rem;
  }
  .card-body {
    max-width: 300px;
    transition: all 0.2s;
    transform: scale(0.95);
    @include threedee-box(3px, $red);
    background: $red;

    &:hover {
      transform: scale(1.05);
    }
    .reward {
      padding: 1rem 0.75rem;
      border-radius: 25px;
      top: 0.5rem;
      left: 0.5rem;
      position: absolute;
      background: rgba($red, 1);
      color: $white;
      text-decoration: line-through;
      &.staked {
        background: rgba($green, 1);
        color: $black;
        text-decoration: none;
      }
    }
    .downloads {
      padding: 0.25rem 0.75rem;
      border-radius: 25px;
      width: 60%;
      bottom: 0.5rem;
      left: 0;
      right: 0;
      margin: 0 auto;
      position: absolute;
      background: rgba($white, 0.85);
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      .download-button {
        line-height: 1.5rem;
        text-align: center;
        flex-grow: 1;
        cursor: pointer;
        &:hover {
          border-radius: 25px;
          background: #CCC;
        }
      }
    }
    .legacy {
      padding: 1rem 0.75rem;
      border-radius: 25px;
      top: 3rem;
      left: 0.5rem;
      position: absolute;
      background: rgba($red, 1);
      color: $white;
      &.staked {
        background: rgba($green, 1);
      }
    }
  }

  .staked .card-body {
    @include threedee-box(3px, $green-hot);
    background: $green-hot;
    .selection {
      background: $green-hot;
    }
  }

  .selection {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    border-radius: 100px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: $red;
    border: 2px solid $white;
    box-sizing: border-box;
  }
}
</style>
