<template>
  <div :class="`card ${type}`">
    <div class="card-title" v-if="title.trim() === 'SquishiVerse'">
      <span>Squishi</span><span>Verse</span>
    </div>
    <div class="card-title" v-else-if="title">
      {{ title }}
    </div>
    <div class="card-body" @click="$emit('click')">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
@keyframes expansive {
  0%,
  100% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.2);
  }
}

.card {
  padding: 1rem 0;
  position: relative;
  &-title {
    color: $white;
    font-size: $font-xlg;
    text-align: center;
    @include threedee-text(3px);
    font-family: $font-primary;
    font-weight: 600;
    text-transform: uppercase;
    z-index: 0;
    span {
      z-index: 1;
      position: relative;
      color: $red;
      + span {
        color: $blue;
        z-index: 0;
      }
    }
  }
  &-body {
    @include threedee-box(3px);
    background: $white;
    padding: 1rem 1.5rem;
    overflow: hidden;
    font-weight: 600;
    white-space: break-spaces;
    z-index: 1;
    border-radius: $radius-md;
    position: relative;
    line-height: 150%;
    font-size: $font-xxs;
    p {
      margin: 0;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        color: $black;
        text-decoration: none;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.image {
  .card-title {
    // display: none;
  }
  .card-body {
    padding: 0;
    line-height: 0;
    background: $black;
    .vue-load-image {
      overflow: hidden;
      height: 0;
      padding-bottom: 100%;
    }
    img {
      max-width: 100%;
      width: 100%;
      // max-height:100%;
      // height: auto;
      &.preloader {
        filter: blur(5px);
        animation: expansive 2s ease-in-out infinite;
      }
    }
  }
}

.brand {
  .card-title {
    @include threedee-text(4px, $navy);
  }
  .card-body {
    color: $white;
    background: $blue;
    @include threedee-box(3px, $navy);
  }
}

.presale {
  .card-title {
    font-size: $font-md;
    margin-bottom: 2px;
    @include threedee-text(2px, darken($pink, 72%));
    color: $pink;
  }
  .card-body {
    @include threedee-box(3px, darken($pink, 72%));
    background: $pink;
  }
}

.public {
  .card-title {
    font-size: $font-md;
    margin-bottom: 2px;
    @include threedee-text(2px, darken($green, 72%));
    color: $green;
  }
  .card-body {
    @include threedee-box(3px, darken($green, 72%));
    background: $green;
  }
}

.farmed,
.balance {
  .card-title {
    font-size: $font-md;
    margin-bottom: 2px;
    @include threedee-text(2.5px, darken($black, 72%));
    color: $gold;
    // @include threedee-text-extra(2.5px, 2.5px, $black);
  }
  .card-body {
    @include threedee-box(3px, darken($black, 72%));
    padding: 0.75rem 1rem;
    // background: $green;
  }
}

.card.lootbox-item-card {
  .card-title {
    font-size: $font-sm;
    margin-bottom: 10px;
    @include threedee-text(2.2px, darken($black, 72%));
    color: $white;
  }
  .card-body {
    padding: 0;
    background: rgba($white, 0.5);
    img {
      max-height: 256px;
    }
    .quantity {
      padding: 0.75rem 0.75em;
      border-radius: 25px;
      bottom: 0.5rem;
      right: 0.5rem;
      position: absolute;
      color: $white;
      font-size: $font-xxxs;
      background: rgba($black, 0.75);
    }
  }
}

.card.list {
  .card-title {
  }
  .card-body {
    padding: 0;
    .list-item {
      border-top: 3px solid $black;
      padding: 0.6rem 1rem;
      // width: 100%;
      display: block;
      position: relative;

      &:first-child {
        border-top: none;
      }

      &.active {
        background: $green;
      }

      &:hover {
        background: lighten($green, 20%);
        cursor: pointer;
      }

      &.disabled {
        cursor: not-allowed;
        background: darken($white, 10%);
      }

      .badge {
        background: rgba($black, 0.1);
        color: rgba($black, 0.8);
        position: absolute;
        border-radius: $radius-md;
        font-size: $font-xxxs;
        padding: 0 0.25rem;
        right: 1rem;
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
  .brand {
    .card-title {
      font-size: $font-xxlg;
      @include threedee-text-extra(6px, 4px, $navy);
    }
  }
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
