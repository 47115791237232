<template>
  <div class="splash-loader" v-bind:class="{ hidden }">
    <LogoSvg class="logo" />
    <div class="progress">
      <div :style="{ width: `${progress * 100}%` }"></div>
    </div>
  </div>
</template>

<script>
import LogoSvg from "../assets/images/logo.svg";

export default {
  name: "SplashLoader",
  props: {
    progress: Number,
    hidden: Boolean,
  },
  components: {
    LogoSvg,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7.5px);
  }
}

.splash-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(45deg, #80b0de, #b2cfea);
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  transition: all 0.2s;

  &.hidden {
    pointer-events: none;
    opacity: 0;
  }

  .logo {
    max-width: 128px;
    animation: bounce 1s infinite;
  }

  .progress {
    width: 100%;
    max-width: 250px;
    height: 25px;
    border-radius: $radius-md;
    background: rgba($black, 0.5);
    display: block;
    overflow: hidden;
    @include threedee-box-extra(3px, 2, $navy);
    > div {
      background: linear-gradient(45deg, $green, lighten($green, 15%));
      height: inherit;
      transition: width 0.2s;
    }
  }
}
</style>
