<template>
  <div class="team">
    <div class="team-bg"></div>
    <div class="container">
      <h2>{{ $t("home.team.the_team") }}</h2>

      <div class="team-grid">
        <TeamMember
          v-for="(member, idx) in teamMembers"
          :key="idx"
          :member="member"
        />
      </div>

      <h3>{{ $t("home.team.community_management") }}</h3>

      <div class="team-grid mod-grid">
        <TeamMember
          v-for="(member, idx) in moderators"
          :key="idx"
          :member="member"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TeamMember from "./TeamMember";
import TeamMembers from "../../data/Team.json";
import Moderators from "../../data/Mods.json";
export default {
  name: "Team",
  props: {},
  methods: {},
  components: {
    TeamMember,
  },
  data() {
    return {
      teamMembers: TeamMembers,
      moderators: Moderators,
    };
  },
};
</script>

<style lang="scss">
.team {
  padding: 1rem 0;
  position: relative;
  overflow: hidden;
  // padding: 2rem;
  background: $navy;

  &-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(180deg, #001127 0%, rgba($navy, 0) 25%),
      url("~@/assets/images/herov2/team.jpg") no-repeat;
    background-size: cover;
    background-position: bottom;
    filter: blur(10px);
  }

  h2 {
    font-size: $font-lg;
    margin: 2rem 0;
  }

  h3 {
    font-size: $font-lg;
    @include threedee-text(3px);
    margin: 1rem 0;
  }
  h2,
  h3 {
    text-align: center;
    text-transform: uppercase;
  }

  .team-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding: 2rem;
  }
  .mod-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    .name {
      font-size: $font-xxs;
      margin: 1rem 0;
      @include threedee-text-extra(1.5px, 3px);
    }
    .twitter {
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
  .team {
    h2 {
      font-size: $font-xlg;
      margin: 5rem;
    }
    h3 {
      font-size: $font-lg;
      margin: 3rem 0;
    }
    .team-grid {
      grid-template-columns: repeat(5, 1fr);
      gap: 3rem;
      padding: 2rem 0;
      .team-member:nth-child(6) {
        grid-column: 2;
      }
    }
    .mod-grid {
      grid-template-columns: repeat(7, 1fr);
      gap: 1rem;
      margin-bottom: 5rem;
      .team-member:nth-child(6) {
        grid-column: unset;
      }
      .team-member:last-child {
        img {
        }
      }
    }
  }
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
