<template>
  <div class="comics">
    <div class="container">
      <div class="header">
        <h2>{{ $t("media.header") }}</h2>
        <div class="mode">
          <div
            class="gif"
            v-bind:class="{ active: viewing === 'gif' }"
            @click="viewing = 'gif'"
          >
            {{ $t("media.gifs") }}
          </div>
          <div
            class="animation"
            v-bind:class="{ active: viewing === 'animation' }"
            @click="viewing = 'animation'"
          >
            {{ $t("media.animation") }}
          </div>
          <div
            class="wallpapers"
            v-bind:class="{ active: viewing === 'wallpapers' }"
            @click="viewing = 'wallpapers'"
          >
            {{ $t("media.wallpapers") }}
          </div>
          <div
            class="manga"
            v-bind:class="{ active: viewing === 'manga' }"
            @click="viewing = 'manga'"
          >
            {{ $t("media.manga") }}
          </div>
          <div
            class="squishies"
            v-bind:class="{ active: viewing === 'extra' }"
            @click="viewing = 'extra'"
          >
            {{ $t("media.extra") }}
          </div>
        </div>
      </div>

      <div v-if="viewing === 'gif'">
        <div class="images">
          <div
            v-for="(src, index) in gifs"
            :key="index"
            class="image"
            @click="() => showImg(index)"
          >
            <VueLoadImage>
              <img slot="image" :src="src" />
              <div slot="preloader">
                <img
                  src="@/assets/images/preloader.png"
                  class="preloader"
                  draggable="false"
                />
              </div>
              <img
                slot="error"
                src="@/assets/images/staking/placeholder.gif"
                draggable="false"
              />
            </VueLoadImage>
          </div>
          <div class="image" @click="goToGiphy()">
            <div>
              <span class="countLayer visible">
                <span class="see-more">{{ $t("media.see_more_giphy") }}</span>
              </span>
            </div>
            <VueLoadImage>
              <img
                slot="image"
                class="preloader"
                src="@/assets/images/staking/placeholder.gif"
              />
            </VueLoadImage>
          </div>
        </div>
        <vue-easy-lightbox
          class="lightbox"
          moveDisabled
          :visible="visible"
          :imgs="gifs"
          :index="index"
          @hide="handleHide"
        ></vue-easy-lightbox>
      </div>
      <div v-else-if="viewing === 'animation'">
        <div class="animations">
          <div
            class="animations-item"
            v-for="(animation, idx) in animations"
            :key="idx"
          >
            <div class="animations-item__header">
              {{ $t("generic.episode") }} {{ idx + 1 }}
            </div>
            <div class="animations-item__preview">
              <iframe
                :src="animation"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="viewing === 'wallpapers'">
        <div class="images wallpapers">
          <div
            v-for="(src, index) in wallpapers"
            :key="index"
            class="image"
            @click="() => showImg(index)"
          >
            <VueLoadImage>
              <img slot="image" :src="src.replace('.png', 'm.png')" />
              <div slot="preloader">
                <img
                  src="@/assets/images/preloader.png"
                  class="preloader"
                  draggable="false"
                />
              </div>
              <img
                slot="error"
                src="@/assets/images/staking/placeholder.gif"
                draggable="false"
              />
            </VueLoadImage>
          </div>
        </div>
        <vue-easy-lightbox
          class="lightbox"
          moveDisabled
          :visible="visible"
          :imgs="wallpapers"
          :index="index"
          @hide="handleHide"
        ></vue-easy-lightbox>
      </div>
      <div v-else-if="viewing === 'manga'">
        <div class="images">
          <div
            v-for="(src, index) in images"
            :key="index"
            class="image"
            @click="() => showImg(index)"
          >
            <div class="countLayer">
              <span>{{ index + 1 }}</span>
            </div>
            <VueLoadImage>
              <img slot="image" :src="src.replace('l.png', 'm.png')" />
              <div slot="preloader">
                <img
                  src="@/assets/images/preloader.png"
                  class="preloader"
                  draggable="false"
                />
              </div>
              <img
                slot="error"
                src="@/assets/images/staking/placeholder.gif"
                draggable="false"
              />
            </VueLoadImage>
          </div>
        </div>
        <vue-easy-lightbox
          class="lightbox"
          moveDisabled
          :visible="visible"
          :imgs="images"
          :index="index"
          @hide="handleHide"
        ></vue-easy-lightbox>
      </div>
      <div v-else-if="viewing === 'extra'">
        <Card title="Transparent Squishies" type="squishies">
          <form @submit.prevent>
            <label>
              {{ $t("media.enter_squishie") }}
            </label>
            <div class="form-group">
              <input
                type="text"
                placeholder="Squishie Number (i.e 1337)"
                v-model="transparentSquishiesInput"
                required
              />
              <Button
                type="button"
                class="chain"
                @click="fetchTransparentSquishies"
              >
                {{ $t("generic.fetch") }}
              </Button>
            </div>
          </form>
          <p v-if="transparentSquishiesInput === false" class="preview-error">
            {{ $t("media.wrong_input") }}
          </p>
          <div v-else class="preview-squishies">
            <Card
              type="image"
              v-for="(ts, index) of transparentSquishies"
              :key="`${index}__${ts}`"
            >
              <VueLoadImage>
                <img
                  slot="image"
                  :src="ts"
                  @click="() => showImg(index)"
                  class="result"
                />
                <div slot="preloader">
                  <img
                    src="@/assets/images/preloader.png"
                    class="preloader"
                    draggable="false"
                  />
                </div>
                <img
                  slot="error"
                  src="@/assets/images/staking/placeholder.gif"
                  draggable="false"
                />
              </VueLoadImage>
              <vue-easy-lightbox
                class="lightbox"
                moveDisabled
                :visible="visible"
                :imgs="transparentSquishies"
                :index="index"
                @hide="handleHide"
              ></vue-easy-lightbox>
            </Card>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueLoadImage from "vue-load-image";
import Card from "../ui/Card";
import Button from "../ui/Button";

export default {
  name: "Comics",
  props: {},
  methods: {
    fetchTransparentSquishies() {
      const squishies = this.transparentSquishiesInput
        .split(",")
        .map((e) => {
          try {
            return parseInt(e);
          } catch (err) {
            return -1;
          }
        })
        .filter((e) => e >= 0 && e <= 8888);
      this.transparentSquishies = squishies.map(
        (s) =>
          `https://cloudflare-ipfs.com/ipfs/QmPj5wqaEfFuCDu38ryXnRCDCFdn16tf8qC5aPJD7fevXT/${s}.png`
      );
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
      console.log("Show");
    },
    handleHide() {
      console.log("Hide");
      this.visible = false;
    },
    async fetchManga() {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/media/manga`
      );
      this.images = data.message.map((media) =>
        media.url.replace(".png", "l.png")
      );
    },
    async fetchWallpapers() {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/media/wallpapers`
      );
      this.wallpapers = data.message.map((media) => media.url);
    },
    async fetchGifs() {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/media/gifs`
      );
      this.gifs = data.message.images.map((e) => e.images.original.url);
    },
    async goToGiphy() {
      window.open("https://giphy.com/squishiverse/", "_blank").focus();
    },
  },
  components: {
    VueLoadImage,
    Button,
    Card,
  },
  mounted() {
    this.fetchManga();
    this.fetchGifs();
    this.fetchWallpapers();
  },
  data() {
    return {
      images: [],
      wallpapers: [],
      gifs: [],
      visible: false,
      index: 0, // default: 0
      viewing: "gif",
      animations: [
        "https://www.youtube.com/embed/L_mAQqEnEcg",
        "https://www.youtube.com/embed/FuiHiSw_ZsU",
        "https://www.youtube.com/embed/hAuLjUI2K-Y",
        "https://www.youtube.com/embed/LfEAyqtFvLE",
      ],
      transparentSquishiesInput: "",
      transparentSquishies: [],
    };
  },
};
</script>

<style lang="scss">
.lightbox {
  z-index: 100000 !important;
}

.comics {
  padding: 0 0 8rem 0;
  // padding: 2rem 0 8rem 0;
  background: $blue;
  min-height: calc(100vh - 200px);

  .header {
    display: grid;
    align-self: center;
    margin-bottom: 1rem;
    // margin-bottom: 4rem;
    // grid-template-columns: 33% 66%;
  }

  h2 {
    text-align: center;
    margin: 0rem;
    align-self: center;
    transform: rotate(-5deg);
    color: $gold;
    margin-left: 1rem;
    justify-self: center;
    margin-bottom: 2rem;
  }

  .card.squishies {
    max-width: $screen-sm;
    margin: 1rem;
    // margin: 0 auto;
    .card-title {
      font-size: $font-lg;
      color: $gold;
    }
    form {
      padding-top: 0.5rem;
      label {
        text-align: center;
        display: inline-block;
      }
      .form-group {
        display: grid;
        text-align: left;
        margin-top: 0.25rem;
        // grid-template-columns: 70% 30%;

        input {
          width: 100%;
          color: $black;
          padding: 0.75rem 1rem;
          border-radius: $radius-md;
          font-size: $font-xxs;
          line-height: 0;
          border: 3px solid $black;
          box-sizing: border-box;
          align-self: center;
        }

        input:disabled {
          opacity: 0.8;
          cursor: not-allowed;
        }

        input[type="number"] {
          -moz-appearance: textfield;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }

        button {
          margin: 0;
          height: 50px;
          width: 100%;
          align-self: center;
          border: 3px solid $black;
          box-shadow: none !important;
          background: $green;
          margin-top: 1rem;
          // width: calc(100% - 1rem);
          // margin-left: 1rem;
          // margin-top: 0;
        }
      }
    }
    .preview-squishies {
      display: grid;
      padding: 2px;
      margin-top: 0.25rem;
      // gap: 2rem;
      // grid-template-columns: repeat(4, 1fr);
      .result {
        cursor: pointer;
        background: $white;
        z-index: 0;
        position: relative;
      }
    }
  }

  .mode {
    display: inline-flex;
    overflow: hidden;
    align-self: center;
    justify-content: center;
    font-weight: 600;
    @include threedee-box(3px, $black);
    border-radius: 10px;
    justify-self: center;
    // justify-self: flex-start;
    // margin-left: 1rem;
    .animation,
    .wallpapers,
    .manga,
    .gif,
    .squishies {
      font-size: $font-xxxs;
      background: $white;
      padding: 0.5rem 0.6rem;
      // padding: 0.75rem 1.25rem;
      // font-size: $font-xxs;

      cursor: pointer;
      &.active {
        background: $gold;
      }
    }
    .gif {
      border-right: 3px solid $black;
    }
    .manga,
    .wallpapers,
    .squishies {
      border-left: 3px solid $black;
    }
  }

  .animations {
    display: grid;
    gap: 4rem 2rem;
    padding: 1rem;
    // grid-template-columns: repeat(2, 1fr);
    &-item {
      &__header {
        font-size: $font-md;
        color: $white;
        font-family: $font-primary;
        text-transform: uppercase;
        font-weight: 600;
        text-align: center;
        margin-bottom: 2rem;
        @include threedee-text(3px, $black);
      }
      &__preview {
        @include threedee-box(3px);
        border-radius: $radius-md;
        background: $white;
        position: relative;
        iframe {
          margin-bottom: -5px;
          width: 100%;
          height: 300px;
        }
      }
    }
  }
  .images {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 1rem;
    gap: 2rem;

    .preloader {
      filter: blur(5px);
      animation: expansive 2s ease-in-out infinite;
    }

    &.wallpapers {
      .image {
        height: 150px;
        img {
          width: 100%;
          // max-width: 300px;
          // max-height: 300px;
          object-fit: cover;
          height: 100%;
          object-position: center;
        }
      }
    }

    .image {
      position: relative;
      cursor: pointer;
      justify-self: center;
      border-radius: $radius-md;
      overflow: hidden;
      @include threedee-box(2px);
      padding: 0;
      line-height: 0;
      background: $black;
      width: 100%;
      .countLayer {
        z-index: 99999;
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        color: white;
        text-align: center;
        background-color: rgba(1, 1, 1, 0.5);
        font-family: $font-primary;
        @include vertical-align(middle);
        opacity: 0;
        span {
          font-size: 10rem;
        }
        .see-more {
          font-size: 3rem;
          line-height: 1;
          display: inline-block;
          padding: 1rem;
        }
        &:hover {
          opacity: 1;
        }
        &.visible {
          opacity: 1;
        }
      }
      img {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        height: auto;
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .comics {
    .images {
      grid-template-columns: repeat(2, 1fr);
    }

    .card.squishies {
      margin: 0 auto;
      .card-title {
        font-size: $font-lg;
      }
      form {
        label {
        }
        .form-group {
          grid-template-columns: 70% 30%;

          input {
          }

          button {
            width: calc(100% - 1rem);
            margin-left: 1rem;
            margin-top: 0;
          }
        }
      }
      .preview-squishies {
        gap: 2rem;
        grid-template-columns: repeat(4, 1fr);
        .result {
        }
      }
    }
  }
}
@media screen and (min-width: $screen-md) {
  .comics {
    h2 {
      font-size: $font-xlg;
      margin-bottom: 0;
    }
    .images {
    }
  }

  .comics {
    padding: 2rem 0 8rem 0;

    .header {
      margin-bottom: 3rem;
      grid-template-columns: 33% 66%;
    }

    h2 {
      justify-self: flex-start;
    }

    .mode {
      justify-self: flex-start;
      .animation,
      .wallpapers,
      .manga,
      .gif,
      .squishies {
        padding: 0.75rem 1.25rem;
        font-size: $font-xxs;

        &.active {
        }
      }
      .gif {
      }
      .manga,
      .wallpapers {
      }
    }

    .animations {
      grid-template-columns: repeat(2, 1fr);
      &-item {
        &__header {
        }
        &__preview {
          iframe {
          }
        }
      }
    }
    .images {
      grid-template-columns: repeat(3, 1fr);
      &.wallpapers {
        .image {
          img {
            max-width: 300px;
            max-height: 300px;
          }
        }
      }

      .image {
        .countLayer {
          span {
          }
          &:hover {
          }
        }
        img {
        }
      }
    }
  }
}
@media screen and (min-width: $screen-lg) {
  .comics {
    .images {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
