var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero",on:{"mousemove":_vm.mouseMove}},[_c('SplashLoader',{attrs:{"hidden":!_vm.isLoading,"progress":_vm.totalLoaded / _vm.imagesToPreload.length}}),_c('Navbar'),_c('div',{staticClass:"hero-sky",style:({
      backgroundPosition:
        _vm.deviceWidth < 1024
          ? '-350px bottom'
          : `${2 * _vm.hillPositionX}px ${_vm.hillPositionY}px`,
    })}),_c('div',{staticClass:"hero-hill"},[_c('div',{staticClass:"hero-hill-tree",style:({
        backgroundPosition:
          _vm.deviceWidth < 1024 ? '-350px bottom' : `${_vm.hillPositionX}px bottom`,
      })}),_c('div',{staticClass:"logo-wrapper",style:({
        transform: `translate(${_vm.hillPositionX * 0.5}px, ${
          _vm.hillPositionY + 200 + -_vm.scrollPercent * 10000
        }px) `,
      })},[_c('div',{staticClass:"intro-logo",class:{ 'intro-logo-animate': !_vm.isLoading }},[_c('img',{staticClass:"logo",style:({
            transform: `rotate(${-_vm.scrollPercent * 20000}deg) scale(${
              1 - _vm.scrollPercent * 10
            })`,
          }),attrs:{"src":require("@/assets/images/herov2/logo.png"),"draggable":"false"}})])]),_c('div',{staticClass:"hero-hill-alone",style:({
        backgroundPosition:
          _vm.deviceWidth < 1024 ? '-350px 0' : `${_vm.hillPositionX}px bottom`,
      })})]),_c('img',{staticClass:"squishie-pink",style:({
      transform: `translate(${_vm.hillPositionX * 0.5}px, ${
        _vm.hillPositionY * 5 + 500
      }px)`,
    }),attrs:{"src":require("@/assets/images/herov2/pink.png"),"draggable":"false"}}),_c('img',{staticClass:"squishie-pink-shadow",style:({
      transform: `translate(${_vm.hillPositionX * 3 + 25}px, ${
        _vm.hillPositionY + 135
      }px) scale(${0.9 - _vm.hillPositionX / 90})`,
    }),attrs:{"src":require("@/assets/images/herov2/pink-shadow.png"),"draggable":"false"}}),_c('img',{staticClass:"squishie-pink-pumpkin",style:({ transform: `translateX(${_vm.hillPositionX}px)` }),attrs:{"src":require("@/assets/images/herov2/pink-pumpkin.png"),"draggable":"false"}}),_c('img',{staticClass:"squishie-dragon",style:({
      transform: `translate(${_vm.hillPositionX * 10 + 100}px, ${
        -_vm.hillPositionY - 200
      }px)`,
    }),attrs:{"src":require("@/assets/images/herov2/dragon.png"),"draggable":"false"}}),_c('img',{staticClass:"squishie-blue",style:({
      transform: `translateY(${-_vm.hillPositionX * 5 - 100}px)`,
    }),attrs:{"src":require("@/assets/images/herov2/blue.png"),"draggable":"false"}}),_c('img',{staticClass:"squishie-blue-shadow",style:({
      transform: `translate(${_vm.hillPositionX * 2 + 50}px, ${
        _vm.hillPositionY + 25
      }px) scale(${1.25 + _vm.hillPositionX / 50})`,
    }),attrs:{"src":require("@/assets/images/herov2/blue-shadow.png"),"draggable":"false"}}),_c('div',{staticClass:"hero-grass-blue"}),_c('div',{staticClass:"hero-grass",style:({ opacity: 1 - 1 * (_vm.scrollPercent / 0.01) })}),_c('RecordPlayer',{style:({
      opacity: 1 - 1 * (_vm.scrollPercent / 0.01),
      pointerEvents: _vm.scrollPercent / 0.01 > 1 ? 'none' : 'all',
    })}),_c('LocaleChanger',{style:({
      opacity: 1 - 1 * (_vm.scrollPercent / 0.01),
      pointerEvents: _vm.scrollPercent / 0.01 > 1 ? 'none' : 'all',
    })})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }