<template>
  <div>
    <div class="marketplace-bg"></div>
    <MarketplaceHero />
    <Settings />
    <img
      class="marketplace-clover"
      src="~@/assets/images/marketplace/clover.png"
    />
  </div>
</template>

<script>
import { pages } from "../../vue.config";
import MarketplaceHero from "../components/Hero/MarketplaceHero.vue";
import Settings from "../components/Account/Settings.vue";

export default {
  name: "SettingsView",
  metaInfo: {
    title: pages.settings.title,
    meta: [{ name: "description", content: pages.settings.metaDescription }],
  },
  components: {
    MarketplaceHero,
    Settings,
  },
  methods: {},
  mounted() {
    const vm = this;
    // if (vm.$store.state.token === null) {
    //   this.$router.push('/');
    // }
    if (vm.$store.state.account !== null) {
      vm.$store.dispatch("fetchUserRewards", vm.$store.state.account);
    }
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.marketplace-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  background: url("~@/assets/images/marketplace/bg.jpg") no-repeat;
  background-size: 300% 120%;
  background-position: top;
  z-index: -1;
}
.marketplace-clover {
  display: none;
}
@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
  .marketplace-bg {
    background-size: 100% 100%;
  }
  .marketplace-clover {
    display: block;
    position: fixed;
    height: 70%;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
