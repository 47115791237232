<template>
  <div class="hero-staking">
    <Navbar />
    <div class="container">
      <div class="hero-header">
        <div class="treasure-container">
          <img
            src="@/assets/images/staking/treasure.png"
            :style="treasureChestInterpolate"
            draggable="false"
          />
        </div>
        <div class="hero-text" :style="treasureTextInterpolate">
          <h2>
            {{
              $t("staking.join_squishies", {
                num: $store.state.mintStatus.totalStaked || "...",
              })
            }}
          </h2>
          <h2 class="squishies">{{ $t("staking.adventure") }}</h2>
        </div>
        <div
          v-if="$store.state.account === null"
          class="rewards"
          :style="treasureTextInterpolate"
        >
          <Button class="btn-connect" @click="connectWallet">
            {{ $t("generic.connect_wallet") }}
          </Button>
        </div>
        <div v-else class="rewards" :style="treasureTextInterpolate">
          <Card :title="$t('generic.balance')" type="balance"
            >{{ balance }} $xSLIME</Card
          >
          <Card :title="$t('generic.farmed')" type="farmed">
            {{ rewards }} $xSLIME
          </Card>
          <Button @click="claimRewards">{{
            $t("staking.claim_rewards")
          }}</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../ui/Button";
import Card from "../ui/Card";
import Navbar from "./Navbar";

export default {
  name: "StakingHero",
  props: {},
  components: {
    Card,
    Button,
    Navbar,
  },
  computed: {
    balance() {
      const vm = this;
      const { balance } = vm.$store.state.user.claim;
      if (balance === null) {
        return "...";
      }
      return parseInt(balance).toLocaleString();
    },
    rewards() {
      const vm = this;
      const { rewards } = vm.$store.state.user.claim;
      if (rewards === null) {
        return "...";
      }
      return parseInt(rewards).toLocaleString();
    },
  },
  methods: {
    onScroll() {
      const vm = this;
      const { scrollY } = window;
      if (scrollY > 750) {
        return;
      }
      vm.treasureTextInterpolate =
        `transform: ` + ` translateY(-${(scrollY / 500) * 1000}px)`;
      vm.treasureChestInterpolate = `transform: scale(${
        1 - scrollY / 750
      }) rotate(${360 * (scrollY / 750)}deg)`;
    },
    connectWallet() {
      const vm = this;
      vm.$store.dispatch("connectWallet", { action: "staking" });
    },
    async claimRewards() {
      const vm = this;
      vm.$store.state.transactionModal = "rewards";
      await vm.$store.dispatch("claimRewards");
    },
  },
  mounted() {
    const vm = this;
    window.addEventListener("scroll", vm.onScroll);
    vm.$store.dispatch("fetchTotalStaked");
  },
  data() {
    return {
      treasureChestInterpolate: "",
      treasureTextInterpolate: "",
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1px, 0, 0);
  }
}

@keyframes bloat {
  0% {
    transform: rotate(-3deg) scale(1);
  }
  50% {
    transform: rotate(-3deg) scale(1.05);
  }
  100% {
    transform: rotate(-3deg) scale(1);
  }
}

.hero-staking {
  // background: $sand;
  padding: 1rem 0 0 0;
  position: relative;
  overflow: hidden;

  > .container {
    display: grid;

    .hero-header {
      margin-top: 5rem;
      text-align: center;
      margin-bottom: 16rem;
      .hero-text {
        position: relative;
        top: 0.5rem;
        margin: 0 auto;
        width: 100%;
        h2 {
          font-size: $font-lg;
          position: relative;
          z-index: 1;
          margin: 0;
          padding: 1rem;
          color: $white;
          font-weight: 600;
          text-transform: uppercase;
          // filter: drop-shadow(0 0px 20px rgba(black, 1));
          // color: $pink;
          &.squishies {
            color: $green;
            font-size: $font-xlg;
            padding: 0;
            animation: bloat 2s ease-in-out infinite;
            // color: $gold;
          }
        }
      }
      .treasure-container {
        animation: shake 2s ease-in-out infinite;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        img {
          position: absolute;
          filter: brightness(0.8);
          width: 400px;
          top: 285px;
        }
      }
      .rewards {
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.75rem 1.5rem;
        max-width: 375px;
        margin: 0 auto;
        padding-top: 12rem;
        button {
          margin-top: 0;
          grid-column: 1 / span 2;
          background: $gold;
          &.btn-connect {
            margin-top: 4.5rem;
            margin-bottom: 4rem;
            background: $blue;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .hero-staking {
    > .container {
      .hero-header {
        margin-bottom: 17em;
        .hero-text {
          h2 {
            padding: 1rem 0 0 0;
            &.squishies {
              font-size: $font-xxlg;
            }
          }
        }
        .treasure-container {
          img {
            width: 450px;
            top: 240px;
          }
        }
        .rewards {
          padding-top: 14rem;
          button {
            &.btn-connect {
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: $screen-md) {
  .hero-staking {
    > .container {
      .hero-header {
        margin-bottom: 18.5rem;
        .hero-text {
          h2 {
            &.squishies {
              margin-top: 7.5rem;
            }
          }
        }
        .treasure-container {
          img {
            width: 550px;
            top: 150px;
          }
        }
        .rewards {
          padding-top: 4.5rem;
          button {
            &.btn-connect {
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: $screen-lg) {
  .hero-staking {
    > .container {
      .hero-header {
        .hero-text {
          h2 {
            &.squishies {
            }
          }
        }
        .treasure-container {
          img {
            width: 700px;
            top: 10px;
          }
        }
        .rewards {
          button {
            &.btn-connect {
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
