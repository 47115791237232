import store from "storejs";

export default {
    setProvider(state, params) {
        state.provider = params;
    },
    setWeb3(state, params) {
        state.web3 = params;
    },
    setAccount(state, params) {
        state.account = params;
    },
    setToken(state, params) {
        store.set('auth-token', params)
        state.token = params;
    },
    setPresaleTier(state, params) {
        state.presaleTier = params;
    },
    setMintStatus(state, params) {
        state.mintStatus = params;
    },
    setCurrentTime(state, params) {
        state.currentTime = params;
    },
    setStakingSelection(state, params) {
        state.stakingSelection = params;
    },
    setProducts(state, params) {
        state.products = params;
    },
    setProductSelection(state, params) {
        state.productSelection = params;
    },
    setUser(state, params) {
        state.user = params;
    },
    updateUser(state, params) {
        state.user = { ...state.user, ...params };
    },
    setTransactionState(state, params) {
        state.tx = Object.assign(
            {},
            state.tx,
            params
        )
    },
    updateSpecificNftAttributes(state, { stakingLand, nftIds, resolve }) {
        const key = stakingLand ? 'landNfts' : 'nfts';
        state.user[key] = [...state.user[key]]
            .map(n => nftIds.includes(n.id)
                ? Object.assign({}, n, resolve(n))
                : n
            )
    },
    setLootbox(state, params) {
        state.lootbox = params;
    }
};