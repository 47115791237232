<template>
  <div>
    <div class="comics-bg"></div>
    <div class="comics-hero">
      <Navbar />
      <div class="container">
        <Comics />
      </div>
    </div>
  </div>
</template>

<script>
import { pages } from "../../vue.config";
import Comics from "../components/Comics/Comics.vue";
import Navbar from "../components/Hero/Navbar.vue";

export default {
  name: "MediaView",
  metaInfo: {
    title: pages.media.title,
    meta: [{ name: "description", content: pages.media.metaDescription }],
  },
  components: {
    Comics,
    Navbar,
  },
  methods: {},
  mounted() {},
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.comics-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  background: $blue;
  background-position: top;
  z-index: -1;
}
.comics-hero {
  padding: 1rem 0 0 0;
  min-height: 15rem;
  position: relative;
  // overflow: hidden;

  > .container {
    padding-top: 5rem;
    display: grid;

    .page-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      h2 {
        transform: rotate(-5deg);
        color: $gold;
        position: relative;
        small {
          position: absolute;
          right: 0;
          bottom: -18px;
          font-size: $font-sm;
          color: $blue;
        }
      }

      .mode {
        display: inline-flex;
        overflow: hidden;
        justify-content: center;
        font-weight: 600;
        @include threedee-box(3px, $black);
        border-radius: 10px;
        margin-left: 1rem;
        margin-bottom: 1rem;
        .items,
        .slime {
          // font-size: $font-xxs;
          background: $white;
          padding: 0.75rem 1.25rem;
          font-size: $font-xxs;

          cursor: pointer;
          &.active {
            background: $green;
          }
        }
        .slime {
          border-right: 3px solid $black;
        }
      }

      .connect-wallet {
        button {
          font-size: $font-xs;
          @include threedee-box(2px, $black);
        }
      }

      .checkout {
        display: flex;
        align-self: center;
        gap: 1rem;

        .rewards,
        .balance {
          padding: 0.75rem 1.25rem;
          font-size: $font-xxxs;
          font-weight: 600;
          background: $white;
          border-radius: 10px;
        }
        .balance {
          background: $green;
          color: $black;
          @include threedee-box(3px, $black);
        }
        .rewards {
          background: $pink;
          color: $black;
          @include threedee-box(3px, $black);
        }
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
  .bridge-hero {
    min-height: 5rem;
    > .container {
      .page-title {
        flex-direction: row;

        h2 {
        }

        .connect-wallet {
          padding-bottom: 1.5rem;
          button {
          }
        }

        .checkout {
          gap: 1rem;
          .rewards,
          .balance {
            font-size: $font-xxs;
            margin-left: 1rem;
            margin-bottom: 1rem;
          }
          .balance {
          }
          .rewards {
          }
        }
      }
    }
  }
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
