import state from "../state";
import Web3 from "web3";
import Web3Modal from "web3modal";
import axios from "axios";

import TokenAbi from "../../data/abi/ERC20.json";
import SquishiverseItemAbi from "../../data/abi/SquishiverseItem.json";
import { BigFloat } from "bigfloat.js";

import { lootboxFromId, clientSeed } from "../../helpers/lootboxes";

const apiUrl = process.env.VUE_APP_API_URL;
const gasLimitBoost = 1.05;
const gasPriceBoost = 1.1;

export default {
  async connectWallet(context, { action }) {
    console.log("Set Network", process.env.VUE_APP_CONTRACT_NETWORK);
    const web3modal = new Web3Modal({
      network: process.env.VUE_APP_CONTRACT_NETWORK,
      cacheProvider: false,
      providerOptions: state.providerOptions,
    });
    const provider = await web3modal.connect();
    context.commit("setProvider", provider);
    context.commit("setWeb3", new Web3(state.provider));
    context.commit("setAccount", (await state.web3.eth.getAccounts())[0] || null);
    if (action === 'staking') {
      await context.dispatch("fetchUserNfts", state.account);
      await context.dispatch("fetchUserLandNfts", state.account);
    }
    await context.dispatch("fetchUserRewards", state.account);
  },
  async fetchTotalStaked(context) {
    const { data } = await axios.get(`${apiUrl}/stats`);
    await context.commit("setMintStatus", { ...state.mintStatus, ...data.message });
  },
  async fetchUserNfts(context, account) {
    console.log("fetchUserNfts")
    const { data: nftData } = await axios.get(`${apiUrl}/nfts/${account}`);
    const nftsSorted = nftData.message
      .sort((a, b) => b.rate - a.rate)
      .sort((a, b) => (a.staked !== b.staked) ? 0 : !a.staked ? -1 : 1)
    await context.commit("setUser", { ...state.user, ...{ nfts: nftsSorted } })
  },
  async fetchUserLandNfts(context, account) {
    console.log("fetchUserLandNfts")
    const { data: nftData } = await axios.get(`${apiUrl}/nfts/land/${account}`);
    const nftsSorted = nftData.message
      .sort((a, b) => b.rate - a.rate)
      .sort((a, b) => (a.staked !== b.staked) ? 0 : !a.staked ? -1 : 1)
    await context.commit("setUser", { ...state.user, ...{ landNfts: nftsSorted } })
  },
  async fetchUserInventory(context) {
    console.log("fetchUserInventory")
    const { data: nftData } = await axios.get(`${apiUrl}/nfts/items`,
      { headers: { Authorization: `Bearer ${state.token}` } }
    );
    await context.commit("setUser", { ...state.user, ...{ inventory: nftData.message || [] } })
  },
  async fetchUserTokenInventory(context) {
    console.log("fetchUserTokenInventory")
    const { data: nftData } = await axios.get(`${apiUrl}/nfts/tokens`,
      { headers: { Authorization: `Bearer ${state.token}` } }
    );
    await context.commit("setUser", { ...state.user, ...{ tokenInventory: nftData.message || [] } })
  },
  async fetchTotalLootboxes(context) {
    console.log("fetchTotalLootboxes")
    try {
      const { data: nftData } = await axios.get(`${apiUrl}/lootbox`,
        { headers: { Authorization: `Bearer ${state.token}` } }
      );
      await context.commit("setUser", { ...state.user, ...{ lootboxes: nftData.message || null } })
    } catch (err) {
      // no lootbox most likely
      await context.commit("setUser", { ...state.user, ...{ lootboxes: null } })
    }
  },
  async fetchUserRewards(context, account) {
    const { data: rewardsData } = await axios.get(`${apiUrl}/rewards/${account}`);
    await context.commit("setUser", { ...state.user, ...{ claim: rewardsData.message } })
  },
  async fetchUserTokens(context, account) {
    console.log('fetchUserTokens');
    const contract = new state.web3.eth.Contract(
      TokenAbi,
      process.env.VUE_APP_ERC20_CONTRACT_ADDRESS
    );
    const amountWei = await contract.methods.balanceOf(account).call();
    const tokens = parseInt(new BigFloat(amountWei).div(10 ** 18).floor().toString());
    await context.commit("setUser", { ...state.user, ...{ tokens } })
  },
  async claimRewards(context) {
    state.tx.started = true;
    state.tx.statusText = "Authenticating your wallet...";

    if (state.token === null) {
      state.tx.statusText = "Authenticating your wallet...";
    } else {
      state.tx.statusText = "Please sign the claim rewards message in your wallet";
    }

    try {
      if (!(await context.dispatch("authenticate", { action: "staking" }))) {
        throw "Unable to authenticate wallet";
      }

      // get nonce
      const { data: nonceData } = await axios.post(
        `${apiUrl}/rewards/nonce`, {},
        { headers: { Authorization: `Bearer ${state.token}` } }
      );
      const { nonce } = nonceData.message;

      // sign the message
      state.tx.statusText = "Please sign the claim rewards message in your wallet";
      const msg = `I want to claim my rewards.\n\nHASH: ${nonce}`;
      const signature = await state.web3.eth.personal.sign(msg, state.account, '')

      // make claim
      const { data: claimData } = await axios.post(
        `${apiUrl}/rewards/claim`,
        { signature },
        { headers: { Authorization: `Bearer ${state.token}` } }
      );
      const { message: claim } = claimData;

      // display claim amount and update rewards balance
      state.user.claim.balance += claim.amount;
      state.user.claim.rewards = 0;
      state.tx.completed = true;
      state.tx.statusText = `Successfully claimed ${claim.amount} $xSLIME!`;
      return true;
    } catch (err) {
      state.tx.failed = true;
      if (err.response) {
        const { message } = err.response.data;
        state.tx.failedMsg = message || "Transaction failed, unknown error";
      } else {
        state.tx.failedMsg = err.message || "Transaction failed, unknown error";
      }
      console.log(err);
      return false;
    }
  },
  async authenticate(context, { action }) {
    // wait for them to authenticate their wallet
    if (state.account === null) {
      await context.dispatch("connectWallet", { action });
    }

    // const token = store.get('auth-token');
    // if (token && state.token === null) {
    //   console.log("Found in cache")
    //   await context.commit("setToken", token)
    //   return true;
    // }

    if (state.token !== null) {
      return true;
    }

    try {
      // get nonce
      const { data: nonceData } = await axios.get(`${apiUrl}/auth/nonce/${state.account}`);
      const { nonce } = nonceData.message;
      // console.log("nonceData", nonceData)

      // sign the message
      state.tx.statusText = "Please sign the authentication message in your wallet";
      const msg = `I am signing into my Squishiverse account.\n\nNONCE: ${nonce}`;
      const signature = await state.web3.eth.personal.sign(msg, state.account, '')

      // authenticate
      const { data: authData } = await axios.post(`${apiUrl}/auth`, {
        address: state.account,
        signature,
      });
      await context.commit("setToken", authData.message.token)
      context.dispatch('fetchUserTokens', state.account)
      context.dispatch('fetchUserInventory')
      context.dispatch("fetchUserTokenInventory")
      context.dispatch('fetchTotalLootboxes')
      context.dispatch('startSession')
      context.dispatch("loadUserSocials")
      return true;
    } catch (err) {
      return false;
    }
  },
  async loadProducts(context) {
    const { data } = await axios.get(`${apiUrl}/products`);
    await context.commit("setProducts", data.message)
  },
  async increaseTokenAllowance(context, { amount }) {
    console.log("increaseTokenAllowance", amount)
    const tokenContract = new state.web3.eth.Contract(
      TokenAbi,
      process.env.VUE_APP_ERC20_CONTRACT_ADDRESS
    );
    const approvedAmount = await tokenContract.methods
      .allowance(state.account, process.env.VUE_APP_BRIDGE_CONTRACT_ADDRESS).call();
    if (parseFloat(approvedAmount / 10 ** 18) > amount) {
      return true
    }
    const gasPrice = await state.web3.eth.getGasPrice()
    const contract = new state.web3.eth.Contract(TokenAbi, process.env.VUE_APP_ERC20_CONTRACT_ADDRESS)
    const allowanceAmount = amount // @todo maybe jack it up more
    const contractFunction = contract.methods.increaseAllowance(
      process.env.VUE_APP_BRIDGE_CONTRACT_ADDRESS,
      new BigFloat(allowanceAmount)
        .mul(10 ** 18).toString()
    );
    const gasAmped = new BigFloat(gasPrice)
      .mul(gasPriceBoost)
      .floor()
      .toString();
    const gas = await contractFunction.estimateGas({ from: state.account });
    console.log({ gasPrice, gas, gasAmped });
    return await new Promise((resolve, reject) => {
      contractFunction
        .send({
          from: state.account,
          gas: parseInt(gasLimitBoost * gas),
          gasPrice: gasAmped,
        })
        .on('transactionHash', () => {
          context.commit("setTransactionState", {
            statusText: `Submitted allowance increase for $SLIME. Please wait...`,
          });
        })
        .on('receipt', receipt => resolve(receipt))
        .on('error', error => reject(error))
    })
  },
  async authoriseBurn(context) {
    console.log("authoriseBurn")
    const squishiverseItemContract = new state.web3.eth.Contract(
      SquishiverseItemAbi,
      process.env.VUE_APP_ITEM_CONTRACT_ADDRESS
    );
    const approvedBurner = await squishiverseItemContract.methods
      .isApprovedForAll(state.account, process.env.VUE_APP_BRIDGE_ITEM_CONTRACT_ADDRESS).call();
    if (approvedBurner) {
      return true
    }
    const gasPrice = await state.web3.eth.getGasPrice()
    const contractFunction = squishiverseItemContract.methods.setApprovalForAll(
      process.env.VUE_APP_BRIDGE_ITEM_CONTRACT_ADDRESS,
      true
    );
    const gasAmped = new BigFloat(gasPrice)
      .mul(gasPriceBoost)
      .floor()
      .toString();
    const gas = await contractFunction.estimateGas({ from: state.account });
    console.log({ gasPrice, gas, gasAmped });
    return await new Promise((resolve, reject) => {
      contractFunction
        .send({
          from: state.account,
          gas: parseInt(gasLimitBoost * gas),
          gasPrice: gasAmped,
        })
        .on('transactionHash', () => {
          context.commit("setTransactionState", {
            statusText: `Authorising bridge to transfer items. Please wait...`,
          });
        })
        .on('receipt', receipt => resolve(receipt))
        .on('error', error => reject(error))
    })
  },
  async openLootbox(context, { lootboxId, quantity }) {
    // handle opening of OG boxes differently
    if (lootboxId === 0) {
      console.log("OPEN OG LOOTBOX");
      return await context.dispatch('openLootboxLegacy')
    }

    try {
      // lootbox name to id
      const { name } = lootboxFromId(lootboxId)

      // get nonce
      const { data: nonceData } = await axios.get(`${apiUrl}/auth/nonce/${state.account}`);
      const { nonce } = nonceData.message;

      // sign the message
      const msg = `I want to open ${quantity}x ${name} lootboxes. NONCE: ${nonce}`;
      const signature = await state.web3.eth.personal.sign(msg, state.account, '')

      const { data } = await axios.post(
        `${apiUrl}/lootbox`,
        {
          signature,
          clientSeed: clientSeed(),
          lootboxId,
          quantity,
        },
        { headers: { Authorization: `Bearer ${state.token}` } }
      );
      context.dispatch('fetchTotalLootboxes')
      return data.message || null
    } catch (err) {
      console.log(err);
      return null;
    }
  },

  async openLootboxLegacy(context) {
    try {
      // get nonce
      const { data: nonceData } = await axios.get(`${apiUrl}/auth/nonce/${state.account}`);
      const { nonce } = nonceData.message;

      // sign the message
      const msg = `I want to open my og lootbox. NONCE: ${nonce}`;
      const signature = await state.web3.eth.personal.sign(msg, state.account, '')

      // get result
      const { data: outcomeData } = await axios.post(
        `${apiUrl}/lootbox`,
        {
          signature,
          clientSeed: clientSeed(),
          lootboxId: 0,
          quantity: 1,
        },
        { headers: { Authorization: `Bearer ${state.token}` } }
      );

      // display claim amount and update rewards balance
      context.dispatch('fetchTotalLootboxes')
      return outcomeData.message || null;
    } catch (err) {
      console.log(err);
      return null;
    }
  },
  async loadUserOrderHistory(context) {
    const { data } = await axios.get(
      `${apiUrl}/order/history`,
      { headers: { Authorization: `Bearer ${state.token}` } }
    );
    await context.commit("updateUser", { orders: data.purchases });
  },
  async loadUserSocials(context) {
    const { data } = await axios.get(
      `${apiUrl}/socials`,
      { headers: { Authorization: `Bearer ${state.token}` } }
    );
    await context.commit("updateUser", { socials: data.socials });
  },
  async startSession(context) {
    const { data } = await axios.get(
      `${apiUrl}/lootbox/session`,
      { headers: { Authorization: `Bearer ${state.token}` } }
    );
    await context.commit("updateUser", { serverSeedHash: data.newServerSeedHash });
  },
  async setNftApprovalForAll(context, { contract, sender, approvingAddress }) {
    const preauthorised = await contract.methods.isApprovedForAll(sender, approvingAddress).call()
    if (preauthorised === true) {
      return true
    }
    const gasPrice = await state.web3.eth.getGasPrice();
    const gasAmped = new BigFloat(gasPrice)
      .mul(gasPriceBoost)
      .floor()
      .toString();
    const contractFunction = contract.methods.setApprovalForAll(approvingAddress, true);
    const gas = await contractFunction.estimateGas({ from: sender });
    return await new Promise((resolve, reject) => {
      contractFunction
        .send({
          from: sender,
          gas: parseInt(gasLimitBoost * gas),
          gasPrice: gasAmped,
        })
        .on('transactionHash', () => context.commit("setTransactionState", {
          message: "Authorizing staking contract. Please wait...",
        }))
        .on('receipt', receipt => resolve(receipt))
        .on('error', error => reject(error))
    })
  },
};
