import Vue from "vue";
import VueI18n from 'vue-i18n'

import en from './en'
import cn from './cn'
import es from './es'
import th from './th'
import jp from './jp'
import vn from './vn'
import ru from './ru'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'en', // set locale

    // English
    messages: { en, cn, es, th, jp, vn, ru }
})

export default i18n