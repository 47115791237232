import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from 'vue-meta'
import Home from "../views/Home.vue";
import Staking from "../views/Staking.vue";
import Marketplace from "../views/Marketplace.vue";
import Purchases from "../views/Purchases.vue";
import Settings from "../views/Settings.vue";
import Bridge from "../views/Bridge.vue";
import Lootbox from "../views/Lootbox.vue";
import Comics from "../views/Comics.vue";

Vue.use(VueRouter);
Vue.use(VueMeta)

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    // {
    //     path: "/staking",
    //     name: "Staking",
    //     component: Staking,
    // },
    // {
    //     path: "/marketplace",
    //     name: "Marketplace",
    //     component: Marketplace,
    // },
    // {
    //     path: "/purchases",
    //     name: "Purchases",
    //     component: Purchases,
    // },
    // {
    //     path: "/settings",
    //     name: "Settings",
    //     component: Settings,
    // },
    // {
    //     path: "/inventory",
    //     name: "Bridge",
    //     component: Bridge,
    // },
    // {
    //     path: "/lootbox",
    //     name: "Lootbox",
    //     component: Lootbox,
    // },
    {
        path: "/media",
        name: "Media",
        component: Comics,
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

export default router;
