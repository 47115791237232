export default {
    // Languages:
    languages: {
        en: "English",
        cn: "中文",
        jp: "日本",
        vn: "Tiếng Việt",
        th: "ไทย",
        es: "Español",
        ru: "Русский",
    },

    // Generic
    generic: {
        connect_wallet: "连接钱包",
        wallet_not_connected: "您的钱包没有连接",
        squishie: "Squishie | Squishies",
        land: "土地 | 土地",
        lootbox: "战利品箱 | 战利品箱",
        your: "您的",
        balance: "账户余额",
        farmed: "耕种",
        collection: "Squishi<span class='fix'></span>verse 收藏",
        footer: "Squishiverse &copy; 2022. 版权所有。",
        nothing_to_show: "没有什么可展示的",
        buy_now: "立即购买",
        buy_now_qty: "立即购买 {quantity}",
        unavailable: "不可用",
        close: "关",
        settings: "设置",
        beta: "Beta",
        items: "项目",
        i_understand: "我明白",
        cancel: "取消",
        max: "最大限度",
        claim: "宣称",
        loading: "加载中",
        fetch: "拿来",
        episode: "插曲",
        connect: "连接",
        reconnect: "重新连接",
        open: "打开",
    },

    // Navigation
    nav: {
        home: "主页",
        staking: "质押",
        marketplace: "市场",
        inventory: "库存",
        lootbox: "赃物箱",
        media: "媒体",
    },

    // Transaction
    modal: {
        title: {
            market_purchase: "市场",
            claim_rewards: "索取奖励",
            authentication: "验证",
            slime_bridge: "$SLIME 桥梁",
            item_bridge: "物品桥梁",
            lootbox_bridge: "战利品箱桥梁",
            squishie_adventure: "Squishie 大冒险",
        },
        body: {
            transaction_failed: '交易失败！',
            transaction_complete: '交易完成！',
            transaction_pending: '交易待处理…',
            transaction_confirming: '正在确认交易...请稍候。',
            transaction_wrong: '出了些问题。 请再试一遍。',
            view_etherscan: "在 Etherscan 上查看",
            item_bridge_warning: "换出之前友情提示，当你换到链上的时候，这个过程是不能取消的！",
            transaction_interrupt_retry: "如果此交易被中断，请重试，最后一次不成功的索赔将被重新处理。",
            lootbox_bridge_warning: "换出之前友情提示，当你换NFTs到链上时，这个过程是不能取消的！",
            fifo_issuance: "我们的智能合约保管 NFT 并以先到先得的方式发送它们。",
            lootbox_nft_unavailable: "如果我们没有可用的 NFT，您的交易将失败，您只需等待我们补充合约即可。",
            slime_swap_warning: "在你换出之前，这是一个友情提醒，当你用 $xSLIME 换成 $SLIME 时，你不能取消这个过程！",
            slime_swap: "您的 $xSLIME 余额将减少，并且将生成一个声明签名，必须用于兑换您的 {amount} $SLIME。",
            nothing_to_show_product: "该产品没有可显示的内容"
        },
    },

    // Homepage
    home: {
        collection: {
            p1: "欢迎来到Squishiverse，那里可爱，数字收藏品就栩栩如生。 受亚洲媒体的标志性史莱姆（Slime）角色的启发，我们的Web3本地品牌提供了广泛的令人心动的体验，从收藏品和故事到商品等等。 作为Metaverse的领导者，我们致力于推动Web3的界限并提供创新的体验。",
            p2: "作为一名Squishie持有者，您将成为一个重视授权和力量的紧密联系社区的一部分。 您将可以在整个Squishiverse生态系统中访问独家令牌门控渠道，活动，个人IP机会，白名单，NFT和其他好处。 加入我们，发现Squishiverse必须提供的一切。",
        },
        features: {
            play_now: "立即玩",
            see_more: "查看更多",
            learn_more: "学更多",
            official_website: "官方网站",
            instagram: "Instagram",
            youtube: "YouTube",
            squishiland: {
                title: "Squishiland",
                subtitle: "创世纪土地",
                p1: "Squishiland是有限的可定制土地集合，构成了Squishiverse可互操作元的基础。",
                p2: "持有情节可让您完全控制其设计和配置，这反映在所有二级市场中。 您可以使用情节创建商店，房屋，酒店，博物馆或您可以想象的任何其他结构。 Squishiland的创造力和发展的机会无穷无尽。"
            },
            metaverse: {
                title: "Metaverse",
                subtitle: "MMORPG METAVERSE",
                p1: "许多Web3游戏和元游戏都难以维持玩家群，因为它们缺乏吸引人的游戏玩法。 Squishiverse会有所不同。 我们正在为所有玩家提供乐趣，有益，社交和互操作的经验，为所有玩家奠定基础。",
                p2: "我们的Web3平台将将成功的MMORPG的最佳元素与Web3的先进技术相结合，提供真正独特且愉快的体验，重点关注分散资产的所有权。",
            },
            ip: {
                title: "IP利用率",
                subtitle: "Squishiverse Branding",
                p1: "在Squishiverse，我们的目标是通过我们创造的产品增强社区权力并加强知识产权（IP）的使用。 持有人的柔和在我们的原始动画系列以及高质量的GIF和模因中都有特色，这些gif和模因有助于构建每个Squishie的个人IP。",
                p2: "商品，玩具，豪华，动画，模因等，所有这些都促成了Squishiverse作为IP利用率的声誉。"
            },
            descend: {
                title: "下降 (Descend)",
                subtitle: "地牢爬行游戏",
                p1: "这款类似2D的Rogue般的地牢横梁游戏体验高赌注，并有可能获得巨大的回报，每次都有完全独特的玩法。",
                p2: "作为所有人的季节性Gamefi游戏，下降奖励其最熟练和大胆的球员，最低奖金为10K USDC，NFTS和SBTS。"
            }
        },
        team: {
            the_team: "团队",
            community_management: "社区管理",
            roles: {
                founder: "创始人",
                artist: "艺术家",
                cofounder: "联合创始人",
                developer_lead: "首席软件工程师",
                web3_developer: "Web3 软件工程师",
                metaverse_lead: "Game 首席工程师",
                metaverse_developer: "Game 软件工程师",
                creative_help: "创意帮助",
            },
        },
    },

    // Staking Page
    staking: {
        join_squishies: "加入 {num} Squishies 在一个",
        adventure: "冒险",
        claim_rewards: "认领奖励",
        farming_yield_rate: "种植 {totalYield} $xSLIME / 天",
        switch_to_land: "切换到土地",
        switch_to_squishies: "切换到Squishies",
        loading_your: "加载你的 {what}, 请稍等!",
        dont_own_any: "你不拥有任何 {what}",
    },

    // Marketplace
    marketplace: {
        header: "市场",
        categories: "类别",
        loading_marketplace: "加载市场",
        filters_all: "过滤",
        view_order_history: "查看订单历史",
        filters: {
            all: "全部",
            active: "现行",
            newest: "最新",
            oldest: "最老",
            lowest_price: "最低价格",
            highest_price: "最高价",
        },
    },

    // Inventory
    inventory: {
        header: "库存",
        swap_slime: "交换 Slime",
        you_will_receive: "您将收到",
        swap_for: "换成",
        swap_to: "交换到",
        same_loot_withdraw_tip: "提示：您可以提取多个相同类型的战利品",
        disclaimer: {
            slime: {
                p1: "如果交易失败，请重新尝试任何金额，它将再次尝试处理上次索赔",
                p2: "从 $xSLIME 转换为 $SLIME 时收取 {calculatedFee} 的费用",
                p3: "发送到 $xSLIME 的交换可能需要最多 10 分钟才能到达",
            },
            item: {
                p1: "L1 项目余额最多可能需要 10 分钟才能准确反映",
                p2: "再次单击交换按钮以重新尝试失败或取消的交换",
            },
            loot: {
                p1: "智能合约保管 NFT，并以先到先得的方式分发",
                p2: "再次单击交换按钮以重新尝试失败或取消的交换",
            }
        },
        swap_your_items: "交换您的 {layer} 项目",
        no_inventory: "没有库存可显示",
    },

    // Lootboxes
    lootbox: {
        header: "战利品箱",
        you_have_won: "你赢了！",
        fairness_roll_hash: "公平滚动哈希",
        lootbox_content_header: "战利品箱内容",
        lootbox_content_disclaimer: "免责声明：显示的 NFT 可能不代表您提取到钱包后收到的 NFT。 我们的智能合约定期使用 NFT 提供资金，并且 NFT 提款按照先到先得的原则提出。"
    },

    // Media
    media: {
        header: "媒体",
        gifs: "GIFs",
        animation: "动画",
        wallpapers: "壁纸",
        manga: "漫画",
        extra: "额外的",
        wrong_input: "您的输入有问题，请重试。",
        enter_squishie: "输入您的 Squishie 号码（使用逗号加载多个",
        see_more_giphy: "在Giphy查看更多",
    },

    // Settings
    settings: {
        header: "帐号设定",
        discord_account: "Discord帐号",
        add_discord_account: "添加您的 Discord 帐户",
    },
}