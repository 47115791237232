<template>
  <div class="lootbox-selection">
    <div
      class="lootbox-selection__item"
      v-for="lootbox in lootboxes"
      v-bind:class="{
        active: isActiveLootbox(lootbox),
        unavailable: lootbox.quantity <= 0,
      }"
      @click="changeLootbox(lootbox)"
      :key="lootbox.id"
    >
      <span class="count">{{ lootbox.quantity }}</span>
      <div class="image">
        <img :src="lootboxThumbnail(lootbox.name)" draggable="false" />
      </div>
    </div>
  </div>
</template>

<script>
import { lootboxes } from "../../helpers/lootboxes";
export default {
  name: "LootboxSelection",
  props: {},
  computed: {
    lootboxes() {
      const vm = this;
      return lootboxes
        .sort((a, b) => a.order - b.order)
        .filter((l) => l.hide !== true)
        .map((l) => {
          const userLootbox =
            (vm.$store.state.user.lootboxes || []).filter(
              (ul) => ul.lootboxId === l.id
            )[0] || null;
          const quantity = userLootbox ? userLootbox.quantity : 0;
          return Object.assign(l, { quantity });
        });
    },
  },
  methods: {
    isActiveLootbox(lootbox) {
      return this.$store.state.lootbox === lootbox.id;
    },
    changeLootbox(lootbox) {
      this.$store.commit("setLootbox", lootbox.id);
    },
    lootboxThumbnail(lootbox) {
      return require(`@/assets/images/lootbox/lootboxes/${lootbox}.png`);
    },
  },
};
</script>

<style lang="scss">
.lootbox-selection {
  position: absolute;
  bottom: -19rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  width: 100%;
  place-content: center;
  &.videoPlaying {
    pointer-events: none;
    opacity: 0;
  }
  &__item {
    width: 64px;
    line-height: 0;
    transition: all 0.2s;
    position: relative;
    cursor: pointer;
    .image {
      // background: $black;
      border-radius: $radius-md;
      border: 3px solid $black;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .count {
      width: 24px;
      height: 24px;
      position: absolute;
      right: -0.25rem;
      top: -0.25rem;
      background: $black;
      color: $white;
      font-size: $font-xxxs;
      border-radius: 999px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
    }
    &.active {
      .image {
        border: 3px solid $green;
      }
      .count {
        background: $green;
        color: $black;
      }
    }
    &.active.unavailable {
      .image {
        border: 3px solid $red;
      }
      .count {
        background: $red;
        color: $black;
      }
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}

// .hero-lootbox.ogLootbox {
//   .lootbox-selection {
//     bottom: -25rem;
//   }
// }

@media screen and (min-width: $screen-sm) {
  .lootbox-selection {
    bottom: -5rem;
  }

  // .hero-lootbox.ogLootbox {
  //   .lootbox-selection {
  //     bottom: -10rem;
  //   }
  // }
}
@media screen and (min-width: $screen-md) {
  .lootbox-selection {
    bottom: -10rem;
  }
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>