export default {
    // Languages:
    languages: {
        en: "English",
        cn: "中文",
        jp: "日本",
        vn: "Tiếng Việt",
        th: "ไทย",
        es: "Español",
        ru: "Русский",
    },

    // Generic
    generic: {
        connect_wallet: "เชื่อมต่อ Wallet",
        wallet_not_connected: "Wallet ของคุณไม่ได้เชื่อมต่อ",
        squishie: "Squishie | Squishies",
        land: "Land | Lands",
        lootbox: "Lootbox | Lootboxes",
        your: "ของคุณ",
        balance: "Balance",
        farmed: "Farmed",
        collection: "ของสะสม Squishi<span class='fix'></span>verse",
        footer: "Squishiverse &copy; 2022. All rights reserved.",
        nothing_to_show: "ไม่มีอะไรให้แสดง",
        buy_now: "ซื้อตอนนี้",
        buy_now_qty: "ซื้อตอนนี้{quantity}",
        unavailable: "ไม่พร้อมใช้งาน",
        close: "ปิด",
        settings: "ตั้งค่า",
        beta: "ทดสอบ",
        items: "ไอเท็ม",
        i_understand: "ฉัน เข้าใจแล้ว",
        cancel: "ยกเลิก",
        max: "เต็ม",
        claim: "รับ",
        loading: "กำลังโหลด",
        fetch: "ดึงข้อมูล",
        episode: "ตอน",
        connect: "เชื่อมต่อ",
        reconnect: "เชื่อมต่ออีกครั้ง",
        open: "เปิด",
    },

    // Navigation
    nav: {
        home: "หน้าหลัก",
        staking: "สะสมแต้ม",
        marketplace: "ตลาด",
        inventory: "ช่องเก็บของ",
        lootbox: "กล่องสุ่ม",
        media: "สื่อ",
    },

    // Transaction
    modal: {
        title: {
            market_purchase: "ซื้อในตลาด",
            claim_rewards: "รับรางวัล",
            authentication: "Authentication",
            slime_bridge: "$SLIME Bridge",
            item_bridge: "ไอเท็ม Bridge",
            lootbox_bridge: "กล่องสุ่ม Bridge",
            squishie_adventure: "Squishie ผจญภัย",
        },
        body: {
            transaction_failed: 'การทำธุระกรรมล้มเหลว!',
            transaction_complete: 'การทำธุระกรรมสำเร็จ!',
            transaction_pending: 'กำลังดำเนินการ...',
            transaction_confirming: 'ยืนยันการทำธุรกรรม... กรุณารอสักครู่',
            transaction_wrong: 'มีบางอย่างผิดพลาด กรุณาลองอีกครั้ง',
            view_etherscan: "ดูบน Etherscan",
            item_bridge_warning: "ก่อนที่คุณจะแลกเปลี่ยน นี่คือการแจ้งเตือนที่เป็นมิตรเมื่อคุณแลกเปลี่ยนไอเท็มไป on-chain คุณจะไม่สามารถยกเลิกกระบวนการนี้ได้!",
            transaction_interrupt_retry: "หากธรุกรรมถูกขัดจังหวะ ให้ลองอีกครั้งแล้วการรับของครั้งล่าสุดจะถูกทำใหม่อีกครั้ง",
            lootbox_bridge_warning: "ก่อนที่คุณจะแลกเปลี่ยน นี่คือการแจ้งเตือนที่เป็นมิตรเมื่อคุณแลกเปลี่ยน NFTs ไป on-chain คุณจะไม่สามารถยกเลิกกระบวนการนี้ได้!",
            fifo_issuance: "สัญญาอัจฉริยะจะดูแล NFTs และส่งตามลำดับก่อนหลัง",
            lootbox_nft_unavailable: "การทำธุระกรรมของคุณจะล้มเหลวหากยังไม่มี NFTs ที่พร้อมใช้งาน คุณเพียงแค่ต้องรอจนกว่าเราจะทำสัญญาให้สมบูรณ์",
            slime_swap_warning: "ก่อนที่คุณจะแลกเปลี่ยน นี่คือการแจ้งเตือนที่เป็นมิตรเมื่อคุณแลกเปลี่ยน $xSLIME เป็น $สไลม์ คุณจะไม่สามารถยกเลิกกระบวนการนี้ได้!",
            slime_swap: "ยอดคงเหลือ $xSLIME ของคุณจะลดลง และลายเซ็นการอ้างสิทธิ์จะถูกสร้างขึ้นเพื่อนำมาใช้แลก {amount} $SLIME ของคุณ",
            nothing_to_show_product: "ไม่มีอะไรที่จะแสดงสำหรับสินค้านี้"
        },
    },

    // Homepage
    home: {
        collection: {
            p1: "ยินดีต้อนรับสู่ Squishiverse ที่ซึ่งมีของสะสมดิจิทัลสุดน่ารักมีชีวิตขึ้นมา โดยได้แรงบันดาลใจมาจากตัวละครสไลม์อันโด่งดังจากสื่อเอเชีย แบรนด์ Web3-native ของเรานำเสนอประสบการณ์อันอบอุ่นใจที่หลากหลาย ตั้งแต่ของสะสมและเรื่องราวไปจนถึงสินค้าและอื่นๆ อีกมากมาย.",
            p2: "ในฐานะผู้ถือ Squishie คุณจะเป็นส่วนหนึ่งของชุมชนที่แน่นแฟ้นซึ่งให้ความสำคัญกับการเสริมอำนาจและความแข็งแกร่ง คุณจะมีสิทธิ์เข้าถึงแชนเนล กิจกรรม โอกาส IP แต่ละรายการ รายการที่อนุญาตพิเศษ NFT และสิทธิประโยชน์อื่น ๆ ทั่ว Squishiverse ecosystemเข้าร่วมกับพวกเราและค้นพบทุกสิ่งที่ Squishiverse มีให้.",
        },
        features: {
            play_now: "Play Now",
            see_more: "ดูข้อมูลเพิ่มเติม",
            learn_more: "เรียนรู้เพิ่มเติม",
            official_website: "เว็บไซต์ทางการ",
            instagram: "Instagram",
            youtube: "YouTube",
            squishiland: {
                title: "Squishiland",
                subtitle: "Genesis Land Plots",
                p1: "SquishiLand เป็นคอลเลกชันจำกัดของแปลงที่ดิน(Plots)ที่ปรับแต่งได้ซึ่งเป็นรากฐานของ metaverse ที่ทำงานร่วมกันภายในของ Squishiverse.",
                p2: "การถือครอง Plots ทำให้คุณสามารถความคุมการออกแบบแล้วกำหนดค่าได้อย่างสมบุรณ์ ซึ่งสะท้อนให้เห็นตลาดแลกเปลี่ยนทั้งหมด คุณสามารถใช้ Plots ของคุณเพื่อสร้างร้านค้า บ้าน โรงแรม พิพิธภัณฑ์ หรือสิ่งก่อสร้างอื่น ๆ ที่คุณจินตนาการได้ โอกาศในการสร้างสรรค์และพัฒนาไม่มีสิ้นสุดกับ SquishiLand."
            },
            metaverse: {
                title: "Metaverse",
                subtitle: "MMORPG METAVERSE",
                p1: "เกม Web3 และ metaversrs จำนวนมากพยามรักษาฐานผู้เล่นไว้เนื่องจากขาดรูปแบบการเล่นที่น่าสนใจ Squishiverse จะแตกต่างออกไป เรากำลังวางรากฐานสำหรับประสบการณ์ที่สนุก คุ้มค่า เข้าสังคมและใช้งานร่วมกันได้สำหรับผู้เล่นทุกคน.",
                p2: "แพลตฟอร์ม web3 ของเราจะผสมผสานองค์ประกอบที่ดีที่สุดของเกม MMORPG ที่ประสบความสำเร็จเข้ากับเทคโนโลยีขั้นสูงของ Web3 ซึ่งจะมอบประสบการณ์ที่แปลกใหม่และสนุกสนานอย่างแท้จริง โดยเน้นที่การเป็นเจ้าของเนื้อหาแบบกระจายศูนย์.",
            },
            ip: {
                title: "IP Utilization",
                subtitle: "การสร้างแบรนด์ Squishiverse",
                p1: "ที่ Squishiverse เป้าหมายของเราคือการเสริมพลังชุมชนของเราและเสริมสร้างการใช้ทรัพย์สินทางปัญญา (IP) ผ่านผลิตภัณฑ์ที่เราสร้างขึ้น ผู้ถือครอง Squishies มีอยู่ในซีรีส์ original animated ของเรา เช่นเดียวกับ GIF และ memes คุณภาพสูงที่ช่วยสร้าง IP เฉพาะของ Squishie แต่ละตัว.",
                p2: "สินค้า ของเล่น ตุ๊กตา แอนิเมชั่น memes ฯลฯ ทั้งหมดนี้มีส่วนทำให้ Squishiverse มีชื่อเสียงในฐานะแหล่งขุมพลังของการใช้ทรัพย์ทางปัญญา."
            },
            descend: {
                title: "Descend",
                subtitle: "Dungeon Crawler Game",
                p1: "ประสบการณ์เดิมพันสูงและโอกาสในการรับรางวัลมากมายกับเกมตะลุย ดันเจี้ยนแบบ 2D rogue-like ที่มีรูปแบบการเล่นที่ยูนิคไม่ซ้ำใคร.",
                p2: "ในฐานะที่เป็นเกม GameFi ตามฤดูกาลสำหรับทุกคน Descend ให้รางวัลแก่ผู้เล่นที่มีสกิลและใจถึงที่สุดด้วยเงินรางวัลรวมขั้นต่ำ 10,000 USDC, NFT และ SBTs."
            }
        },
        team: {
            the_team: "ทีมงาน",
            community_management: "จัดการชุมชน",
            roles: {
                founder: "ผู้สร้าง",
                artist: "ศิลปิน",
                cofounder: "ผู้ร่วมก่อตั้ง",
                developer_lead: "หัวหน้านักพัฒนา",
                web3_developer: "นักพัฒนา Web3",
                metaverse_lead: "หัวหน้า Game",
                metaverse_developer: "นักพัฒนา Game",
                creative_help: "ผู้ช่วย Creative",
            },
        },
    },

    // Staking Page
    staking: {
        join_squishies: "{num} เข้าร่วม Squishies On A",
        adventure: "การผจญภัย",
        claim_rewards: "รับรางวัล",
        farming_yield_rate: "กำลังทำฟาร์ม {totalYield} $SLIME /วัน",
        switch_to_land: "เปลื่ยนเป็น Land",
        switch_to_squishies: "เปลื่ยนเป็น Squishies",
        loading_your: "กำลังโหลด {what} ของคุณ โปรดรอสักครู่!",
        dont_own_any: "คุณไม่ได้เป็นเจ้าของ {what}",
    },

    // Marketplace
    marketplace: {
        header: "ตลาดสินค้า",
        categories: "หมวดหมู่",
        loading_marketplace: "กำลังโหลด มาร์เก็ต...",
        filters_all: "ตัวกรอง",
        view_order_history: "ดูประวัติการสั่งซื้อ",
        filters: {
            all: "ทั้งหมด",
            active: "พร้อมใช้งาน",
            newest: "ใหม่ที่สุด",
            oldest: "เก่าที่สุด",
            lowest_price: "ราคาต่ำสุด",
            highest_price: "ราคาสูงสุด",
        },
    },

    // Inventory
    inventory: {
        header: "รายการสิ่งของ",
        swap_slime: "แลกสไลม์",
        you_will_receive: "คุณจะได้รับ",
        swap_for: "แลกเป็น",
        swap_to: "เปลี่ยนเป็น",
        same_loot_withdraw_tip: "เคล็ดลับ : คุณสามารถรับของรางวัลประเภทเดียวกันได้หลายรายการ",
        disclaimer: {
            slime: {
                p1: "หากทำธรุกรรมล้มเหลว โปรดลองอีกครั้งด้วยจำนวนเงินใดๆ และจะพยามประมวลผลการเรียกร้องอีกครั้ง",
                p2: "ค่าธรรมเนียม {calculatedFee} เมื่อเปลี่ยนจาก $xSLIME เป็น $SLIME",
                p3: "การแลกเปลี่ยนเป็น $xSLIME อาจใช้เวลา 10 นาที ในการส่ง",
            },
            item: {
                p1: "ยอดคงเหลือ ของ ไอเท็ม L1 อาจใช้เวลาถึง 10 นาทีในการแสงผลอย่างถูกต้อง",
                p2: "กดปุ่มแลกเปลี่ยนอีกครั้ง ถ้าล้มเหลว หรือ ยกเลิกการแลกเปลี่ยน",
            },
            loot: {
                p1: "สัญญาอัจฉริยะจะดูแล NFTs และแจกจ่ายตามลำดับก่อนหลัง",
                p2: "กดปุ่มแลกเปลี่ยนอีกครั้ง ถ้าล้มเหลว หรือ ยกเลิกการแลกเปลี่ยน",
            }
        },
        swap_your_items: "แลกเปลี่ยนไอเท็ม {layer} ของคุณ",
        no_inventory: "ไม่มีสิ่งของในคลัง",
    },

    // Lootboxes
    lootbox: {
        header: "Lootboxes",
        you_have_won: "คุณชนะแล้ว",
        fairness_roll_hash: "Fairness Roll Hash",
        lootbox_content_header: "Lootbox Contents",
        lootbox_content_disclaimer: "ข้อจำกัดความรับผิดชอบ: NFTs ที่แสดงอาจไม่ได้เป็นตัวแทนของสิ่งที่คุณที่คุณได้รับเมื่อคุณถอนเงินไปยังกระเป๋าเงินของคุณ สัญญาอัจฉริยะของเราจะได้รับเงินสนับสนุนเป็นระยะด้วย NFTs และการถอน NFTs จะอ้างสิทธิ์ตามลำดับก่อนหลัง."
    },

    // Media
    media: {
        header: "สื่อ",
        gifs: "GIFs",
        animation: "อนิเมชั่น",
        wallpapers: "ภาพพื้นหลัง",
        manga: "มังงะ",
        extra: "โบนัส",
        wrong_input: "มีบางอย่างผิดปกติกับการป้อนข้อมูลของคุณ โปรดลองอีกครั้ง.",
        enter_squishie: "ป้อนหมายเลข Squishie(ใช้เครื่องหมายจุลภาค( . )เพื่อโหลดหลายรายการ)",
        see_more_giphy: "ดูเพิ่มเติมที่ Giphy!",
    },

    // Settings
    settings: {
        header: "การตั้งค่าบัญชี",
        discord_account: "บัญชี Discord",
        add_discord_account: "เพิ่มบัญชี Discord ของคุณ",
    },
}