<template>
  <div class="modal">
    <div class="modal-overlay" @click.self="$emit('closePrompt')"></div>
    <div class="modal-container">
      <div class="modal-header">
        <h1>{{ project.name }}</h1>
      </div>
      <div class="modal-body">
        <h3 v-if="entries === null">{{ $t('generic.loading')}}...</h3>
        <p v-else-if="!entries.length">
          {{ $t('modal.body.nothing_to_show_product')}}
        </p>
        <pre v-else>{{ formattedWhitelist }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TransactionModal",
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {},
  computed: {
    formattedWhitelist() {
      const vm = this;
      return (vm.entries || [])
        .map(({ recipient, discord, quantity }) =>
          `${recipient}\t${discord}\n`.repeat(quantity)
        )
        .join("");
    },
  },
  methods: {
    async loadWhitelist() {
      const vm = this;
      const { data } = await axios.get(
        `${vm.$apiUrl}/products/${vm.project.id}/whitelist`
      );
      vm.entries = data.message;
    },
  },
  mounted() {
    this.loadWhitelist();
  },
  data() {
    return {
      entries: null,
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7.5px);
  }
}

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  color: $black;
  font-weight: 600;

  h1 {
    font-family: $font-primary;
    margin-top: 0;
  }

  &-overlay {
    display: block;
    position: absolute;
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
  }

  &-container {
    width: 100%;
    z-index: 9999;
    max-width: 750px;
    padding: 1rem;
  }

  &-body {
    top: 0;
    left: 0;
    background: $white;
    z-index: 9999;
    margin: auto 0;
    text-align: center;
    @include threedee-box(3px);
    position: relative;
    padding: 2rem 3rem;
    border-radius: $radius-md;

    pre {
      text-align: left;
      background: rgba($black, 0.05);
      // border-radius: 10px;
      padding: 1rem;
      max-height: 300px;
      overflow-y: auto;
    }

    a {
      color: black;
    }

    button {
      &.close {
        margin-top: 2rem;
      }
    }
  }

  &-header {
    text-align: center;
    z-index: 9998;
    position: relative;
    width: 100%;
    h1 {
      font-family: $font-primary;
      font-size: $font-lg;
      margin: 0;
      margin-bottom: 2rem;
      color: $white;
      @include threedee-text(4px);
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .modal {
    &-header {
      h1 {
        font-size: $font-xlg;
        margin-bottom: 0.2rem;
        @include threedee-text-extra(6px, 4px, $black);
      }
    }
  }
}
</style>
