<template>
  <div>
    <div class="bridge-bg"></div>
    <BridgeHero />
    <Bridge />
  </div>
</template>

<script>
import { pages } from "../../vue.config";
import BridgeHero from "../components/Hero/BridgeHero.vue";
import Bridge from "../components/Bridge/Bridge.vue";

export default {
  name: "InventoryView",
  metaInfo: {
    title: pages.media.title,
    meta: [{ name: "description", content: pages.inventory.metaDescription }],
  },
  components: {
    BridgeHero,
    Bridge,
  },
  mounted() {
    const vm = this;
    if (vm.$store.state.account !== null) {
      vm.$store.dispatch("fetchUserRewards", vm.$store.state.account);
      vm.$store.dispatch("fetchUserInventory");
      vm.$store.dispatch("fetchUserTokenInventory");
    }
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.bridge-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  background: $sand;
  background-position: top;
  z-index: -1;
}
@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
