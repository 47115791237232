<template>
  <div class="features">
    <div class="container">
      <div class="features-item features-item__bg-sky">
        <div class="features-item__content">
          <ul class="features-item__content-dots">
            <li class="active">&nbsp;</li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
          </ul>
          <div class="features-item__content__wrapper">
            <div class="features-item__content-header">
              {{ $t("home.features.squishiland.title") }}
            </div>
            <div class="features-item__content-subtitle">
              {{ $t("home.features.squishiland.subtitle") }}
            </div>
            <div class="features-item__content-description">
              <p>
                {{ $t("home.features.squishiland.p1") }}
              </p>
              <p>
                {{ $t("home.features.squishiland.p2") }}
              </p>
              <div class="features-item__content-preview">
                <FeatureCarousel
                  :images="[
                    'squishiland-1.mp4',
                    'squishiland-2.png',
                    'squishiland-3.png',
                  ]"
                />
              </div>
              <div class="features-item__content-description__links">
                <SimpleButton class="website" href="https://squishiland.com">
                  {{ $t("home.features.play_now") }}
                </SimpleButton>
                <SimpleButton href="https://opensea.io/collection/squishiland">
                  {{ $t("home.features.see_more") }}
                </SimpleButton>
              </div>
            </div>
          </div>
        </div>
        <div class="features-item__head-image">
          <img src="@/assets/images/herov2/squishiland-land.png" />
        </div>
      </div>
      <div class="features-item features-item__bg-metaverse features-item__odd">
        <div class="features-item__head-image"></div>
        <div class="features-item__content">
          <div class="features-item__content__wrapper">
            <ul class="features-item__content-dots">
              <li>&nbsp;</li>
              <li class="active">&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
            </ul>
            <div class="features-item__content-header">
              {{ $t("home.features.metaverse.title") }}
            </div>
            <div class="features-item__content-subtitle">
              {{ $t("home.features.metaverse.subtitle") }}
            </div>
            <div class="features-item__content-description">
              <p>
                {{ $t("home.features.metaverse.p1") }}
              </p>
              <!-- <p>
                {{ $t("home.features.metaverse.p2") }}
              </p> -->
              <div class="features-item__content-preview">
                <FeatureCarousel
                  :images="[
                    'metaverse-1.mp4',
                    'metaverse-2.mp4',
                    'metaverse-3.png',
                  ]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="features-item features-item__bg-ip features-item__odd">
        <div class="features-item__head-image"></div>
        <div class="features-item__content">
          <ul class="features-item__content-dots">
            <li>&nbsp;</li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
            <li class="active">&nbsp;</li>
          </ul>
          <div class="features-item__content__wrapper">
            <div class="features-item__content-header">
              {{ $t("home.features.ip.title") }}
            </div>
            <div class="features-item__content-subtitle">
              {{ $t("home.features.ip.subtitle") }}
            </div>
            <div class="features-item__content-description">
              <p>
                {{ $t("home.features.ip.p1") }}
              </p>
              <p>
                {{ $t("home.features.ip.p2") }}
              </p>
              <div class="features-item__content-preview">
                <FeatureCarousel
                  :images="['ip-1.mp4', 'ip-4.png', 'ip-3.png']"
                />
              </div>
              <div class="features-item__content-description__links">
                <!-- <SimpleButton class="website" to="/media">Media</SimpleButton> -->
                <SimpleButton
                  class="website"
                  href="https://giphy.com/squishiverse"
                >
                  GIPHY
                </SimpleButton>
                <SimpleButton href="https://instagram.com/squishiverse">
                  {{ $t("home.features.instagram") }}
                </SimpleButton>
                <SimpleButton href="https://www.youtube.com/@Squishiverse">
                  {{ $t("home.features.youtube") }}
                </SimpleButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="features-item features-item__bg-descend">
        <div class="features-item__content">
          <ul class="features-item__content-dots">
            <li>&nbsp;</li>
            <li>&nbsp;</li>
            <li class="active">&nbsp;</li>
            <li>&nbsp;</li>
          </ul>
          <div class="features-item__content__wrapper">
            <div class="features-item__content-header">
              {{ $t("home.features.descend.title") }}
            </div>
            <div class="features-item__content-subtitle">
              {{ $t("home.features.descend.subtitle") }}
            </div>
            <div class="features-item__content-description">
              <p>
                {{ $t("home.features.descend.p1") }}
              </p>
              <!-- <p>
                {{ $t("home.features.descend.p2") }}
              </p> -->
              <div class="features-item__content-preview">
                <FeatureCarousel
                  :images="['descend-1.mp4', 'descend-2.png', 'descend-3.png']"
                />
              </div>
              <div class="features-item__content-description__links">
                <SimpleButton class="website" href="https://descend.gg">
                  {{ $t("home.features.official_website") }}
                </SimpleButton>
                <SimpleButton href="https://twitter.com/descendgg">
                  {{ $t("home.features.learn_more") }}
                </SimpleButton>
              </div>
            </div>
          </div>
        </div>
        <div class="features-item__head-image"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleButton from "../ui/Button/SimpleButton.vue";
import FeatureCarousel from "./FeatureCarousel.vue";
export default {
  name: "Features",
  props: {},
  methods: {},
  components: {
    SimpleButton,
    FeatureCarousel,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.features {
  background: #001127;
  color: $white;
  display: grid;
  gap: 3rem;
  padding: 1rem;
  &-item {
    border-radius: $radius-md;
    @include threedee-box(3px, $black);
    overflow: hidden;
    display: grid;
    margin-bottom: 3rem;
    &__bg-sky {
      background: url("~@/assets/images/herov2/squishiland-bg.png");
      background-size: 300% 100%;
      background-position: center;
      @include threedee-box(3px, $green);
      .features-item__content-subtitle {
        color: $green;
      }
      .features-item__content-preview {
        border-color: $green;
      }
      .website {
        background: $green;
      }
    }
    &__bg-metaverse {
      background: url("~@/assets/images/herov2/metaverse.jpg");
      background-size: 300% 100%;
      background-position: center;
      @include threedee-box(3px, $blue);
      .features-item__content-subtitle {
        color: $blue;
      }
      .features-item__content-preview {
        border-color: $blue;
      }
      .website {
        background: $blue;
      }
    }
    &__bg-descend {
      background: url("~@/assets/images/herov2/descend.png");
      background-size: 300% 100%;
      background-position: -525px -250px;
      @include threedee-box(3px, $gold);
      .features-item__content-subtitle {
        color: $gold;
      }
      .features-item__content-preview {
        border-color: $gold;
      }
      .website {
        background: $gold;
      }
    }
    &__bg-ip {
      background: url("~@/assets/images/herov2/ip.png");
      background-size: 300% 100%;
      background-position: -200px;
      @include threedee-box(3px, $pink);
      .features-item__content-subtitle {
        color: $pink;
      }
      .features-item__content-preview {
        border-color: $pink;
      }
      .website {
        background: $pink;
      }
    }
    &__head-image {
      display: grid;
      align-items: center;
      min-height: 311px;
      padding: 2rem;
      grid-row: 1;
      img {
        width: 100%;
      }
    }
    &__content {
      background: rgba($black, 0.75);
      padding: 1rem;
      &-dots {
        display: none;
      }
      &-header {
        @include threedee-text-extra(2px, 2px, $black);
        font-family: $font-primary;
        font-weight: 600;
        font-size: $font-md;
        text-transform: uppercase;
      }
      &-subtitle {
        margin-top: 1rem;
        text-transform: uppercase;
      }
      &-description {
        font-family: $font-secondary;
        font-size: $font-xxxs;
        font-weight: lighter;
        &__links {
          display: flex;
          gap: 1rem;
          margin: 1rem 0 0.5rem 0;
        }
      }
      &-preview {
        display: none;
      }
    }
    &__odd {
      .features-item__content-dots {
        right: 1.25rem;
        left: unset;
        &-dots {
          li {
            &.active {
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
  .features {
    > .container {
      width: 100%;
      max-width: unset;
      margin: 0;
    }
    &-item {
      position: relative;
      margin: 0 2rem;
      margin-bottom: 5rem;
      grid-template-columns: 40% 60%;
      // min-height: 750px;
      &:last-child {
        margin-bottom: 0;
      }
      &__bg-sky {
        background-size: cover;
        .features-item__content-subtitle {
        }
        .website {
        }
      }
      &__bg-metaverse {
        background-size: cover;
        .features-item__content-subtitle {
        }
        .website {
        }
      }
      &__bg-descend {
        background-size: cover;
        background-position: bottom;
        .features-item__content-subtitle {
        }
        .website {
        }
      }
      &__bg-ip {
        background-size: cover;
        background-position: center;
        .features-item__content-subtitle {
        }
        .website {
        }
      }
      &__head-image {
        // padding: 1rem;
        // max-height: unset;
        height: inherit;
        justify-self: center;
        align-self: center;
        grid-row: unset;
        padding: 4rem;
        img {
          width: 100%;
          height: 100%;
          max-height: 550px;
          object-fit: cover;
        }
      }
      &__content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0;
        &__wrapper {
          padding: 4rem;
        }
        &-dots {
          position: absolute;
          list-style-type: none;
          left: -1rem;
          li {
            display: block;
            width: 4px;
            height: 4px;
            background: $white;
            border: 2px solid $black;
            border-radius: 100%;
            margin-bottom: 0.75rem;
            opacity: 0.25;
            &.active {
              opacity: 1;
            }
          }
        }
        &-header {
          @include threedee-text-extra(3px, 3px, $black);
          font-size: $font-lg;
        }
        &-subtitle {
          font-size: $font-xs;
        }
        &-description {
          font-size: $font-xxs;
          &__links {
            margin: 1.5rem 0 0.5rem 0;
          }
        }
        &-preview {
          display: block;
          border-width: 3px;
          border-style: solid;
          border-radius: $radius-md;
          overflow: hidden;
          max-height: 300px;
          img {
            display: block;
            object-fit: cover;
            transform: scale(1.01);
            width: 100%;
            height: 100%;
          }
        }
      }
      &__odd {
        grid-template-columns: 60% 40%;
      }
    }
  }
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
