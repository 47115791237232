<template>
  <div class="purchases-table">
    <div class="container">
      <table>
        <thead>
          <tr>
            <th class="date">Date</th>
            <th class="items">Items</th>
            <th class="total">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="purchases === null">
            <td colspan="3" class="center">Loading your purchases...</td>
          </tr>
          <tr v-else-if="purchases.length < 1">
            <td colspan="3" class="center">
              {{ $t("generic.nothing_to_show") }}
            </td>
          </tr>
          <tr v-for="(purchase, id) in purchases" :key="id">
            <td>{{ new Date(purchase.createdAt).toLocaleString() }}</td>
            <td>
              <span v-if="purchase.orderItems.length === 0">
                <i>Products unavailable</i>
              </span>
              <span
                v-else
                v-html="orderItemsFormatted(purchase.orderItems)"
              ></span>
              <div v-if="purchase.serials.length" class="serial">
                <small v-html="serialToString(purchase)"></small>
              </div>
            </td>
            <td>{{ purchase.total }} $SLIME</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { marked } from "marked";
export default {
  name: "Purchases",
  props: {},
  computed: {
    purchases() {
      return this.$store.state.user.orders;
    },
  },
  mounted() {
    const vm = this;
    vm.$store.dispatch("loadUserOrderHistory");
  },
  methods: {
    orderItemsFormatted(orderItems) {
      return orderItems
        .map((i) =>
          marked.parseInline(
            `<b>${i.quantity}x</b> ${i.product.name} - ${i.product.description}`.replace(
              /\\n/g,
              " "
            )
          )
        )
        .join("\n");
    },
    serialToString(purchase) {
      return purchase.serials
        .map(({ serial, product }) => {
          if (product.name.toLowerCase().includes("super")) {
            return (
              `<a href="https://nonfungible.tools/subscription/squishiverse?code=${serial}"` +
              ` target="_blank">Redeem SuperSea Subscription (${serial})</a>`
            );
          }
          return `${product.name} Code: ${serial}`;
        })
        .join("<br>");
    },
  },
};
</script>

<style lang="scss">
.purchases-table {
  margin: 2rem 0 5rem 0;
  min-height: 100vh;
  width: 100%;

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  table {
    @include threedee-box(3px);
    background: $black;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    .serial {
      margin-top: 0.5rem;
      font-weight: 600;
      color: $blue;
      a {
        margin: 0;
        padding: 0;
        text-decoration: none;
        color: $blue;
      }
    }
    thead {
      background: $sand;
      .date {
        width: 20%;
      }
      .items {
        width: 60%;
      }
      .total {
        width: 20%;
      }
    }
    th,
    td {
      padding: 0.75rem 1rem;
      border-width: 3px;
      &.center {
        text-align: center;
        overflow: hidden;
      }
    }

    tbody {
      background: $white;
    }
  }
}
</style>