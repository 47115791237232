<template>
  <div class="lootbox-content-item">
    <Card :title="product.name" type="image">
      <VueLoadImage>
        <img slot="image" :src="productImage" draggable="false" />
        <div slot="preloader">
          <img
            src="@/assets/images/preloader.png"
            class="preloader"
            draggable="false"
          />
        </div>
        <img
          slot="error"
          src="@/assets/images/staking/placeholder.gif"
          draggable="false"
        />
      </VueLoadImage>
      <span class="probability" v-if="product.probability">
        {{ `${(product.probability / 10000).toFixed(3)}% CHANCE` }}
      </span>

      <div
        class="product-description"
        v-if="product.description"
        v-html="product.description"
      ></div>
      <ul class="product-links">
        <li v-if="product.contract.twitter">
          <a
            :href="`https://twitter.com/${product.contract.twitter}`"
            target="_blank"
            class="social twitter"
          >
            <TwitterSvg
              :style="{ width: '24px !important', height: '24px !important' }"
            />
          </a>
        </li>
        <li v-if="product.discord">
          <a :href="product.discord" target="_blank" class="social discord">
            <DiscordSvg
              :style="{ width: '24px !important', height: '24px !important' }"
            />
          </a>
        </li>
        <li v-if="product.contract && product.contract.id !== 1">
          <a
            :href="`https://opensea.io/collection/${product.contract.slug}`"
            target="_blank"
            class="social opensea"
          >
            <OpenseaSvg
              :style="{ width: '24px !important', height: '24px !important' }"
            />
          </a>
        </li>
        <!-- <li v-if="product.contract && product.contract.id !== 1">
          <a
            :href="`https://www.gem.xyz/collection/${product.contract.slug}`"
            target="_blank"
            class="social opensea"
          >
            <GemSvg
              :style="{ width: '24px !important', height: '24px !important' }"
            />
          </a>
        </li> -->
        <li v-if="product.contract && product.contract.id === 1">
          <a
            :href="`https://etherscan.io/address/${product.contract.address}`"
            target="_blank"
            class="social opensea"
          >
            <EtherscanSvg
              :style="{ width: '24px !important', height: '24px !important' }"
            />
          </a>
        </li>
      </ul>
    </Card>
  </div>
</template>

<script>
import Card from "../ui/Card";
import VueLoadImage from "vue-load-image";
import LongPress from "vue-directive-long-press";

import TwitterSvg from "../../assets/images/socials/Twitter.svg";
import DiscordSvg from "../../assets/images/socials/Discord.svg";
// import GemSvg from "../../assets/images/socials/Gem.svg";
import EtherscanSvg from "../../assets/images/socials/Etherscan.svg";
import OpenseaSvg from "../../assets/images/socials/Opensea.svg";

export default {
  name: "LootboxContentItem",
  props: {
    product: Object,
  },
  directives: {
    "long-press": LongPress,
  },
  computed: {
    isLocalImage() {
      return (this.product.image || "").startsWith("images/");
    },
    productImage() {
      if (this.isLocalImage) {
        return require(`@/assets/${this.product.image}`);
      } else {
        return this.product.image;
      }
    },
  },
  components: {
    Card,
    VueLoadImage,
    DiscordSvg,
    OpenseaSvg,
    TwitterSvg,
    // GemSvg,
    EtherscanSvg,
  },
};
</script>

<style lang="scss">
.lootbox-content-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .card-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: $font-md;
    @include threedee-text(2px);
    margin-bottom: 1.5rem;
    min-height: 5rem;
  }
  .card-body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 330px;
    height: 100%;
    max-width: 300px;
    background: $white !important;
    transition: all 0.2s;
    width: 100%;
    > img {
      height: 200px !important;
      width: 100% !important;
      object-fit: cover;
      border-bottom: 2px solid $black;
    }
    .probability {
      padding: 0.75rem 0.7rem;
      border-radius: 25px;
      position: absolute;
      font-size: $font-xxxs;
      top: 0.5rem;
      left: 0.5rem;
      background: rgba($black, 0.75);
      color: $white;
    }
    .supply,
    .purchases {
      top: 175px;
      right: 0.5rem;
      background: rgba($purple, 0.75);
      color: $white;
    }
    .purchases {
      background: rgba($blue, 0.75);
    }
  }

  .product-description {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    font-size: $font-xxxs;
    line-height: 150%;
    text-align: center;
    color: $black;
    font-weight: 600;

    p {
      padding: 0;
      margin-top: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .product-links {
    display: flex;
    list-style: none;
    padding: 0;
    margin: -1px -1px;
    padding-bottom: 1rem;
    justify-content: center;
    li {
      margin: 0 0.25rem;
      a {
        text-decoration: none;
        img {
          filter: brightness(0);
        }
      }
    }
  }
}
</style>
