<template>
  <div class="modal">
    <div class="modal-overlay" @click.self="overlayExit"></div>
    <div class="modal-container">
      <div class="modal-header">
        <h1 v-if="$store.state.transactionModal === 'marketplace'">
          {{ $t("modal.title.market_purchase") }}
        </h1>
        <h1 v-else-if="$store.state.transactionModal === 'rewards'">
          {{ $t("modal.title.claim_rewards") }}
        </h1>
        <h1 v-else-if="$store.state.transactionModal === 'authenticate'">
          {{ $t("modal.title.authentication") }}
        </h1>
        <h1 v-else-if="$store.state.transactionModal === 'bridge'">
          {{ $t("modal.title.slime_bridge") }}
        </h1>
        <h1 v-else-if="$store.state.transactionModal === 'bridge_item'">
          {{ $t("modal.title.item_bridge") }}
        </h1>
        <h1 v-else-if="$store.state.transactionModal === 'bridge_lootbox'">
          {{ $t("modal.title.lootbox_bridge") }}
        </h1>
      </div>
      <div class="modal-body">
        <img
          v-if="!$store.state.tx.completed && !$store.state.tx.failed"
          src="@/assets/images/squishie-blue.png"
          class="loader"
        />
        <h1 class="tx-failed" v-if="$store.state.tx.failed">
          {{ $t("modal.body.transaction_failed") }}
        </h1>
        <h1 class="tx-complete" v-else-if="$store.state.tx.completed">
          {{ $t("modal.body.transaction_complete") }}
        </h1>
        <h1 v-else>{{ $t("modal.body.transaction_pending") }}</h1>
        <!-- transaction body -->
        <div class="tx-hash" v-if="!$store.state.tx.failed">
          <div class="tx-minting">
            <span v-if="$store.state.tx.hash === false">
              {{ $store.state.tx.statusText }}
            </span>
            <span v-else>
              {{ $store.state.tx.statusText }}
              <a
                :href="'https://etherscan.io/tx/' + $store.state.tx.hash"
                target="_blank"
              >
                {{ $t("modal.body.view_etherscan") }}
              </a>
              <span v-if="$store.state.tx.completed === true">&#10003;</span>
            </span>
          </div>
          <div
            class="tx-confirm"
            v-if="$store.state.tx.hash && !$store.state.tx.completed"
          >
            {{ $t("modal.body.transaction_confirming") }}
          </div>
        </div>
        <div class="tx-hash" v-else>
          <div v-if="$store.state.tx.failedMsg">
            {{ $store.state.tx.failedMsg }}
          </div>
          <div v-else>
            {{ $t("modal.body.transaction_wrong") }}
          </div>
        </div>
        <Button
          class="close"
          @click="closePrompt"
          v-if="$store.state.tx.completed || $store.state.tx.failed"
        >
            {{ $t("generic.close") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../ui/Button.vue";
export default {
  name: "TransactionModal",
  components: {
    Button,
  },
  methods: {
    closePrompt() {
      const vm = this;
      vm.$store.state.transactionModal = false;
      vm.resetTransactionData();
    },
    resetTransactionData() {
      const vm = this;
      vm.$store.state.tx = {
        started: false,
        completed: false,
        failed: false,
        failedMsg: null,
        hash: false,
      };
      vm.$store.state.stakingSelection = [];
    },
    overlayExit() {
      const vm = this;
      if (vm.$store.state.tx.completed || !vm.$store.state.tx.started) {
        vm.closePrompt();
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7.5px);
  }
}

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  color: $black;
  font-weight: 600;

  h1 {
    font-family: $font-primary;
    margin-top: 0;
  }

  &-overlay {
    display: block;
    position: absolute;
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
  }

  &-container {
    width: 100%;
    z-index: 9999;
    max-width: 650px;
    padding: 1rem;
  }

  &-body {
    top: 0;
    left: 0;
    background: $white;
    z-index: 9999;
    margin: auto 0;
    text-align: center;
    @include threedee-box(3px);
    position: relative;
    padding: 2rem 3rem;
    border-radius: $radius-md;

    .connect {
      margin-top: 0;
    }

    .loader {
      max-width: 90px;
      margin-top: 1rem;
      margin-bottom: 1rem;
      animation: bounce 1s infinite;
    }

    .tx-complete {
      color: darken($green, 30%);
    }
    .tx-minting {
      span {
        white-space: break-spaces;
      }
    }
    .tx-failed {
      color: $red;
    }
    .tx-confirm {
      color: $blue;
    }
    .tx-hash {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 1.5rem;
      color: $black;
      text-decoration: none;
      > div {
        margin-bottom: 1rem;
      }
    }
    a {
      color: black;
    }

    button {
      &.close {
        margin-top: 2rem;
      }
    }
  }

  &-header {
    text-align: center;
    z-index: 9998;
    position: relative;
    width: 100%;
    h1 {
      font-family: $font-primary;
      font-weight: 600;
      text-transform: uppercase;
      font-size: $font-lg;
      margin: 0;
      margin-bottom: 2rem;
      color: $green-hot;
      @include threedee-text(4px);
      span {
        z-index: 1;
        position: relative;
        color: $red;
        + span {
          color: $blue;
          z-index: 0;
          margin-left: 4px;
        }
      }
    }

    h2 {
      font-size: $font-sm;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .modal {
    &-header {
      h1 {
        font-size: $font-xlg;
        margin-bottom: 0;
        @include threedee-text-extra(5px, 4px, $black);
      }
    }
  }
}
</style>
