<template>
  <div
    class="hero-lootbox"
    v-bind:class="{ ogLootbox: $store.state.lootbox === 0 }"
  >
    <Navbar />
    <div class="container">
      <div class="hero-header">
        <div class="chest-container">
          <div :class="`video-container lootbox-${$store.state.lootbox}`">
            <video
              ref="chest"
              :key="$store.state.lootbox"
              @ended="finishedVideo"
              muted
              playsinline
            >
              <source :src="lootboxVideo" :type="videoType" />
            </video>
            <audio ref="chestSound" playsinline>
              <source
                src="@/assets/images/lootbox/sfx-opening.ogg"
                type="audio/ogg"
              />
            </audio>
            <audio ref="chestOpenedSound" playsinline>
              <source
                src="@/assets/images/lootbox/sfx-opened.ogg"
                type="audio/ogg"
              />
            </audio>
          </div>
          <div class="rolling" v-if="videoPlaying">
            <div
              class="animated-roll"
              v-bind:class="{ ogLootbox: $store.state.lootbox === 0 }"
              v-if="!videoFinished"
            >
              <img
                v-for="item in tokenIdOutcomes || contractIdOutcomes"
                :key="item"
                :src="
                  itemImage(
                    tokenIdOutcomes ? item : null,
                    contractIdOutcomes ? item : null
                  )
                "
                v-bind:class="{
                  active: currentRoll === item,
                  final: videoFinished,
                }"
                :alt="item"
              />
            </div>
            <div
              class="finished-rolling"
              v-bind:class="{ show: videoFinished }"
            >
              <h2>{{ $t("lootbox.you_have_won") }}</h2>
              <div
                class="rolled-items"
                v-bind:class="{
                  'col-3': outcomes.length === 3,
                  'col-2': outcomes.length === 2,
                  'col-1': outcomes.length <= 1,
                }"
              >
                <Card
                  v-for="(outcome, idx) in outcomes"
                  :title="outcome.name"
                  type="image"
                  class="lootbox-item-card"
                  :key="`${outcome.name}__${idx}`"
                >
                  <img
                    :src="itemImage(outcome.tokenId, outcome.contractId)"
                    draggable="false"
                  />
                  <!-- <span class="quantity">QTY {{ outcome.quantity }}</span> -->
                </Card>
              </div>
              <div class="rolled-footer">
                <Button class="close" @click="close">
                  {{ $t("generic.close") }}
                </Button>
                <p class="fairness">
                  {{
                    $t("lootbox.fairness_roll_hash") + ": " + newServerSeedHash
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="hero-text" v-bind:class="{ videoPlaying }">
          <h2 class="page-title">{{ $t("lootbox.header") }}</h2>
        </div>
        <div class="lootbox-cta" v-bind:class="{ videoPlaying }">
          <Button
            class="btn-connect"
            v-if="$store.state.token === null"
            @click="connectWallet"
          >
            {{ $t("generic.connect_wallet") }}
          </Button>
          <LootboxRoll v-else @open="open" :key="$store.state.lootbox" />
        </div>

        <LootboxSelection
          v-bind:class="{ videoPlaying }"
          v-show="$store.state.token !== null"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../ui/Button";
import Card from "../ui/Card";
import Navbar from "./Navbar";
import LootboxSelection from "../Lootbox/LootboxSelection";
import LootboxRoll from "../Lootbox/LootboxRoll";
import { lootboxFromId } from "../../helpers/lootboxes";
import { supportsHEVCAlpha } from "../../helpers/video";

export default {
  name: "StakingHero",
  props: {
    lootbox: {
      type: String,
    },
  },
  components: {
    Card,
    Button,
    Navbar,
    LootboxSelection,
    LootboxRoll,
  },
  computed: {
    lootboxVideo() {
      const { name } = lootboxFromId(this.$store.state.lootbox);
      const format = supportsHEVCAlpha() ? "mov" : "webm";
      console.log("Supports ", format);
      return require(`@/assets/images/lootbox/lootboxes/${name}.${format}`);
    },
    videoType() {
      return supportsHEVCAlpha() ? "video/quicktime" : "video/webm";
    },
  },
  methods: {
    async connectWallet() {
      const vm = this;

      vm.$store.state.transactionModal = "authenticate";
      vm.$store.state.tx.started = true;
      vm.$store.state.tx.statusText = "Authenticating your wallet...";

      try {
        if (
          !(await vm.$store.dispatch("authenticate", { action: "lootbox" }))
        ) {
          throw { message: "Unable to authenticate wallet" };
        }

        vm.$store.state.tx.completed = true;
        vm.$store.state.tx.statusText = `Successfully logged in!`;
      } catch (err) {
        vm.$store.state.tx.failed = true;
        vm.$store.state.tx.failedMsg =
          err.message || "Transaction failed, unknown error";
        console.log(err);
      }
    },
    async open(quantity) {
      const vm = this;
      const { lootbox } = this.$store.state;
      console.log("OPEN TRIGGER");
      const { results, newServerSeedHash } = await vm.$store.dispatch(
        "openLootbox",
        { lootboxId: lootbox, quantity }
      );
      const { tokenOutcomes, contractOutcomes } = lootboxFromId(lootbox);
      // console.log(tokenOutcomes, contractOutcomes);
      // console.log([results, newServerSeedHash]);
      vm.tokenIdOutcomes = tokenOutcomes || null;
      vm.contractIdOutcomes = contractOutcomes || null;
      vm.outcomes = results
        .sort((a, b) => b.quantity - a.quantity)
        .sort((a, b) => b.contractId - a.contractId);
      vm.newServerSeedHash = newServerSeedHash;
      if (vm.outcomes !== null) {
        vm.$refs.chest.play();
        vm.$refs.chestSound.play();
        vm.videoPlaying = true;
        vm.rollInterval = 100;
        vm.rollingTimer = setTimeout(vm.rotateImage, 1000);
      }
    },
    async finishedVideo() {
      const vm = this;
      vm.videoFinished = true;
      vm.$refs.chestOpenedSound.play();
    },
    rotateImage() {
      const vm = this;
      if (vm.videoPlaying && vm.videoFinished) {
        // vm.currentRoll = vm.outcome.tokenId;
        return;
      }
      if (vm.rollInterval > 1200) {
        vm.finishedVideo();
        return;
      }
      vm.rollInterval *= 1.1;
      vm.currentRoll = vm.generateRandom();
      vm.rollingTimer = setTimeout(vm.rotateImage, vm.rollInterval);
    },
    close() {
      const vm = this;
      vm.videoPlaying = false;
      vm.videoFinished = false;
      vm.rollingTimer = null;
      vm.currentRoll = null;
      vm.outcomes = null;
      vm.$refs.chest.load();
      vm.$refs.chestSound.load();
      vm.$refs.chestOpenedSound.load();
    },
    generateRandom() {
      const vm = this;
      const randomExcept = (arr, excludeNum) => {
        var randNumber = Math.floor(Math.random() * arr.length);
        return arr[randNumber] == excludeNum
          ? randomExcept(arr, excludeNum)
          : randNumber;
      };
      const outcomeList = vm.tokenIdOutcomes || vm.contractIdOutcomes;
      return outcomeList[randomExcept(outcomeList, vm.currentRoll)];
    },
    itemImage(tokenId, contractId) {
      if (tokenId !== null) {
        return require(`@/assets/images/lootbox/items/${tokenId}.png`);
      } else {
        try {
          return require(`@/assets/images/lootbox/contracts/${contractId}.png`);
        } catch (err) {
          return require(`@/assets/images/lootbox/contracts/${contractId}.gif`);
        }
      }
    },
  },
  mounted() {},
  beforeDestroy() {
    clearTimeout(this.rollingTimer);
  },
  data() {
    return {
      tokenIdOutcomes: null,
      contractIdOutcomes: null,

      outcomes: null,
      newServerSeedHash: null,

      videoPlaying: false,
      videoFinished: false,

      rollingTimer: null,
      rollInterval: 25,
      currentRoll: null,
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1px, 0, 0);
  }
}

@keyframes shake2 {
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(2px, -2px, 0);
  }

  20%,
  80% {
    transform: translate3d(-2px, 2px, 0);
  }

  40%,
  60% {
    transform: translate3d(-1px, 1px, 0);
  }
}

@keyframes shake2-img {
  0%,
  100% {
    transform: translate(0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: scaleY(1) translate(-4px, 2px); // skewX(-2deg)
  }

  20%,
  80% {
    transform: translate(-2px, -2px);
  }

  40%,
  60% {
    transform: translate(-2px, -2px);
  }
}

@keyframes bloat {
  0% {
    transform: rotate(-3deg) scale(1);
  }
  50% {
    transform: rotate(-3deg) scale(1.05);
  }
  100% {
    transform: rotate(-3deg) scale(1);
  }
}

@keyframes move-in {
  to {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}

@keyframes glow-up {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.hero-lootbox {
  // background: $sand;
  position: relative;
  overflow: hidden;
  min-height: 746px;
  padding: 1rem 0 0 0;

  > .container {
    display: grid;

    .hero-header {
      margin-top: 5rem;
      text-align: center;
      margin-bottom: 6rem;
      .hero-text {
        position: relative;
        top: 0.5rem;
        margin: 0 auto;
        width: 100%;
        transition: opacity 1s ease-in-out;
        h2 {
          font-size: $font-lg;
          position: relative;
          z-index: 1;
          margin: 0;
          padding: 1rem;
          color: $white;
          // filter: drop-shadow(0 0px 20px rgba(black, 1));
          // color: $pink;
          &.page-title {
            color: $gold;
            font-size: $font-xlg;
            padding: 0;
            animation: bloat 2s ease-in-out infinite;
            // color: $gold;
          }
        }
      }
      .chest-container {
        width: 100%;
        left: 0;
        right: 0;
        margin-left: 0;
        height: 100%;
        // bottom: 17rem;
        bottom: 15rem;
        position: absolute;
        .video-container {
          pointer-events: none;
          animation: shake2 5s ease-in-out infinite;
          &.lootbox-3 {
            padding-top: 1.5rem;
          }
          video {
            // width: 100%;
            width: 1000px;
            margin-left: 50%;
            transform: translateX(-50%);
          }
        }
        .rolling {
          transform: rotate(360deg) scale(0);
          opacity: 0;
          animation: move-in 1s ease-in-out forwards;
          animation-delay: 1.2s;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: -20em;
          width: 256px;
          height: 256px;
          .animated-roll {
            height: 256px;
            transform: perspective(1000px) rotateX(350deg) rotateY(320deg)
              translate(-20px, -50px);
            // transform: perspective(1000px) rotateX(352deg) rotateY(324deg)
            //   translate(-20px, -20px);
            &.ogLootbox {
              transform: unset;
            }
            > img {
              position: absolute;
              bottom: 0;
              left: 0;
              opacity: 0;
              transition: opacity 50ms;
              max-height: 256px;
              filter: drop-shadow(0 0px 100px rgba($gold, 1));
              animation: shake2-img 5s ease-in-out infinite;
              // border: 5px solid rgba($black, 1);
              border-radius: $radius-md;
              &.ogLootbox {
                transform: unset;
                // border: none;
                animation: none;
                border-radius: none;
              }
              &.active {
                opacity: 1;
              }
              &.final {
                animation: glow-up 5s ease-in-out infinite;
              }
            }
          }
          .finished-rolling {
            position: absolute;
            max-width: 700px;
            width: 100vw;
            min-height: 650px;
            // height: 650px;
            // left: -128px;
            top: -200px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            opacity: 0;

            margin-left: 50%;
            transform: translateX(-50%);
            &.show {
              opacity: 1;
              pointer-events: all;
            }

            h2 {
              margin-top: 4rem;
            }

            .rolled-items {
              display: grid;
              align-self: center;
              grid-template-columns: repeat(1, 1fr);
              max-height: 320px;
              gap: 0.5rem 2rem;
              overflow-y: auto;
              padding: 3px;
              .lootbox-item-card {
              }
            }

            .rolled-footer {
              padding: 1rem;
              margin-top: 2rem;
              p {
                white-space: nowrap;
                position: absolute;
                text-align: center;
                width: 100%;
                font-size: 11px;
                padding-top: 1rem;
                color: $black;
                font-weight: 600;
              }
            }
          }
        }
      }
      .lootbox-cta {
        transition: opacity 1s ease-in-out;
        position: relative;
        z-index: 1;
        display: grid;
        gap: 0.75rem 1.5rem;
        max-width: 375px;
        margin: 0 auto;
        padding-top: 3rem;
        button {
          margin-top: 0;
          grid-column: 1 / span 2;
          background: $gold;
          margin-bottom: 4rem;
          &.btn-connect {
            margin-top: 0;
            background: $blue;
          }
        }

        .buy-now-group {
          width: 100%;
          display: grid;
          grid-template-columns: 10% 80% 10%;
          margin-top: 2rem;

          .minus,
          .plus {
            font-size: $font-xxs;
            padding: 0;
            padding: 0rem;
            width: unset;
            height: 35px;
            margin: 0;
            color: $black;
          }

          // .minus {
          //   background: $red;
          // }
          // .plus {
          //   background: $green;
          // }

          .buy-now {
            background: $green;
            margin: 0 0.8rem;
            font-size: $font-xs;
            // padding: 0rem;
            width: unset;
          }

          &.unpurchasable {
            opacity: 0.5;
            button {
              font-size: $font-xs;
              &.disabled {
                opacity: 1;
              }
            }
          }

          &-100 {
            grid-template-columns: 100%;
            > .buy-now {
              margin: 0;
            }
          }
        }
      }

      .videoPlaying {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}

.hero-lootbox.ogLootbox {
  > .container {
    .chest-container {
      margin-left: 1.5rem;
      bottom: 17rem;
      .video-container {
        animation: shake 5s ease-in-out infinite;
      }
      .rolling {
        bottom: -22em;
        .finished-rolling {
          margin-left: 40%;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .hero-lootbox {
    > .container {
      .hero-header {
        margin-bottom: 17em;
        .hero-text {
          h2 {
            padding: 1rem 0 0 0;
            &.page-title {
              font-size: $font-xxlg;
            }
          }
        }
        .chest-container {
          bottom: 25rem;
          .video-container {
            video {
              width: 1200px;
            }
          }
          .rolling {
            bottom: -16em;
            .animated-roll {
              transform: perspective(1000px) rotateX(350deg) rotateY(320deg)
                translate(-30px, -80px);
            }
            .finished-rolling {
              .rolled-items {
                grid-template-columns: repeat(1, 1fr);
                &.col-1 {
                  grid-template-columns: repeat(1, 1fr);
                }
                &.col-2,
                &.col-3 {
                  grid-template-columns: repeat(2, 1fr);
                }
              }
            }
          }
        }
        .num-lootboxes {
          bottom: -6rem;
        }
        .lootbox-cta {
          padding-top: 4rem;
          button {
            &.btn-connect {
            }
          }
        }
      }
    }
  }

  .hero-lootbox.ogLootbox {
    > .container {
      .chest-container {
        bottom: 27rem;
        .rolling {
          bottom: -18rem;
        }
      }
    }
  }
}
@media screen and (min-width: $screen-md) {
  .hero-lootbox {
    min-height: 846px;
    > .container {
      .hero-header {
        margin-bottom: 18.5rem;
        .hero-text {
          h2 {
            &.page-title {
              // margin-top: 7.5rem;
            }
          }
        }
        .chest-container {
          bottom: 20rem;
          .rolling {
            bottom: -11rem;
            .animated-roll {
              transform: perspective(1000px) rotateX(350deg) rotateY(325deg)
                translate(-20px, -20px);
            }
            .finished-rolling {
              .rolled-items {
                max-height: 440px;
                grid-template-columns: repeat(4, 1fr);
                &.col-3 {
                  grid-template-columns: repeat(3, 1fr);
                }
              }
            }
          }
          video {
          }
        }
        .lootbox-cta {
          padding-top: 3.5rem;
          button {
            &.btn-connect {
            }
          }
        }
      }
    }
  }

  .hero-lootbox.ogLootbox {
    > .container {
      .chest-container {
        bottom: 21rem;
        .rolling {
          bottom: -13rem;
        }
      }
    }
  }
}
@media screen and (min-width: $screen-lg) {
  .hero-lootbox {
    > .container {
      .hero-header {
        .hero-text {
          h2 {
            &.page-title {
            }
          }
        }
        .chest-container {
          img {
          }
        }
        .lootbox-cta {
          button {
            &.btn-connect {
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
