<template>
  <footer>
    <div class="socials">
      <a :href="socials.opensea" target="_blank" class="social opensea">
        <OpenseaSvg :style="{ width: '24px', height: '24px' }" />
      </a>
      <a :href="socials.etherscan" target="_blank" class="social opensea">
        <EtherscanSvg :style="{ width: '24px', height: '24px' }" />
      </a>
      <a :href="socials.discord" target="_blank" class="social discord">
        <DiscordSvg :style="{ width: '24px', height: '24px' }" />
      </a>
      <a :href="socials.twitter" target="_blank" class="social twitter">
        <TwitterSvg :style="{ width: '24px', height: '24px' }" />
      </a>
      <a :href="socials.instagram" target="_blank" class="social twitter">
        <InstagramSvg :style="{ width: '24px', height: '24px' }" />
      </a>
    </div>
    <div v-html="$t('generic.footer')"></div>
    <div class="languages">
      <ul>
        <li
          v-for="language in languages"
          :key="language"
          @click="changeLanguage(language)"
        >
          {{ $t(`languages.${language}`) }}
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import store from "storejs";

import TwitterSvg from "../assets/images/socials/Twitter.svg";
import InstagramSvg from "../assets/images/socials/Instagram.svg";
import DiscordSvg from "../assets/images/socials/Discord.svg";
import OpenseaSvg from "../assets/images/socials/Opensea.svg";
import EtherscanSvg from "../assets/images/socials/Etherscan.svg";

import Socials from "../data/Socials.json";
import Languages from "../data/Languages.json";

export default {
  name: "Footer",
  props: {},
  components: {
    TwitterSvg,
    InstagramSvg,
    DiscordSvg,
    OpenseaSvg,
    EtherscanSvg,
  },
  data() {
    return {
      socials: Socials,
      languages: Languages,
    };
  },
  methods: {
    changeLanguage(language) {
      this.$i18n.locale = language;
      store.set("site_locale", language);
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  padding: 2rem;
  color: $white;
  font-size: $font-xxxs;
  text-align: center;
  background: $black; // $blue-darker;
  font-weight: 600;
  font-family: $font-secondary;
  text-transform: uppercase;

  &.blue {
    background: $blue-darker;
  }

  .socials {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
    svg {
      filter: brightness(0) invert(1);
    }
  }
  .languages {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    margin-top: 1rem;
    ul {
      list-style: none;
      display: flex;
      gap: 1.5rem;
      padding: 0;
      margin: 0;
      li {
        cursor: pointer;
        transition: opacity 0.2s;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

// .hero-wrapper + footer {
//   background: $blue-darker;
// }
</style>
