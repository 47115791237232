<template>
  <div class="navbar">
    <div class="mobile-nav">
      <div class="brand">
        <router-link to="/">
          <LogoSvg />
        </router-link>
      </div>
      <button href="#" class="burger-menu" @click="mobileMenu = true">
        <span></span>
      </button>
    </div>

    <div
      v-bind:class="{ links: true, mobile: mobileMenu }"
      @click.self="mobileMenu = false"
    >
      <div class="links-container">
        <div class="brand">
          <router-link to="/">
            <LogoSvg />
          </router-link>
        </div>
        <div class="left">
          <router-link to="/">{{ $t("nav.home") }}</router-link>
          <a href="https://squishi.shop" target="_blank">{{ $t("nav.merch") }}</a>
          <!-- <router-link to="/staking">{{ $t("nav.staking") }}</router-link> -->
          <!-- <router-link to="/marketplace">{{ $t("nav.marketplace") }}</router-link> -->
          <!-- <router-link to="/inventory">{{ $t("nav.inventory") }}</router-link> -->
          <!-- <router-link to="/lootbox">{{ $t("nav.lootbox") }}</router-link> -->
          <router-link to="/media">{{ $t("nav.media") }}</router-link>
        </div>
        <div class="right">
          <a :href="socials.opensea" target="_blank" class="social opensea">
            <OpenseaSvg :style="{ width: '24px', height: '24px' }" />
            <span>OpenSea</span>
          </a>
          <a :href="socials.etherscan" target="_blank" class="social opensea">
            <EtherscanSvg :style="{ width: '24px', height: '24px' }" />
            <span>Etherscan</span>
          </a>
          <a :href="socials.discord" target="_blank" class="social discord">
            <DiscordSvg :style="{ width: '24px', height: '24px' }" />
            <span>Discord</span>
          </a>
          <a :href="socials.twitter" target="_blank" class="social twitter">
            <TwitterSvg :style="{ width: '24px', height: '24px' }" />
            <span>Twitter</span>
          </a>
          <a :href="socials.instagram" target="_blank" class="social twitter">
            <InstagramSvg :style="{ width: '22px', height: '22px' }" />
            <span>Instagram</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import LogoSvg from "../../assets/images/logo.svg";
import TwitterSvg from "../../assets/images/socials/Twitter.svg";
import InstagramSvg from "../../assets/images/socials/Instagram.svg";
import DiscordSvg from "../../assets/images/socials/Discord.svg";
import OpenseaSvg from "../../assets/images/socials/Opensea.svg";
import EtherscanSvg from "../../assets/images/socials/Etherscan.svg";
import Socials from "../../data/Socials.json";

export default {
  name: "Hero",
  methods: {},
  components: {
    LogoSvg,
    TwitterSvg,
    InstagramSvg,
    DiscordSvg,
    OpenseaSvg,
    EtherscanSvg,
  },
  data() {
    return {
      mobileMenu: false,
      socials: Socials,
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99999;
  .brand {
    svg {
      width: 64px;
    }
  }

  .left {
    a {
      position: relative;
    }
  }

  .mobile-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    .burger-menu {
      padding: 1rem;
      background: $white;
      border-radius: 10px;
      @include threedee-box-extra(3px, 3, black);
      border: none;
      > span {
        width: 24px;
        height: 10px;
        position: relative;
        display: inline-block;
        border-top: 3px solid $black;
      }
      span:after,
      span:before {
        content: "";
        width: 100%;
        height: 3px;
        left: 0;
        background-color: $black;
        position: absolute;
      }
      span:after {
        bottom: -2px;
      }
      span:before {
        top: 3px;
      }
    }
    .brand {
    }
  }

  .links {
    display: none;
    &.mobile {
      display: block;
      position: fixed;
      // background: rgba($black, 0.8);
      backdrop-filter: blur(10px);
      height: 100%;
      width: 100%;
      z-index: 99999;
      top: 0;
      .links-container {
        display: grid;
        background: rgba($white, 0.9);
        @include threedee-box(3px, black);
        // margin: 5rem 3rem;
        border-radius: 10px;
        // padding: 2rem;
        margin: 2rem;
        overflow-y: auto;
        max-height: 80vh;
        > * {
          display: grid;
          a {
            color: $black;
            text-decoration: none;
            border-bottom: solid rgba($black, 1) 3px;
            padding: 1rem 1rem;
          }
        }
        > .brand {
          justify-self: center;
          padding: 1rem 0;
          background: transparent;
          width: 100%;
          text-align: center;
          justify-content: center;
          svg {
            width: 64px;
          }
          > a {
            border-bottom: none;
          }
        }
        > .right {
          svg {
            display: none;
          }
          a:last-child {
            border-bottom: 0;
          }
        }
        > .left {
          a:first-child {
            border-top: solid rgba($black, 1) 3px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
  .navbar {
    padding: 0 2rem;
    // top: 1rem;
    // justify-content: flex-start;
    .mobile-nav {
      display: none;
    }

    .left {
      a {
        position: relative;
        &:last-child {
          margin: 0;
        }
      }
    }
    .links {
      margin-top: 0;
      display: flex;
      width: 100%;
      .brand {
      }
      .links-container {
        display: grid;
        grid-template-columns: 7% 73% 20%;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 1rem 0;
        .left {
          justify-self: center;
        }
        .right {
          justify-self: flex-end;
          display: flex;
          align-items: center;
          > a {
            position: relative;
            display: flex;
            align-items: center;
            svg + span {
              display: none;
            }
            svg + span + span {
              margin-left: 10px;
            }
            svg {
              position: relative;
              width: 32px;
              margin-left: 1.5rem;
              transition: transform 0.1s;
              &:hover {
                transform: scale(1.1);
              }
            }
            &:first-child {
              img {
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    a {
      font-family: $font-primary;
      color: $white;
      font-size: $font-xs;
      @include threedee-text-extra(1.5px, 2px, $black);
      font-weight: 600;
      text-transform: uppercase;
      text-decoration: none;
      // margin-top: -0.3rem;
      margin-right: 1.5rem;
      display: inline-block;
      transition: all 0.2s;
      &:hover {
        transform: rotate(-3deg) scale(1.1);
      }
    }

    .mobile a {
      margin-right: 0;
      transform: unset;
    }
    .right a {
      margin-right: 0;
      transform: scale(1.1);
    }
  }

  .hero .social,
  .hero-lootbox .social,
  .hero-staking .social {
    filter: brightness(0) invert(1);
  }
}
@media screen and (min-width: $screen-lg) {
  .navbar {
    .mobile-nav {
    }
    .left {
      a {
        &:last-child {
        }
      }
    }
    .links {
      .brand {
      }
      .links-container {
        grid-template-columns: 20% 60% 20%;
        .left {
        }
        .right {
          > a {
            svg + span {
            }
            svg + span + span {
            }
            svg {
              &:hover {
              }
            }
            &:first-child {
              img {
              }
            }
          }
        }
      }
    }

    a {
      &:hover {
      }
    }

    .mobile a {
    }
    .right a {
    }
  }

  .hero .social,
  .hero-lootbox .social,
  .hero-staking .social {
  }
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>