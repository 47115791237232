var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero-lootbox",class:{ ogLootbox: _vm.$store.state.lootbox === 0 }},[_c('Navbar'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"hero-header"},[_c('div',{staticClass:"chest-container"},[_c('div',{class:`video-container lootbox-${_vm.$store.state.lootbox}`},[_c('video',{key:_vm.$store.state.lootbox,ref:"chest",attrs:{"muted":"","playsinline":""},domProps:{"muted":true},on:{"ended":_vm.finishedVideo}},[_c('source',{attrs:{"src":_vm.lootboxVideo,"type":_vm.videoType}})]),_c('audio',{ref:"chestSound",attrs:{"playsinline":""}},[_c('source',{attrs:{"src":require("@/assets/images/lootbox/sfx-opening.ogg"),"type":"audio/ogg"}})]),_c('audio',{ref:"chestOpenedSound",attrs:{"playsinline":""}},[_c('source',{attrs:{"src":require("@/assets/images/lootbox/sfx-opened.ogg"),"type":"audio/ogg"}})])]),(_vm.videoPlaying)?_c('div',{staticClass:"rolling"},[(!_vm.videoFinished)?_c('div',{staticClass:"animated-roll",class:{ ogLootbox: _vm.$store.state.lootbox === 0 }},_vm._l((_vm.tokenIdOutcomes || _vm.contractIdOutcomes),function(item){return _c('img',{key:item,class:{
                active: _vm.currentRoll === item,
                final: _vm.videoFinished,
              },attrs:{"src":_vm.itemImage(
                  _vm.tokenIdOutcomes ? item : null,
                  _vm.contractIdOutcomes ? item : null
                ),"alt":item}})}),0):_vm._e(),_c('div',{staticClass:"finished-rolling",class:{ show: _vm.videoFinished }},[_c('h2',[_vm._v(_vm._s(_vm.$t("lootbox.you_have_won")))]),_c('div',{staticClass:"rolled-items",class:{
                'col-3': _vm.outcomes.length === 3,
                'col-2': _vm.outcomes.length === 2,
                'col-1': _vm.outcomes.length <= 1,
              }},_vm._l((_vm.outcomes),function(outcome,idx){return _c('Card',{key:`${outcome.name}__${idx}`,staticClass:"lootbox-item-card",attrs:{"title":outcome.name,"type":"image"}},[_c('img',{attrs:{"src":_vm.itemImage(outcome.tokenId, outcome.contractId),"draggable":"false"}})])}),1),_c('div',{staticClass:"rolled-footer"},[_c('Button',{staticClass:"close",on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("generic.close"))+" ")]),_c('p',{staticClass:"fairness"},[_vm._v(" "+_vm._s(_vm.$t("lootbox.fairness_roll_hash") + ": " + _vm.newServerSeedHash)+" ")])],1)])]):_vm._e()]),_c('div',{staticClass:"hero-text",class:{ videoPlaying: _vm.videoPlaying }},[_c('h2',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.$t("lootbox.header")))])]),_c('div',{staticClass:"lootbox-cta",class:{ videoPlaying: _vm.videoPlaying }},[(_vm.$store.state.token === null)?_c('Button',{staticClass:"btn-connect",on:{"click":_vm.connectWallet}},[_vm._v(" "+_vm._s(_vm.$t("generic.connect_wallet"))+" ")]):_c('LootboxRoll',{key:_vm.$store.state.lootbox,on:{"open":_vm.open}})],1),_c('LootboxSelection',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.token !== null),expression:"$store.state.token !== null"}],class:{ videoPlaying: _vm.videoPlaying }})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }