<template>
  <div>
    <div v-if="$store.state.lootbox === 0" class="lootbox-bg"></div>
    <div v-else class="lootbox-bg2"></div>
    <LootboxHero />
    <LootboxContent v-if="$store.state.token !== null" />
  </div>
</template>

<script>
import { pages } from "../../vue.config";
import LootboxHero from "../components/Hero/LootboxHero.vue";
import LootboxContent from "../components/Lootbox/LootboxContent.vue";

export default {
  name: "LootboxView",
  metaInfo: {
    title: pages.lootbox.title,
    meta: [{ name: "description", content: pages.lootbox.metaDescription }],
  },
  components: {
    LootboxHero,
    LootboxContent,
  },
  methods: {},
  mounted() {
    const vm = this;
    if (vm.$store.state.account !== null) {
      vm.$store.dispatch("fetchTotalLootboxes");
    }
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@keyframes expand {
  50% {
    transform: scaleX(1.005);
  }
  100% {
    transform: scaleX(1);
  }
}
.lootbox {
  &-bg {
    width: 100%;
    height: 856px;
    position: absolute;
    background: url("~@/assets/images/lootbox/bg.jpg") no-repeat;
    background-size: 250% 100%;
    background-position: center -100px;
  }
  &-bg2 {
    width: 100%;
    height: 856px;
    position: absolute;
    background: url("~@/assets/images/lootbox/bg2.jpg") no-repeat;
    background-size: 300% 100%;
    background-position: center -100px;
  }
}

@media screen and (min-width: $screen-sm) {
  .lootbox {
    &-bg2 {
      background-size: 250% 100%;
    }
  }
}
@media screen and (min-width: $screen-md) {
  .lootbox {
    &-bg {
      background-size: 100% 100%;
      background-position: center;
    }
    &-bg2 {
      background-size: 150% 120%;
      background-position: center -160px;
    }
  }
}
@media screen and (min-width: $screen-lg) {
  .lootbox {
    &-bg2 {
      background-size: 100% 120%;
    }
  }
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
