module.exports = {
    pages: {
        index: {
            entry: 'src/main.js',
            title: 'Squishiverse',
            metaDescription: 'Welcome to the Squishiverse, where cute, digital collectibles come to life!'
        },
        staking: {
            entry: 'src/main.js',
            title: 'Squishiverse - Staking',
            metaDescription: 'Stake your Squishiverse NFT and earn $SLIME which enables you to access exclusive perks, benefits and more!'
        },
        marketplace: {
            entry: 'src/main.js',
            title: 'Squishiverse - Marketplace',
            metaDescription: 'Access our all-in-one $SLIME marketplace where you can purchase NFTs, whitelists, merchandise and more!'
        },
        media: {
            entry: 'src/main.js',
            title: 'Squishiverse - Media',
            metaDescription: 'Discover Squishiverse Media which houses all of our GIFs, animations, wallpapers, manga and more!'
        },
        lootbox: {
            entry: 'src/main.js',
            title: 'Squishiverse - Lootboxes',
            metaDescription: 'Try your luck to win prominent NFTs by spending $SLIME on our provably-fair Squishiverse NFT Lootboxes!'
        },
        inventory: {
            entry: 'src/main.js',
            title: 'Squishiverse - Inventory',
            metaDescription: 'Bridge between your on-chain and off-chain $SLIME, NFTs and Squishiverse Metaverse items.'
        },
        settings: {
            entry: 'src/main.js',
            title: 'Squishiverse - Settings',
            metaDescription: 'Configure your Squishiverse profile.'
        },
        purchases: {
            entry: 'src/main.js',
            title: 'Squishiverse - Purchases',
            metaDescription: 'View your past Squishiverse marketplace history.'
        },
    },
    lintOnSave: true,
    css: {
        loaderOptions: {
            sass: {
                prependData: `@import "@/assets/scss/vars.scss";`,
            },
        },
    },
    chainWebpack: config => {
        config.module
            .rule('mov')
            .test(/\.mov$/)
            .use('file-loader')
            .loader('file-loader')
            .end()

        const svgRule = config.module.rule('svg');
        svgRule.uses.clear();
        svgRule
            .use('babel-loader')
            .loader('babel-loader')
            .end()
            .use('vue-svg-loader')
            .options({
                svgo: {
                    plugins: [{ inlineStyles: { onlyMatchedOnce: false } }]
                }
            })
            .loader('vue-svg-loader')
    }
};
