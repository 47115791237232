<template>
  <div class="team-member">
    <img class="image" :src="memberImage(member)" />
    <h2 class="name">{{ member.name }}</h2>
    <p class="role">{{ $t(member.role) }}</p>
    <div class="socials" v-if="member.twitter !== '#'">
      <a :href="member.twitter" target="_blank" class="twitter">
        <TwitterSvg />
      </a>
    </div>
  </div>
</template>

<script>
import TwitterSvg from "../../assets/images/socials/Twitter.svg";

export default {
  name: "TeamMember",
  props: {
    member: Object,
  },
  components: {
    TwitterSvg,
  },
  methods: {
    memberImage(member) {
      return require(`@/assets/images/team/${member.image}`);
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.team-member {
  // margin-bottom: 6rem;
  text-align: center;
  .image {
    @include threedee-box-extra(2px, 5, $blue-darker);
    border-radius: $radius-md;
    width: 100%;
  }
  .name {
    margin: 1rem 0;
    font-size: $font-sm;
    text-transform: uppercase;
    @include threedee-text-extra(2px, 3px);
  }
  .role {
    margin: 1rem;
    font-size: $font-xxxs;
    text-transform: uppercase;
    color: $blue-darker;
    font-weight: 400;
    font-family: $font-secondary;
    // white-space: nowrap;
  }
  .twitter {
    filter: brightness(0) invert(1);
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .socials {
    text-align: center;
    // @include threedee-box(2px);
    a {
      img {
        transition: 0.2s all;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}
</style>
