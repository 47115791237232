<template>
  <div class="locale-changer">
    <div class="select-lang">
      <div class="options">
        <div class="option" v-for="(lang, idx) in langs" :key="idx">
          <input
            type="radio"
            name="flag"
            :id="lang"
            v-on:change="changeLanguage"
            :checked="$i18n.locale === lang"
          />
          <label :for="lang">
            <Component
              :is="require(`@/assets/images/country/${lang}.svg`)"
              preserveAspectRatio="none"
            />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "storejs";
import Languages from "../../../data/Languages.json";
export default {
  name: "LocaleChanger",
  data() {
    return { langs: Languages };
  },
  mounted() {
    this.fetchDefaultLocale();
  },
  methods: {
    changeLanguage(e) {
      this.$i18n.locale = e.target.id;
      store.set("site_locale", this.$i18n.locale);
    },
    async fetchDefaultLocale() {
      // check local storage for a cached language
      const cachedLocale = store.get("site_locale") || null;
      if (cachedLocale !== null) {
        this.$i18n.locale = cachedLocale;
        return;
      }

      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/country`
      );

      // Map country code (ISO 3166-1 alpha-2) to language
      const countryLocaleMapping = {
        // Chinese
        TW: "CN",
        CN: "CN",
        MO: "CN",
        HK: "CN",

        // Spanish
        RA: "ES",
        RB: "ES",
        CL: "ES",
        CO: "ES",
        CR: "ES",
        CU: "ES",
        DO: "ES",
        EC: "ES",
        SV: "ES",
        GQ: "ES",
        GT: "ES",
        HN: "ES",
        MX: "ES",
        NI: "ES",
        PA: "ES",
        ES: "ES",
        PE: "ES",
        UY: "ES",
        VE: "ES",
        PR: "ES",

        // Japanese
        JP: "JP",

        // Vietnamese
        VN: "VN",

        // Thailand
        TH: "TH",

        // Russian
        RU: "RU",
        BY: "RU",
        KG: "RU",
        KZ: "RU",
      };

      const code = countryLocaleMapping[data.message.country] || null;
      if (code !== null) {
        this.$i18n.locale = code.toLowerCase();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.locale-changer {
  display: none;
}

@media screen and (min-width: $screen-sm) {
  .locale-changer {
    display: block;
    position: fixed;
    bottom: 1.8rem;
    left: 2rem;
    z-index: 9999;
    .select-lang {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      .options {
        .option {
          margin-bottom: 1rem;
          svg {
            width: 72px;
            height: 48px;
            filter: drop-shadow(0 0px 20px rgba($black, 0.5));
            @include threedee-box-extra(3px, 2, $black);
            border-radius: $radius-md;
          }
          label {
            display: inline-block;
            cursor: pointer;
            display: none;
          }
          input {
            width: 0;
            height: 0;
            overflow: hidden;
            margin: 0;
            padding: 0;
            float: left;
            display: inline-block;
            position: absolute;
            visibility: hidden;
            &:checked + label {
              display: block;
              width: 100%;
            }
          }
        }
      }

      &:hover {
        .options .option input + label {
          display: block;
        }
      }
    }
  }
}
@media screen and (min-width: $screen-md) {
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
