<template>
  <div class="hero" @mousemove="mouseMove">
    <SplashLoader
      :hidden="!isLoading"
      :progress="totalLoaded / imagesToPreload.length"
    />
    <Navbar />
    <div
      class="hero-sky"
      :style="{
        backgroundPosition:
          deviceWidth < 1024
            ? '-350px bottom'
            : `${2 * hillPositionX}px ${hillPositionY}px`,
      }"
    ></div>
    <div class="hero-hill">
      <div
        class="hero-hill-tree"
        :style="{
          backgroundPosition:
            deviceWidth < 1024 ? '-350px bottom' : `${hillPositionX}px bottom`,
        }"
      ></div>
      <div
        class="logo-wrapper"
        :style="{
          transform: `translate(${hillPositionX * 0.5}px, ${
            hillPositionY + 200 + -scrollPercent * 10000
          }px) `,
        }"
      >
        <div
          class="intro-logo"
          v-bind:class="{ 'intro-logo-animate': !isLoading }"
        >
          <img
            src="@/assets/images/herov2/logo.png"
            class="logo"
            draggable="false"
            :style="{
              transform: `rotate(${-scrollPercent * 20000}deg) scale(${
                1 - scrollPercent * 10
              })`,
            }"
          />
        </div>
      </div>
      <div
        class="hero-hill-alone"
        :style="{
          backgroundPosition:
            deviceWidth < 1024 ? '-350px 0' : `${hillPositionX}px bottom`,
        }"
      ></div>
    </div>
    <img
      src="@/assets/images/herov2/pink.png"
      class="squishie-pink"
      draggable="false"
      :style="{
        transform: `translate(${hillPositionX * 0.5}px, ${
          hillPositionY * 5 + 500
        }px)`,
      }"
    />
    <img
      src="@/assets/images/herov2/pink-shadow.png"
      class="squishie-pink-shadow"
      draggable="false"
      :style="{
        transform: `translate(${hillPositionX * 3 + 25}px, ${
          hillPositionY + 135
        }px) scale(${0.9 - hillPositionX / 90})`,
      }"
    />
    <img
      src="@/assets/images/herov2/pink-pumpkin.png"
      class="squishie-pink-pumpkin"
      draggable="false"
      :style="{ transform: `translateX(${hillPositionX}px)` }"
    />
    <img
      src="@/assets/images/herov2/dragon.png"
      class="squishie-dragon"
      draggable="false"
      :style="{
        transform: `translate(${hillPositionX * 10 + 100}px, ${
          -hillPositionY - 200
        }px)`,
      }"
    />
    <img
      src="@/assets/images/herov2/blue.png"
      class="squishie-blue"
      draggable="false"
      :style="{
        transform: `translateY(${-hillPositionX * 5 - 100}px)`,
      }"
    />
    <img
      src="@/assets/images/herov2/blue-shadow.png"
      class="squishie-blue-shadow"
      draggable="false"
      :style="{
        transform: `translate(${hillPositionX * 2 + 50}px, ${
          hillPositionY + 25
        }px) scale(${1.25 + hillPositionX / 50})`,
      }"
    />
    <div class="hero-grass-blue"></div>
    <div
      class="hero-grass"
      :style="{ opacity: 1 - 1 * (scrollPercent / 0.01) }"
    ></div>
    <RecordPlayer
      :style="{
        opacity: 1 - 1 * (scrollPercent / 0.01),
        pointerEvents: scrollPercent / 0.01 > 1 ? 'none' : 'all',
      }"
    />
    <LocaleChanger
      :style="{
        opacity: 1 - 1 * (scrollPercent / 0.01),
        pointerEvents: scrollPercent / 0.01 > 1 ? 'none' : 'all',
      }"
    />
  </div>
</template>

<script>
import SplashLoader from "../SplashLoader";
import Navbar from "./Navbar";
import Socials from "../../data/Socials.json";
import { getScrollPercentage, getDeviceWidth } from "../../helpers/device";
import RecordPlayer from "./RecordPlayer/RecordPlayer";
import LocaleChanger from "./LocaleChanger/LocaleChanger.vue";

export default {
  name: "Hero",
  props: {},
  components: {
    Navbar,
    RecordPlayer,
    SplashLoader,
    LocaleChanger,
  },
  created() {
    const images = this.imagesToPreload.map((imageSrc) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imageSrc;
        img.onload = () => {
          this.totalLoaded++;
          resolve();
        };
        img.onerror = reject;
      });
    });
    // force a minimum of 1 second
    images.push(new Promise((resolve) => setTimeout(resolve, 1000)));
    Promise.all(images)
      .then(() => {
        console.log("Images loaded!");
        this.isLoading = false;
      })
      .catch((error) => {
        console.error("Some image(s) failed loading!");
        console.error(error);
      });
  },
  computed: {
    deviceWidth() {
      return getDeviceWidth();
    },
  },
  methods: {
    mouseMove(e) {
      if (typeof e !== "undefined" && this.deviceWidth >= 1024) {
        this.hillPositionX = -e.clientX / 100;
        this.hillPositionY = e.clientY / 100 - 100;
      }
    },
    onScroll() {
      const percent = getScrollPercentage();
      if (percent < 0.17) {
        this.scrollPercent = percent;
      }
    },
  },
  mounted() {
    const vm = this;
    window.addEventListener("scroll", vm.onScroll);
  },
  data() {
    return {
      socials: Socials,

      // move the land
      hillPositionX: 0,
      hillPositionY: -100,

      scrollPercent: 0,

      isLoading: true,
      totalLoaded: 0,
      imagesToPreload: [
        require("@/assets/images/herov2/hill-alone.png"),
        require("@/assets/images/herov2/hill-tree.png"),
        require("@/assets/images/herov2/grass.png"),
        require("@/assets/images/herov2/grass-blue.png"),
        require("@/assets/images/herov2/sky.png"),
        require("@/assets/images/herov2/logo.png"),
        require("@/assets/images/herov2/pink.png"),
        require("@/assets/images/herov2/pink-shadow.png"),
        require("@/assets/images/herov2/pink-pumpkin.png"),
        require("@/assets/images/herov2/dragon.png"),
        require("@/assets/images/herov2/blue.png"),
        require("@/assets/images/herov2/blue-shadow.png"),
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes intro-logo {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.hero {
  min-height: 700px;
  height: 100vh;
  width: 100%;
  // position: absolute;
  // z-index: 999;
  position: relative;
  overflow: hidden;
  img {
    user-select: none;
  }
  &-sky {
    width: 100%;
    height: 100%;
    position: absolute;
    background: url("~@/assets/images/herov2/sky.png") no-repeat;
    background-size: 300% 100%;
    // background-position: -300px center;
    user-select: none;
    z-index: 0;
  }
  &-hill {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
    &-alone,
    &-tree {
      width: inherit;
      height: 100%;
      position: absolute;
    }
    &-alone {
      background: url("~@/assets/images/herov2/hill-alone.png") no-repeat;
      background-size: 325% 100%;
      // background-position: -300px center;
      z-index: 2;
    }
    &-tree {
      background: url("~@/assets/images/herov2/hill-tree.png") no-repeat;
      background-size: 325% 100%;
      // background-position: -300px center;
      z-index: 0;
    }
    .logo-wrapper {
      position: absolute;
      top: 25px;
      right: 0;
      left: 0;
      width: 300px;
      height: 500px;
      z-index: 1;
      margin: auto;
      transform-origin: 150px 125px;
      .intro-logo {
        opacity: 0;
        z-index: 5;
        position: absolute;
        &-animate {
          animation: intro-logo 3s ease forwards;
          transform-origin: center 1000px;
        }
        .logo {
          width: 100%;
        }
      }
    }
  }
  &-grass,
  &-grass-blue {
    width: 100%;
    height: 250px;
    position: absolute;
    background: linear-gradient(0deg, #001127 0%, rgba($navy, 0) 10%),
      url("~@/assets/images/herov2/grass.png") no-repeat;
    background-size: 325% 100%;
    background-position: center bottom;
    bottom: 0;
    z-index: 10;
  }
  &-grass-blue {
    background: url("~@/assets/images/herov2/grass-blue.png") no-repeat;
    background-size: 325% 100%;
    background-position: center bottom;
  }
  .squishie-dragon {
    position: absolute;
    top: 400px;
    left: -200px;
    width: 130px;
    z-index: 0;
  }
  .squishie-blue {
    position: absolute;
    bottom: 0px;
    right: -175px;
    width: 200px;
    z-index: 6;
    &-shadow {
      position: absolute;
      bottom: 25px;
      right: -150px;
      width: 175px;
      z-index: 5;
    }
  }
  .squishie-pink {
    position: absolute;
    width: 175px;
    z-index: 5;
    bottom: 140px;
    right: 5vw;
    &-shadow {
      position: absolute;
      bottom: 175px;
      right: 6vw;
      width: 125px;
      z-index: 4;
    }
  }
  .squishie-pink-pumpkin {
    position: absolute;
    bottom: 90px;
    right: -8vw;
    width: 200px;
    z-index: 6;
  }
}

@media screen and (min-width: $screen-xs) {
  .hero {
    min-height: 700px;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    img {
      user-select: none;
    }
    &-sky {
      background: url("~@/assets/images/herov2/sky.png") no-repeat;
      background-size: 250% 100%;
    }
    &-hill {
      &-alone,
      &-tree {
      }
      &-alone {
        background: url("~@/assets/images/herov2/hill-alone.png") no-repeat;
        background-size: 225% 100%;
      }
      &-tree {
        background: url("~@/assets/images/herov2/hill-tree.png") no-repeat;
        background-size: 225% 100%;
      }
      .logo-wrapper {
        width: 350px;
        .intro-logo {
          &-animate {
          }
          .logo {
          }
        }
      }
    }
    &-grass,
    &-grass-blue {
    }
    &-grass-blue {
    }
    .squishie-dragon {
      left: -175px;
      width: 180px;
    }
    .squishie-blue {
      bottom: 25px;
      right: -125px;
      width: 200px;
      &-shadow {
        bottom: 25px;
        right: -125px;
        width: 175px;
      }
    }
    .squishie-pink {
      width: 200px;
      bottom: 160px;
      right: 12vw;
      &-shadow {
        bottom: 190px;
        right: 15vw;
        width: 150px;
      }
    }
    .squishie-pink-pumpkin {
      bottom: 100px;
      right: -4vw;
      width: 250px;
    }
  }
}
@media screen and (min-width: $screen-sm) {
  .hero {
    min-height: 700px;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    img {
      user-select: none;
    }
    &-sky {
      background: url("~@/assets/images/herov2/sky.png") no-repeat;
      background-size: 200% 100%;
    }
    &-hill {
      &-alone,
      &-tree {
      }
      &-alone {
        background: url("~@/assets/images/herov2/hill-alone.png") no-repeat;
        background-size: 200% 100%;
      }
      &-tree {
        background: url("~@/assets/images/herov2/hill-tree.png") no-repeat;
        background-size: 200% 100%;
      }
      .logo-wrapper {
        width: 450px;
        .intro-logo {
          &-animate {
          }
          .logo {
          }
        }
      }
    }
    &-grass,
    &-grass-blue {
      height: 250px;
      background: linear-gradient(0deg, #001127 0%, rgba($navy, 0) 10%),
        url("~@/assets/images/herov2/grass.png") no-repeat;
      background-size: 200% 100%;
      background-position: center bottom;
    }
    &-grass-blue {
      background: url("~@/assets/images/herov2/grass-blue.png") no-repeat;
      background-size: 200% 100%;
      background-position: center bottom;
    }
    .squishie-dragon {
      left: -100px;
      width: 180px;
    }
    .squishie-blue {
      position: absolute;
      right: -50px;
      width: 225px;
      &-shadow {
        position: absolute;
        right: -25px;
        width: -25px;
      }
    }
    .squishie-pink {
      width: 225px;
      bottom: 180px;
      right: 18vw;
      &-shadow {
        bottom: 200px;
        right: 18vw;
        width: 175px;
      }
    }
    .squishie-pink-pumpkin {
      bottom: 110px;
      right: 9vw;
      width: 275px;
      z-index: 6;
    }
  }
}
@media screen and (min-width: $screen-md) {
  .hero {
    &-sky {
      background: url("~@/assets/images/herov2/sky.png") no-repeat;
      background-size: 150% 100%;
    }
    &-hill {
      &-alone,
      &-tree {
      }
      &-alone {
        background: url("~@/assets/images/herov2/hill-alone.png") no-repeat;
        background-size: 125% 100%;
      }
      &-tree {
        background: url("~@/assets/images/herov2/hill-tree.png") no-repeat;
        background-size: 125% 100%;
      }
      .logo-wrapper {
        width: 475px;
        .intro-logo {
          &-animate {
          }
          .logo {
          }
        }
      }
    }
    &-grass,
    &-grass-blue {
      height: 250px;
      background: linear-gradient(0deg, #001127 0%, rgba($navy, 0) 10%),
        url("~@/assets/images/herov2/grass.png") no-repeat;
      background-size: 150% 100%;
      background-position: center bottom;
    }
    &-grass-blue {
      background: url("~@/assets/images/herov2/grass-blue.png") no-repeat;
      background-size: 150% 100%;
      background-position: center bottom;
    }
    .squishie-dragon {
      left: 0;
      width: 225px;
    }
    .squishie-blue {
      position: absolute;
      right: -50px;
      bottom: 150px;
      width: 300px;
      &-shadow {
        position: absolute;
        right: -100px;
        width: 250px;
        bottom: 75px;
      }
    }
    .squishie-pink {
      width: 225px;
      bottom: 200px;
      right: 20vw;
      &-shadow {
        bottom: 220px;
        right: 16vw;
        width: 225px;
      }
    }
    .squishie-pink-pumpkin {
      bottom: 110px;
      right: 14vw;
      width: 275px;
      z-index: 6;
    }
  }
}
@media screen and (min-width: $screen-lg) {
  .hero {
    min-height: 944px;
    height: 100vh;
    img {
      user-select: none;
    }
    &-sky {
      position: absolute;
      background: url("~@/assets/images/herov2/sky.png") no-repeat;
      background-size: 110% 110%;
      background-position: center center;
    }
    &-hill {
      &-alone,
      &-tree {
      }
      &-alone {
        background: url("~@/assets/images/herov2/hill-alone.png") no-repeat;
        background-size: 101% 110%;
      }
      &-tree {
        background: url("~@/assets/images/herov2/hill-tree.png") no-repeat;
        background-size: 101% 110%;
      }
      .logo-wrapper {
        width: 500px;
        transform-origin: 250px 200px;
        .logo {
        }
      }
    }
    &-grass,
    &-grass-blue {
      height: 290px;
      background-size: 100%;
      background-position: center bottom;
      z-index: 10;
    }
    &-grass-blue {
      background: url("~@/assets/images/herov2/grass-blue.png") no-repeat;
      background-size: 100%;
      background-position: center bottom;
    }
    .squishie-dragon {
      position: absolute;
      top: 400px;
      left: 150px;
      width: 300px;
      z-index: 0;
    }
    .squishie-blue {
      position: absolute;
      bottom: 200px;
      right: 150px;
      width: 400px;
      z-index: 6;
      &-shadow {
        position: absolute;
        bottom: 100px;
        right: 50px;
        width: 350px;
        z-index: 5;
      }
    }
    .squishie-pink {
      position: absolute;
      width: 250px;
      z-index: 5;
      bottom: 275px;
      right: 32vw;
      &-shadow {
        position: absolute;
        bottom: 275px;
        right: 27vw;
        width: 250px;
        z-index: 4;
      }
    }
    .squishie-pink-pumpkin {
      position: absolute;
      bottom: 150px;
      right: 24vw;
      width: 400px;
      z-index: 6;
    }
  }
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
