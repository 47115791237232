<template>
  <div class="wrapper" v-bind:class="{ playing: isPlaying }">
    <audio ref="themeSound" playsinline loop>
      <source src="@/assets/sounds/main_theme.wav" type="audio/wav" />
    </audio>
    <div class="player">
      <div class="top">
        <div class="record-wrapper">
          <div class="record" :class="{ spin: isPlaying }">
            <div class="image"></div>
          </div>
        </div>
      </div>
      <div class="controls" @click="togglePlay">
        <div class="play">
          <svg
            v-if="!isPlaying"
            width="42"
            height="22"
            viewBox="0 0 42 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.05427 42.3186C7.29861 43.4702 4.96631 42.2107 4.96631 40.1111V4.8889C4.96631 2.78927 7.29861 1.52983 9.05427 2.68141L35.9034 20.2925C37.4928 21.335 37.4928 23.665 35.9034 24.7075L9.05427 42.3186Z"
              fill="#fff"
            />
          </svg>
          <span v-else></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecordPlayer",
  props: {
    preset: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isPlaying: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    togglePlay() {
      if (!this.isPlaying) {
        this.$refs.themeSound.play();
      } else {
        this.$refs.themeSound.pause();
      }
      this.isPlaying = !this.isPlaying;
    },
  },
};
</script>

<style lang="scss" scoped>
$offset: 0;
$recordHeight: 100px;
$innerRecordHeight: 50px;
$backgroundColor: #a7c1dd;
$trackColor1: #4b75a0;
$trackColor2: darken($trackColor1, 10%);

@function record-rings($n) {
  $value: "0 0 0 1px #111";
  @for $i from 2 through $n {
    @if $i % 5 == 0 {
      $value: "#{$value}, 0px 0px 0px #{$i * 1}px #222";
    } @else {
      $value: "#{$value}, 0px 0px 0px #{$i * 1}px #111";
    }
  }
  @return unquote($value);
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes pulsate {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.wrapper {
  display: none;
}

@media screen and (min-width: $screen-sm) {
  .wrapper {
    display: block;
    position: fixed;
    bottom: 50px;
    right: 25px;
    z-index: 9999;
    &.playing {
      animation: pulsate 2s ease-in-out infinite;
    }

    .player {
      align-self: center;
      // background: url(https://pbs.twimg.com/profile_images/1585599669832294400/PFV5H3nx_400x400.jpg);
      // background-size: cover;
      // @include threedee-box-extra(4px, 3, $black);
      border-radius: 10px;
      display: grid;
      height: 100px;
      // margin: 50px auto 0;
      position: relative;
      width: 150px;
      .top {
        position: relative;
        .record-wrapper {
          height: $recordHeight - $offset;
          overflow: hidden;
          transform: translateY(-$offset);
          cursor: pointer;
          .record {
            background-image: linear-gradient(
              130deg,
              #070809 24%,
              #070809 35%,
              #676767 49%,
              #070809 64%,
              #070809 76%
            );
            border: 7px solid #000;
            border-radius: 100%;
            box-sizing: border-box;
            height: $recordHeight;
            left: 50%;
            margin-left: ((100px / 2) * -1);
            position: absolute;
            top: 0;
            width: $recordHeight;
            animation: spin 5s linear infinite;
            animation-play-state: paused;
            &.spin {
              animation-play-state: running;
            }
            &:before {
              background-color: #000;
              border-radius: 100%;
              content: "";
              height: 10px;
              left: 50%;
              margin-left: -(10px/2);
              margin-top: -(10px/2);
              opacity: 1;
              position: absolute;
              top: 50%;
              width: 10px;
              z-index: 1;
            }
            &:after {
              content: "";
              border-radius: 2000%;
              box-shadow: record-rings(20);
              height: $innerRecordHeight;
              left: 50%;
              margin-left: -($innerRecordHeight/2);
              margin-top: -($innerRecordHeight/2);
              opacity: 0.6;
              position: absolute;
              top: 50%;
              width: $innerRecordHeight;
            }
            .image {
              border: 3px solid #000;
              box-sizing: border-box;
              background: url("~@/assets/images/herov2/record.jpg");
              background-position: center;
              // transform: rotate(-45deg);
              background-size: 100%;
              border-radius: 100%;
              content: "";
              height: $innerRecordHeight;
              width: $innerRecordHeight;
              left: 50%;
              margin-left: -($innerRecordHeight/2);
              margin-top: -($innerRecordHeight/2);
              opacity: 1;
              position: absolute;
              top: 50%;
              width: $innerRecordHeight;
              opacity: 0.7;
            }
          }
        }
      }
      .controls {
        position: absolute;
        top: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: $recordHeight;
        border-radius: 1000%;
        justify-self: center;
        background: rgba($black, 0.8);
        opacity: 0;
        transition: opacity 0.2s;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
        div {
          &.play {
            margin-top: 0;
            width: 41px;
            height: 20px;
            span {
              position: relative;
              top: 3px;
              left: 12px;
              &:after,
              &:before {
                content: "";
                top: 0;
                position: absolute;
                background-color: $white;
                height: 15px;
                width: 6px;
                border-radius: 4px;
              }
              &:before {
                left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: $screen-md) {
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
