<template>
  <a
    v-if="href !== null"
    :href="href"
    v-bind:class="{ disabled, [size]: size !== '' }"
    target="_blank"
  >
    <slot />
  </a>
  <router-link
    v-else-if="to !== null"
    :to="to"
    v-bind:class="{ disabled, [size]: size !== '' }"
  >
    <slot />
  </router-link>
  <button
    v-else
    @click.stop="!disabled && $emit('click')"
    v-bind:class="{ disabled, [size]: size !== '' }"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "SimpleButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
    href: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
button,
a {
  border: 0;
  background: $white;
  font-family: $font-secondary;
  color: $black;
  padding: 0.5rem 1rem;
  border-radius: $radius-sm;
  font-size: $font-xxxs;
  font-weight: 600;
  @include threedee-box-extra(2px, 3, $black);
  cursor: pointer;
  transition: all 0.2s;
  text-transform: uppercase;
  text-decoration: none;

  &.full {
    width: 100%;
  }

  &:hover {
    transform: scale(1.05);
  }
}
</style>
