export default {
    // Languages:
    languages: {
        en: "English",
        cn: "中文",
        jp: "日本",
        vn: "Tiếng Việt",
        th: "ไทย",
        es: "Español",
        ru: "Русский",
    },

    // Generic
    generic: {
        connect_wallet: "Conectar cartera",
        wallet_not_connected: "Cartera no conectada",
        squishie: "Squishie | Squishies",
        land: "Tierra | Tierras",
        lootbox: "Cofre | Cofres",
        your: "Tus",
        balance: "Balance",
        farmed: "Cultivado",
        collection: "Colección Squishi<span class='fix'></span>verse",
        footer: "Squishiverse &copy; 2022. Todos los derechos reservados.",
        nothing_to_show: "Nada que mostrar",
        buy_now: "Compra Ahora",
        buy_now_qty: "Compra {quantity} Ahora",
        unavailable: "No disponible",
        close: "Cerrar",
        settings: "Ajustes",
        beta: "Beta",
        items: "Objetos",
        i_understand: "Entiendo",
        cancel: "Cancelar",
        max: "Max",
        claim: "Reclamar",
        loading: "Cargando",
        fetch: "Recuperar",
        episode: "Episodio",
        connect: "Conectar",
        reconnect: "Reconectar",
        open: "Abrir",
    },

    // Navigation
    nav: {
        home: "Inicio",
        staking: "Asegurar",
        marketplace: "Mercado",
        inventory: "Inventario",
        lootbox: "Cofre",
        media: "Media",
    },

    // Transaction
    modal: {
        title: {
            market_purchase: "Compra de Mercado",
            claim_rewards: "Reclamar premios",
            authentication: "Autenticación",
            slime_bridge: "Puente de $SLIME",
            item_bridge: "Puente de Objetos",
            lootbox_bridge: "Puente de Cofres",
            squishie_adventure: "Aventura Squishie",
        },
        body: {
            transaction_failed: '¡Transacción fallida!',
            transaction_complete: '¡Transacción completa!',
            transaction_pending: 'Transacción pendiente...',
            transaction_confirming: 'Confirmando transacción... Por favor espere.',
            transaction_wrong: 'Algo salió mal. Inténtalo de nuevo.',
            view_etherscan: "Ver en Etherscan",
            item_bridge_warning: "Antes de cambiar y retirar tus objetos, recuerda que no puedes cancelar el proceso cuando cambias en-cadena (on-chain).",
            transaction_interrupt_retry: "Intenta nuevamente si la ultima transacción se interrumpe, se re-procesara. ",
            lootbox_bridge_warning: "Antes de cambiar y retirar tus nfts, recuerda que no puedes cancelar el proceso cuando cambias en-cadena (on-chain).",
            fifo_issuance: "Nuestro contrato inteligente custodia los nfts 6 los envía por orden de llegada.",
            lootbox_nft_unavailable: "Su transacción fallará si no tenemos el NFT disponible, tendrá que esperar hasta que repongamos el contrato.",
            slime_swap_warning: "Antes de cambiar y retirar tu $xSLIME por $SLIME, recuerda que no puedes cancelar el proceso cuando cambias en-cadena (on-chain).",
            slime_swap: "Su saldo de $xSLIME se reducirá y se generará una firma de reclamo que debe usarse para canjear su {cantidad} $SLIME.",
            nothing_to_show_product: "No hay nada que mostrar para este producto."
        },
    },

    // Homepage
    home: {
        collection: {
            p1: "Bienvenido al Squishiverse, donde los lindos coleccionables digitales cobran vida. Inspirada en personajes icónicos de slime de los medios asiáticos, nuestra marca nativa de Web3 ofrece una amplia gama de experiencias conmovedoras, desde coleccionables e historias hasta mercancia y más. Como líder en el metaverso, nos dedicamos a ampliar los límites de Web3 y a ofrecer experiencias innovadoras.",
            p2: "Como poseedor de Squishie, serás parte de una comunidad muy unida que valora el empoderamiento y la fuerza. Tendrás acceso a canales exclusivos con token, eventos, oportunidades de IP individuales, whitelists, NFTs y otros beneficios dentro del ecosistema de Squishiverse. Únete a nosotros y descubre todo lo que Squishiverse tiene para ofrecer.",
        },
        features: {
            play_now: "Juega Ahora",
            see_more: "Ver Más",
            learn_more: "Leer Más",
            official_website: "Página Oficial",
            instagram: "Instagram",
            youtube: "YouTube",
            squishiland: {
                title: "Squishiland",
                subtitle: "Terrenos Genesis",
                p1: "SquishiLand es una colección finita de terrenos personalizables que forman la base del metaverso interoperable de Squishiverse.",
                p2: "Tener un territorio te da control total sobre su diseño y configuración, lo que se refleja en todos los mercados secundarios. Puedes usar tu tierra para crear una tienda, una casa, un hotel, un museo o cualquier otra estructura que puedas imaginar. Las oportunidades para la creatividad y el desarrollo son infinitas con SquishiLand."
            },
            metaverse: {
                title: "Metaverso",
                subtitle: "MMORPG METAVERSO",
                p1: "Muchos juegos y metaversos de Web3 luchan por mantener una base de jugadores porque carecen de un juego atractivo. Squishiverse será diferente. Estamos sentando las bases para una experiencia divertida, gratificante, social e interoperable para todos los jugadores.",
                p2: "Nuestra plataforma Web3 combinará los mejores elementos de un MMORPG exitoso con la tecnología avanzada de Web3, ofreciendo una experiencia verdaderamente única y placentera, con un enfoque en la propiedad descentralizada de activos.",
            },
            ip: {
                title: "Utilización de IP",
                subtitle: "Marca Squishiverse",
                p1: "En Squishiverse, nuestro objetivo es empoderar a nuestra comunidad y fortalecer el uso de la propiedad intelectual (IP) a través de los productos que creamos. Los poseedores de Squishies aparecen en nuestra serie animada original, así como en GIF y memes de alta calidad que ayudan a construir la IP individual de cada Squishie.",
                p2: "Mercancía, juguetes, peluches, animaciones, memes, etc., todo esto contribuye a la reputación de Squishiverse como una fuente inagotable de utilización de IP."
            },
            descend: {
                title: "Descend",
                subtitle: "Juego de Calabozo",
                p1: "Experimenta grandes apuestas y el potencial de grandes recompensas con este juego de exploración de calabozo en 2D, que presenta jugadas completamente únicas cada vez.",
                p2: "Como juego GameFi de temporada para todos, Descend recompensa a sus jugadores más habilidosos y atrevidos con un premio mínimo de 10 000 USDC, NFTs y SBTs."
            }
        },
        team: {
            the_team: "El Equipo",
            community_management: "Administración de Comunidad",
            roles: {
                founder: "Fundador",
                artist: "Artista",
                cofounder: "Co-Fundador",
                developer_lead: "Líder de Desarrollo",
                web3_developer: "Desarrollador de Web3",
                metaverse_lead: "Líder desarrollador de juegos",
                metaverse_developer: "desarrollador de juegos",
                creative_help: "Asistente Creativo",
            },
        },
    },

    // Staking Page
    staking: {
        join_squishies: "Únete a {num} Squishies En La",
        adventure: "Aventura",
        claim_rewards: "Reclamar Premios",
        farming_yield_rate: "Cultivar {totalYield} $xSLIME / DÍA",
        switch_to_land: "Cambiar a Tierra",
        switch_to_squishies: "Cambiar a Squishies",
        loading_your: "Cargando tu {what}, ¡porfavor espera!",
        dont_own_any: "No tienes ningún {what}",
    },

    // Marketplace
    marketplace: {
        header: "Mercado",
        categories: "Categorias",
        loading_marketplace: "Cargando mercado...",
        filters_all: "Filtros",
        view_order_history: "Ver Historial de Pedidos",
        filters: {
            all: "Todos",
            active: "Activos",
            newest: "Nuevos",
            oldest: "Viejos",
            lowest_price: "Precio Mínimo",
            highest_price: "Precio Máximo",
        },
    },

    // Inventory
    inventory: {
        header: "Inventario",
        swap_slime: "Cambiar Slime",
        you_will_receive: "Recibirás",
        swap_for: "Cambiar por",
        swap_to: "Cambiar a",
        same_loot_withdraw_tip: "CONSEJO: Puedes retirar múltiples del mismo tipo de cofre",
        disclaimer: {
            slime: {
                p1: "Si la transacción falla, vuelva a intentar cualquier monto y el último reclamo se intentará procesar nuevamente.",
                p2: "Se aplica una tarifa de {calculatedFee} al cambiar de $xSLIME a $SLIME",
                p3: "Los cambios a $xSLIME pueden tardar hasta 10 minutos en llegar una vez enviados",
            },
            item: {
                p1: "Los saldos de objetos L1 pueden tardar hasta 10 minutos en reflejarse con precisión",
                p2: "Vuelva a hacer clic en el botón de intercambio para volver a intentar los intercambios fallidos o cancelados",
            },
            loot: {
                p1: "Un contrato inteligente custodia los NFTs y se distribuyen por orden de llegada.",
                p2: "Vuelva a hacer clic en el botón de intercambio para volver a intentar los intercambios fallidos o cancelados",
            }
        },
        swap_your_items: "Intercambia tus elementos de {layer}",
        no_inventory: "No hay inventario para mostrar",
    },

    // Lootboxes
    lootbox: {
        header: "Cofres",
        you_have_won: "Ganaste!",
        fairness_roll_hash: "Transparencia De Tirada",
        lootbox_content_header: "Contenido de Cofres",
        lootbox_content_disclaimer: "DESCARGO DE RESPONSABILIDAD: los NFTs que se muestran pueden no ser representativos del que recibe una vez que retira a su billetera. Nuestro contrato inteligente se financia periódicamente con NFTs, y los retiros de NFTs se reclaman por orden de llegada."
    },

    // Media
    media: {
        header: "Media",
        gifs: "GIFs",
        animation: "Animación",
        wallpapers: "Fondos de pantalla",
        manga: "Manga",
        extra: "Extra",
        wrong_input: "Hubo un problema, intente de nuevo.",
        enter_squishie: "Usa el numero de tu Squishie (usa commas para cargar varios)",
        see_more_giphy: "Ver más en Giphy!",
    },

    // Settings
    settings: {
        header: "Configuración de la cuenta",
        discord_account: "Cuenta de Discord",
        add_discord_account: "Agrega tu cuenta de Discord",
    },
}