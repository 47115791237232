export default {
    // Languages:
    languages: {
        en: "English",
        cn: "中文",
        jp: "日本",
        vn: "Tiếng Việt",
        th: "ไทย",
        es: "Español",
        ru: "Русский",
    },

    // Generic
    generic: {
        connect_wallet: "Connect Wallet",
        wallet_not_connected: "Wallet not connected",
        squishie: "Squishie | Squishies",
        land: "Land | Lands",
        lootbox: "Lootbox | Lootboxes",
        your: "Your",
        balance: "Balance",
        farmed: "Farmed",
        collection: "Squishi<span class='fix'></span>verse Collection",
        footer: "Squishiverse &copy; 2022. All rights reserved.",
        nothing_to_show: "Nothing to show",
        buy_now: "Buy Now",
        buy_now_qty: "Buy {quantity} Now",
        unavailable: "Unavailable",
        close: "Close",
        settings: "Settings",
        beta: "Beta",
        items: "Items",
        i_understand: "I Understand",
        cancel: "Cancel",
        max: "Max",
        claim: "Claim",
        loading: "Loading",
        fetch: "Fetch",
        episode: "Episode",
        connect: "Connect",
        reconnect: "Reconnect",
        open: "Open",
    },

    // Navigation
    nav: {
        home: "Home",
        staking: "Collection",
        marketplace: "Marketplace",
        inventory: "Inventory",
        lootbox: "Lootbox",
        media: "Media",
        merch: "Merchandise",
    },

    // Transaction
    modal: {
        title: {
            market_purchase: "Marketplace Purchase",
            claim_rewards: "Claim Rewards",
            authentication: "Authentication",
            slime_bridge: "$SLIME Bridge",
            item_bridge: "Item Bridge",
            lootbox_bridge: "Lootbox Bridge",
            squishie_adventure: "Squishie Adventure",
        },
        body: {
            transaction_failed: 'Transaction failed!',
            transaction_complete: 'Transaction complete!',
            transaction_pending: 'Transaction pending...',
            transaction_confirming: 'Confirming transaction... Please wait.',
            transaction_wrong: 'Something went wrong. Please try again.',
            view_etherscan: "View on Etherscan",
            item_bridge_warning: "Before you swap out, this is a friendly reminder when you swap items to on-chain, you cannot cancel this process!",
            transaction_interrupt_retry: "If this transaction gets interrupted, retry again and the last unsuccessful claim will be reprocessed.",
            lootbox_bridge_warning: "Before you swap out, this is a friendly reminder when you swap nfts to on-chain, you cannot cancel this process!",
            fifo_issuance: "Our smart contract custodies NFTs and sends them on a first-come first-serve basis.",
            lootbox_nft_unavailable: "Your transaction will fail if we do not have the NFT available, you just will have to wait until we replenish the contract.",
            slime_swap_warning: "Before you swap out, this is a friendly reminder when you swap $xSLIME for $SLIME, you cannot cancel this process!",
            slime_swap: "Your $xSLIME balance will be reduced, and a claim signature will be generated that must be used to redeem your {amount} $SLIME.",
            nothing_to_show_product: "There is nothing to show for this product"
        },
    },

    // Homepage
    home: {
        collection: {
            p1: "Welcome to the Squishiverse, where cute, digital collectibles come to life. Inspired by iconic slime characters from Asian media, our Web3-native brand offers a wide range of heartwarming experiences, from collectibles and stories to merchandise and more. As a leader in the metaverse, we are dedicated to pushing the boundaries of Web3 and delivering innovative experiences.",
            p2: "As a Squishie holder, you'll be part of a tight-knit community that values empowerment and strength. You'll have access to exclusive token-gated channels, events, individual IP opportunities, whitelists, NFTs, and other benefits throughout the Squishiverse ecosystem. Join us and discover all that the Squishiverse has to offer.",
        },
        features: {
            play_now: "Play Now",
            see_more: "See More",
            learn_more: "Learn More",
            official_website: "Official Website",
            instagram: "Instagram",
            youtube: "YouTube",
            squishiland: {
                title: "Squishiland",
                subtitle: "Genesis Land Plots",
                p1: "SquishiLand is a finite collection of customizable land plots that form the foundation of the Squishiverse's interoperable metaverse.",
                p2: "Holding a plot gives you complete control over its design and configuration, which is reflected in all secondary markets. You can use your plot to create a shop, house, hotel, museum, or any other structure you can imagine. The opportunities for creativity and development are endless with SquishiLand."
            },
            metaverse: {
                title: "Metaverse",
                subtitle: "MMORPG METAVERSE",
                p1: "We heavily believe in interoperability and the ability to use your NFTs across different Web3 platforms. We strive for integration with other projects, and so far you can enjoy being a Squishie in platforms such as Arcade, Pixels Online, Worldwide Webb, and more!",
                p2: "",
            },
            ip: {
                title: "IP Utilization",
                subtitle: "Squishiverse Branding",
                p1: "At the Squishiverse, our goal is to empower our community and strengthen the use of our intellectual property (IP) through the products we create. Holder Squishies are featured in our original animated series, as well as in high-quality GIFs and memes that help build the individual IP of each Squishie.",
                p2: "Merchandise, toys, plushies, animations, memes, etc. all contribute to the Squishiverse's reputation as a powerhouse of IP utilization. So far, we have amassed over 600million views across all social media platforms."
            },
            descend: {
                title: "Descend",
                subtitle: "Dungeon Crawler Game",
                p1: "Descend V1 was created to branch out and explore the Web3 gaming audience, as well as rewarding active participants in our ecosystem with a 10K USDC prize pool. It received overwhelmingly positive feedback from gamers, and we look forward to the future of Descend.",
                p2: "Note: Descend has officially separated from the Squishiverse, and is led by QQ. "
            }
        },
        team: {
            the_team: "The Team",
            community_management: "Community Management",
            roles: {
                founder: "Founder",
                artist: "Original Artist",
                instagram_artist: "Instagram Artist",
                cofounder: "Co-Founder",
                developer_lead: "Developer Lead",
                web3_developer: "Web3 Developer",
                metaverse_lead: "Lead Game Developer",
                metaverse_developer: "Game Developer",
                creative_help: "Digital Designer",
                artist_3d: "3D Artist",
                music_production: "Music Production",
                community_manager: "Community Manager",
            },
        },
    },

    // Staking Page
    staking: {
        join_squishies: "Join {num} Squishies On A",
        adventure: "Adventure",
        claim_rewards: "Claim Rewards",
        farming_yield_rate: "Farming {totalYield} $xSLIME / DAY",
        switch_to_land: "Switch To Land",
        switch_to_squishies: "Switch To Squishies",
        loading_your: "Loading your {what}, please wait!",
        dont_own_any: "You do not own any {what}",
    },

    // Marketplace
    marketplace: {
        header: "Marketplace",
        categories: "Categories",
        loading_marketplace: "Loading marketplace...",
        filters_all: "Filters",
        view_order_history: "View Order History",
        filters: {
            all: "All",
            active: "Active",
            newest: "Newest",
            oldest: "Oldest",
            lowest_price: "Lowest Price",
            highest_price: "Highest Price",
        },
    },

    // Inventory
    inventory: {
        header: "Inventory",
        swap_slime: "Swap Slime",
        you_will_receive: "You will receive",
        swap_for: "Swap for",
        swap_to: "Swap to",
        same_loot_withdraw_tip: "TIP: You can withdraw multiple of the same type of loot",
        disclaimer: {
            slime: {
                p1: "If the transaction fails, please re-attempt any amount and it will attempt processing the last claim again",
                p2: "Fee of {calculatedFee} applies when swapping from $xSLIME to $SLIME",
                p3: "Swaps into $xSLIME may take up to 10 minutes to arrive once sent",
            },
            item: {
                p1: "L1 item balances may take up to 10 minutes to reflect accurately",
                p2: "Click the swap button again to reattempt failed or cancelled swaps",
            },
            loot: {
                p1: "A smart contract custodies the NFTs and are distributed out on a first-come first-serve basis",
                p2: "Click the swap button again to reattempt failed or cancelled swaps",
            }
        },
        swap_your_items: "Swap your {layer} items",
        no_inventory: "No inventory to show",
    },

    // Lootboxes
    lootbox: {
        header: "Lootboxes",
        you_have_won: "You have won!",
        fairness_roll_hash: "Fairness Roll Hash",
        lootbox_content_header: "Lootbox Contents",
        lootbox_content_disclaimer: "DISCLAIMER: NFTs shown may not be representative of the one you receive once you withdraw to your wallet. Our smart contract is funded periodically with NFTs, and NFT withdrawals are claimed out on a first-come, first-serve basis."
    },

    // Media
    media: {
        header: "Media",
        gifs: "GIFs",
        animation: "Animation",
        wallpapers: "Wallpapers",
        manga: "Manga",
        extra: "Extra",
        wrong_input: "There was something wrong with your input, please try again.",
        enter_squishie: "Enter your Squishie Number (use comma to load multiple)",
        see_more_giphy: "See More On Giphy!",
    },

    // Settings
    settings: {
        header: "Account settings",
        discord_account: "Discord Account",
        add_discord_account: "Add your Discord account",
    },
}