<template>
  <div class="modal">
    <div class="modal-overlay" @click.self="$emit('closePrompt')"></div>
    <div class="modal-container">
      <div class="modal-header">
        <h1>{{ $t("modal.title.slime_bridge") }}</h1>
      </div>
      <div class="modal-body">
        <p class="red">
          {{ $t("modal.body.slime_swap_warning") }}
        </p>
        <p>
          {{ $t("modal.body.slime_swap", { amount }) }}
        </p>
        <p>
          {{ $t("modal.body.transaction_interrupt_retry") }}
        </p>
        <Button @click="$emit('understood')">
          {{ $t("generic.i_understand") }}
        </Button>
        <Button @click="$emit('closePrompt')" class="close">
          {{ $t("generic.cancel") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import store from "storejs";
import Button from "../ui/Button.vue";

export default {
  name: "BridgeOutModal",
  props: {
    amount: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Button,
  },
  computed: {},
  methods: {
    formSubmit(e) {
      e.preventDefault();
      const vm = this;
      store.set("orderDiscord", vm.discord);
      this.$emit("submit", { recipient: vm.recipient, discord: vm.discord });
    },
  },
  mounted() {},
  data() {
    return {
      discord: store.get("orderDiscord") || "",
      recipient: this.$store.state.account,
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7.5px);
  }
}

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999999;
  color: $black;
  font-weight: 600;

  h1 {
    font-family: $font-primary;
    margin-top: 0;
  }

  .red {
    color: $red;
  }

  &-overlay {
    display: block;
    position: absolute;
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
  }

  &-container {
    width: 100%;
    z-index: 9999;
    max-width: 650px;
    padding: 1rem;
  }

  &-body {
    top: 0;
    left: 0;
    background: $white;
    z-index: 9999;
    margin: auto 0;
    text-align: center;
    @include threedee-box(3px);
    position: relative;
    padding: 2rem 3rem;
    border-radius: $radius-md;

    pre {
      text-align: left;
      background: rgba($black, 0.05);
      // border-radius: 10px;
      padding: 1rem;
      max-height: 300px;
      overflow-y: auto;
    }

    p {
      line-height: 150%;
    }

    button {
      background: $green;
      &.close {
        margin-top: 1.5rem;
        background: $red;
      }
    }
  }

  &-header {
    text-align: center;
    z-index: 9998;
    position: relative;
    width: 100%;
    h1 {
      font-family: $font-primary;
      font-size: $font-lg;
      margin: 0;
      margin-bottom: 2rem;
      color: $green;
      @include threedee-text(4px);
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .modal {
    &-header {
      h1 {
        font-size: $font-xlg;
        margin-bottom: 0.2rem;
        @include threedee-text-extra(6px, 4px, $black);
      }
    }
  }
}
</style>
