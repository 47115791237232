<template>
  <div class="collection-slider">
    <div class="collection-slider-row" v-for="(row, i) in images" :key="i">
      <InfiniteSlideBar
        class="collection-slider-row-slider"
        :direction="i % 2 == 0 ? 'reverse' : 'normal'"
        duration="30s"
      >
        <div v-for="(asset, idx) in row" :key="idx">
          <img :src="asset.img" />
        </div>
      </InfiniteSlideBar>
    </div>
  </div>
</template>

<script>
import InfiniteSlideBar from "vue-infinite-slide-bar";
export default {
  name: "collection-slider",
  props: {},
  methods: {},
  components: {
    InfiniteSlideBar,
  },
  data() {
    return {
      images: [
        [
          { img: require("@/assets/images/collection/1.png") },
          { img: require("@/assets/images/collection/2.png") },
          { img: require("@/assets/images/collection/3.png") },
          { img: require("@/assets/images/collection/4.png") },
          { img: require("@/assets/images/collection/5.png") },
          { img: require("@/assets/images/collection/6.png") },
          { img: require("@/assets/images/collection/7.png") },
          { img: require("@/assets/images/collection/8.png") },
        ],
        [
          { img: require("@/assets/images/collection/9.png") },
          { img: require("@/assets/images/collection/10.png") },
          { img: require("@/assets/images/collection/11.png") },
          { img: require("@/assets/images/collection/12.png") },
          { img: require("@/assets/images/collection/13.png") },
          { img: require("@/assets/images/collection/14.png") },
          { img: require("@/assets/images/collection/15.png") },
          { img: require("@/assets/images/collection/16.png") },
        ],
        [
          { img: require("@/assets/images/collection/17.png") },
          { img: require("@/assets/images/collection/18.png") },
          { img: require("@/assets/images/collection/19.png") },
          { img: require("@/assets/images/collection/20.png") },
          { img: require("@/assets/images/collection/21.png") },
          { img: require("@/assets/images/collection/22.png") },
          { img: require("@/assets/images/collection/23.png") },
          { img: require("@/assets/images/collection/24.png") },
        ],
      ],
    };
  },
};
</script>

<style lang="scss">
.collection-slider {
  padding-bottom: 2rem;
  &-row {
    margin-bottom: 0;
    &-slider {
      display: inline-block;
      .vifnslb-bar {
        display: flex;
        > div {
          padding: 0.5rem;
          img {
            max-width: 7rem;
            border-radius: 20px;
            @include threedee-box(2px, $white);
          }
        }
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
  .collection-slider {
    padding-bottom: 2rem;
    &-row {
      margin-bottom: 0;
      &-slider {
        display: inline-block;
        .vifnslb-bar {
          display: flex;
          > div {
            padding: 0.75rem;
            img {
              max-width: 15rem;
              border-radius: 20px;
              @include threedee-box(2px, $white);
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
