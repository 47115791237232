<template>
  <div class="lootbox-content">
    <div class="container">
      <h2>{{ $t("lootbox.lootbox_content_header") }}</h2>
      <p class="disclaimer">
        {{ $t("lootbox.lootbox_content_disclaimer") }}
      </p>
      <div class="lootbox-content-grid">
        <LootboxContentItem
          v-for="product in products"
          :product="product"
          :key="product.name"
        />
      </div>
      <h3 v-if="!products.length">
        {{ $t("generic.nothing_to_show") }}
      </h3>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LootboxContentItem from "./LootboxContentItem";
export default {
  name: "LootboxContent",
  components: { LootboxContentItem },
  computed: {
    lootbox() {
      return this.$store.state.lootbox;
    },
  },
  methods: {
    async fetchLootboxInfo(id) {
      if (id === 0) {
        this.products = require(`@/assets/images/lootbox/lootboxes/og.json`);
        return;
      }
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/lootbox/${id}/info`
        );
        this.products = data.message.lootboxItems;
      } catch (err) {
        this.products = [];
      }
    },
  },
  watch: {
    lootbox(newLootbox, oldLootbox) {
      if (newLootbox !== oldLootbox) {
        this.fetchLootboxInfo(newLootbox);
      }
    },
  },
  mounted() {
    this.fetchLootboxInfo(this.$store.state.lootbox);
  },
  data() {
    return {
      products: [],
    };
  },
};
</script>

<style lang="scss">
.lootbox-content {
  padding: 3rem 1rem 5rem 1rem;
  position: relative;
  overflow: hidden;
  background: $clay;
  z-index: 99999;
  margin-top: -10px;
  border-top: 5px solid $black;
  h2 {
    text-align: center;
    margin-bottom: 4rem;
  }
  h3 {
    text-align: center;
  }
  .disclaimer {
    padding: 0rem 0 2rem 0;
    font-weight: 600;
    font-size: $font-xxxs;
    text-align: center;
    line-height: 150%;
    max-width: 800px;
    margin: 0 auto;
  }
  .lootbox-content-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }
}

@media screen and (min-width: $screen-sm) {
  .lootbox-content {
    .lootbox-content-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media screen and (min-width: $screen-md) {
  .lootbox-content {
    .lootbox-content-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
@media screen and (min-width: $screen-lg) {
  .lootbox-content {
    .lootbox-content-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>