export default {
    // Languages:
    languages: {
        en: "English",
        cn: "中文",
        jp: "日本",
        vn: "Tiếng Việt",
        th: "ไทย",
        es: "Español",
        ru: "Русский",
    },

    // Generic
    generic: {
        connect_wallet: "ウォレットを接続する",
        wallet_not_connected: "ウォレットが接続されていない",
        squishie: "Squishie | Squishies",
        land: "ランド | ランド",
        lootbox: "戦利品 | 戦利品",
        your: "お客様の",
        balance: "バランス",
        farmed: "ファームド",
        collection: "Squishi<span class='fix'></span>verse コレクション",
        footer: "Squishiverse &copy; 2022. 無断転載を禁じます。",
        nothing_to_show: "何も表示されません",
        buy_now: "今すぐ購入する",
        buy_now_qty: "今すぐ {quantity} 購入",
        unavailable: "購入不可",
        close: "閉じる",
        settings: "設定",
        beta: "ベータ",
        items: "アイテム",
        i_understand: "理解する",
        cancel: "キャンセル",
        max: "最大",
        claim: "請求",
        loading: "読み込み",
        fetch: "フェッチ",
        episode: "エピソード",
        connect: "接続する",
        reconnect: "再接続",
        open: "開く",
    },

    // Navigation
    nav: {
        home: "ホーム",
        staking: "ステーキング",
        marketplace: "マーケットプレイス",
        inventory: "インベントリ",
        lootbox: "戦利品",
        media: "メディア",
    },

    // Transaction
    modal: {
        title: {
            market_purchase: "マーケットプレイスでの購入",
            claim_rewards: "リワードを請求する",
            authentication: "認証",
            slime_bridge: "$SLIMEブリッジ",
            item_bridge: "アイテムブリッジ",
            lootbox_bridge: "戦利品ブリッジ",
            squishie_adventure: "Squishieアドベンチャー",
        },
        body: {
            transaction_failed: '処理に失敗しました！',
            transaction_complete: '取引完了！',
            transaction_pending: '取引保留中...',
            transaction_confirming: 'トランザクションを確認しています... お待ちください。',
            transaction_wrong: 'エラーが発生しました。 もう一度やり直してください。',
            view_etherscan: "イーサースキャンで見る",
            item_bridge_warning: "スワップアウトする前に、オンチェーンにアイテムをスワップする際の注意です！この処理をキャンセルすることはできません。",
            transaction_interrupt_retry: "もし処理が中断された場合、再試行すると最後に失敗したリクエストが再処理されます。",
            lootbox_bridge_warning: "スワップアウトする前に、これはnftをオンチェーンにスワップ時のリマインダーです、このプロセスをキャンセルすることはできません！",
            fifo_issuance: "当社のスマートコントラクトはNFTを保管し、先着順でお送りします。",
            lootbox_nft_unavailable: "利用可能なNFTがない場合、取引は失敗です、当社が契約を補充するまでお待ちください。",
            slime_swap_warning: "スワップアウトの前に、$xSLIMEを$SLIMEにスワップする際の注意点ですが、この処理をキャンセルすることはできません!",
            slime_swap: "お客様の$xSLIMEの残高は減少し、{amount} $SLIMEの交換に使用しなければならない請求署名が作成されます。",
            nothing_to_show_product: "この商品には、何も表示されません"
        },
    },

    // Homepage
    home: {
        collection: {
            p1: "Squishiverseへようこそ！Squishiverseは、かわいいデジタルグッズに命を吹き込む場所です。アジアを代表するメディアであるスライムキャラクターにインスパイアされた私たちのWeb3ネイティブブランドは、グッズやストーリー、グッズなど、心温まる体験を幅広く提供します。メタバースにおけるリーダーとして、Web3の境界を押し広げ、革新的な体験を提供することに専心しています。",
            p2: "Squishieホルダーは、エンパワーメントと強さに価値を置く、結束の固いコミュニティの一員となることができます。Squishverseのエコシステムを通じて、トークンによるゲートウェイ、イベント、個別IPの機会、ホワイトリスト、NFT、その他の特典にアクセスすることができます。私たちの仲間になって、Squishiverseの魅力を発見してください。",
        },
        features: {
            play_now: "今すぐプレイ",
            see_more: "もっと見る",
            learn_more: "もっと学ぶ",
            official_website: "公式ウェブサイト",
            instagram: "インスタグラム",
            youtube: "YouTube",
            squishiland: {
                title: "Squishiland",
                subtitle: "ジェネシスランドプロット",
                p1: "Squishilandは、Squishiverseの相互運用可能なメタバースの基盤となる、カスタマイズ可能な土地区画の有限集合体です。",
                p2: "区画を所有することで、その設計や構成を完全にコントロールすることができ、それはすべての二次市場に反映されます。ショップ、ハウス、ホテル、ミュージアムなど、思いのままの建築物を作ることができます。SquishiLandの創造性と発展の可能性は無限大です。"
            },
            metaverse: {
                title: "メタバース",
                subtitle: "MMORPGメタバース",
                p1: "多くのWeb3ゲームやメタバーズでは、魅力的なゲームプレイがないため、プレイヤーベースを維持するのに苦労しています。しかし、Squishiverseは違います。私たちは、すべてのプレイヤーにとって楽しく、やりがいのある、ソーシャルで相互運用可能な体験のための基礎を築いています。",
                p2: "当社のWeb3プラットフォームは、成功したMMORPGの最良の要素とWeb3の先進技術を組み合わせ、分散型資産所有に焦点を当てた、本当にユニークで楽しい体験を提供するものです。",
            },
            ip: {
                title: "IPの活用",
                subtitle: "Squishiverseバースのブランディング",
                p1: "Squishiverseでは、私たちが作成する製品を通じて、コミュニティを強化し、知的財産（IP）の利用を強化することを目標としています。Holder Squishiesは、私たちのオリジナル・アニメーション・シリーズに登場するほか、高品質のGIFやミームなど、各スクイシーの個別IPを構築するのに役立っています。",
                p2: "グッズ、玩具、ぬいぐるみ、アニメ、ミームなど、これらはすべて、IP活用のパワースポットとしてSquishiverseバースの評判に貢献しているのです。"
            },
            descend: {
                title: "デサント (Descend)",
                subtitle: "ダンジョンクローラーゲーム",
                p1: "この2Dローグライクダンジョンクローラーゲームでは、高い賭けと大きな報酬の可能性を体験することができ、毎回完全にユニークなプレイが可能です。",
                p2: "GameFiの季節限定ゲームであるDescendは、最も熟練した大胆なプレイヤーに、最低10k USDCの賞金プール、NFTs、SBTsで報酬を与えます。"
            }
        },
        team: {
            the_team: "チーム紹介",
            community_management: "コミュニティ管理",
            roles: {
                founder: "創設者",
                artist: "アーティスト",
                cofounder: "共同設立者",
                developer_lead: "デベロッパーリード",
                web3_developer: "Web3デベロッパー",
                metaverse_lead: "メタバースリード",
                metaverse_developer: "Metaverseデベロッパー",
                creative_help: "クリエイティブ・ヘルプ",
            },
        },
    },

    // Staking Page
    staking: {
        join_squishies: "{num}に参加する Squishies On A ",
        adventure: "冒険",
        claim_rewards: "報酬を請求する",
        farming_yield_rate: "ファーミング作業{totalYield} $xSLIME /日",
        switch_to_land: "ランドへ移動",
        switch_to_squishies: "Squishiesに切り替える",
        loading_your: "{what}をロードしています、お待ちください",
        dont_own_any: "あなたは{what}を所有していません",
    },

    // Marketplace
    marketplace: {
        header: "マーケットプレイス",
        categories: "カテゴリー",
        loading_marketplace: "マーケットプレイスをロード中...",
        filters_all: "フィルター",
        view_order_history: "注文履歴を見る",
        filters: {
            all: "すべて",
            active: "アクティブ",
            newest: "最新",
            oldest: "最も古い",
            lowest_price: "最安値",
            highest_price: "最高価格",
        },
    },

    // Inventory
    inventory: {
        header: "在庫情報",
        swap_slime: "スワップスライム",
        you_will_receive: "受け取る",
        swap_for: "にスワップ",
        swap_to: "へスワップ",
        same_loot_withdraw_tip: "ヒント：同じ種類の戦利品を複数個引き出すことができます。",
        disclaimer: {
            slime: {
                p1: "取引に失敗した場合、任意の金額を再請求してください、最後の請求の処理を再度試みます",
                p2: "xSLIMEから$SLIMEにスワップする場合、{calculatedFee}の手数料が適用されます。",
                p3: "＄xSLIMEへのスワップは、送信後到着まで最大10分かかることがあります。",
            },
            item: {
                p1: "L1アイテムの残高は、正確に反映されるまでに10分ほどかかる場合があります",
                p2: "失敗またはキャンセルされたスワップを再試行するには、スワップボタンを再度クリックしてください。",
            },
            loot: {
                p1: "スマートコントラクトがNFTを保管し、先着順で分配されます。",
                p2: "スワップボタンをもう一度押すと、失敗したスワップやキャンセルされたスワップを再度試みることができます。",
            }
        },
        swap_your_items: "{layer}のアイテムをスワップする",
        no_inventory: "インベントリーは表示されません",
    },

    // Lootboxes
    lootbox: {
        header: "戦利品",
        you_have_won: "あなたの勝ちです！",
        fairness_roll_hash: "フェアネスロールハッシュ",
        lootbox_content_header: "戦利品ボックスの内容",
        lootbox_content_disclaimer: "免責事項：表示されるNFTは、ウォレットに引き出した後に受け取るものを代表するものではない可能性があります。私たちのスマートコントラクトは定期的にNFTで資金を調達しており、NFTの引き出しは先着順で請求されます。"
    },

    // Media
    media: {
        header: "メディア",
        gifs: "GIFs",
        animation: "アニメーション",
        wallpapers: "壁紙",
        manga: "マンガ",
        extra: "おまけ",
        wrong_input: "入力に誤りがありました。もう一度やり直してください。",
        enter_squishie: "Squishieナンバーを入力してください（複数読み込みの場合はカンマで区切る）。",
        see_more_giphy: "Giphyでもっと見る!",
    },

    // Settings
    settings: {
        header: "アカウント設定",
        discord_account: "Discordアカウント",
        add_discord_account: "Discordアカウントを追加する",
    },
}