<template>
  <div class="account-settings">
    <div class="container">
      <Card :title="$t('settings.header')" type="settings">
        <div class="setting-item">
          <div class="key">
            <div>{{ $t("settings.discord_account") }}</div>
          </div>
          <div class="value">
            <div v-if="$store.state.token === null">
              <i>{{ $t("generic.wallet_not_connected") }}</i>
            </div>
            <div v-else-if="discordUser === null">
              {{ $t("settings.add_discord_account") }}
              <a :href="authUrl">{{ $t("generic.connect") }}</a>
            </div>
            <div v-else>
              <span>{{ discordUser }}</span>
              <a :href="authUrl">{{ $t("generic.reconnect") }}</a>
            </div>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from "../ui/Card";
import axios from "axios";
import Web3 from "web3";

export default {
  name: "Purchases",
  props: {},
  components: {
    Card,
  },
  computed: {
    discordUser() {
      const { socials } = this.$store.state.user;
      if (!socials || !socials.discord) {
        return null;
      }
      const { username, discriminator } = socials.discord;
      return `${username}#${discriminator}`;
    },
    authUrl() {
      return `https://discord.com/oauth2/authorize?response_type=token&client_id=${process.env.VUE_APP_DISCORD_CLIENT_ID}&state=${this.$store.state.token}&scope=identify`;
    },
  },
  async mounted() {
    const vm = this;
    await vm.attachDiscord();
    await vm.$store.dispatch("loadUserSocials");
  },
  methods: {
    /**
     * @dev works but deprecated for now since http provider does not support signatures
     */
    async authenticateAsToken(token) {
      const vm = this;
      // decode jwt to get account address
      const { payload } = JSON.parse(atob(token.split(".")[1]));
      await vm.$store.commit("setToken", token);
      await vm.$store.commit("setAccount", payload.address);
      await vm.$store.commit(
        "setProvider",
        new Web3.providers.HttpProvider(
          `https://${process.env.VUE_APP_CONTRACT_NETWORK}.infura.io/v3/${process.env.VUE_APP_INFURA_ID}`
        )
      );
      await vm.$store.commit("setWeb3", new Web3(vm.$store.state.provider));
      // fetch tokens
      vm.$store.dispatch("fetchUserTokens", payload.address);
      vm.$store.dispatch("fetchUserInventory");
      vm.$store.dispatch("fetchUserTokenInventory");
      vm.$store.dispatch("fetchTotalLootboxes");
      vm.$store.dispatch("loadUserSocials");
    },
    async attachDiscord() {
      const vm = this;
      // getting relevant discord data and
      const anchor =
        document.URL.split("#").length > 1 ? document.URL.split("#")[1] : null;
      if (anchor) {
        const queries = anchor.split("&");
        // get relevant anchors (token, state)
        let token, jwtToken;
        for (const query of queries) {
          const [key, value] = query.split("=");
          if (key === "access_token") {
            token = value;
          } else if (key === "state") {
            jwtToken = value;
          }
        }
        try {
          await axios.post(
            `${vm.$apiUrl}/socials/discord`,
            { token },
            { headers: { Authorization: `Bearer ${jwtToken}` } }
          );
          // await vm.authenticateAsToken(jwtToken);
          await vm.$store.dispatch("authenticate", { action: "settings" });
        } catch (err) {
          alert("Error while updating profile in request");
        }
      } else if (vm.$store.state.token === null) {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="scss">
.account-settings {
  margin: 2rem 0 5rem 0;
  min-height: 100vh;
  width: 100%;
  .settings {
    max-width: 700px;
    margin: 0 auto;
    .card-title {
      font-size: $font-lg;
      color: $purple;
    }
    .card-body {
      padding: 0;
    }
    .setting-item {
      display: flex;
      flex-wrap: wrap;
      .key,
      .value {
        width: 50%;
        > div {
          padding: 1rem 1.5rem;
          display: flex;
          justify-content: space-between;
        }
      }
      .key {
        width: 50%;
        border-right: 3px solid $black;
        box-sizing: border-box;
        background: $grey;
      }
    }
  }
}
</style>