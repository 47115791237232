<template>
  <div>
    <div class="staking-bg"></div>
    <div class="staking-bg-squishies"></div>
    <StakingHero />
    <StakingSelection />
  </div>
</template>

<script>
import { pages } from "../../vue.config";
import StakingHero from "../components/Hero/StakingHero.vue";
import StakingSelection from "../components/StakingSelection/StakingSelection.vue";

export default {
  name: "StakingView",
  metaInfo: {
    title: pages.staking.title,
    meta: [{ name: "description", content: pages.staking.metaDescription }],
  },
  components: {
    StakingHero,
    StakingSelection,
  },
  methods: {},
  mounted() {
    const vm = this;
    if (vm.$store.state.account !== null) {
      vm.$store.dispatch("fetchUserRewards", vm.$store.state.account);
      if (vm.$store.state.user.nfts === null) {
        vm.$store.dispatch("fetchUserNfts", vm.$store.state.account);
        vm.$store.dispatch("fetchUserLandNfts", vm.$store.state.account);
      }
    }
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@keyframes expand {
  50% {
    transform: scaleX(1.005);
  }
  100% {
    transform: scaleX(1);
  }
}
.staking {
  &-bg {
    width: 100%;
    height: 940px;
    position: absolute;
    background: url("~@/assets/images/staking/bg.jpg") no-repeat;
    background-size: 250% 100%;
    background-position: bottom;
  }
}

@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
}
@media screen and (min-width: $screen-lg) {
  .staking {
    &-bg {
      background-size: 100% 100%;
    }
    &-bg-squishies {
      width: 100%;
      height: 940px;
      position: absolute;
      background: url("~@/assets/images/staking/squishies.png") no-repeat;
      background-size: 100% 100%;
      background-position: bottom;
      z-index: 1;
      pointer-events: none;
      animation: expand 2s ease-in-out infinite;
    }
  }
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
