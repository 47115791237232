<template>
  <div class="collection" v-cloak>
    <div
      class="collection-bg"
      :style="{ opacity: (scrollPercent - 0.03) / 0.02 }"
    ></div>
    <div class="container" :style="{ opacity: (scrollPercent - 0.05) / 0.02 }">
      <h2 v-html="$t('generic.collection')"></h2>
      <p>
        {{ $t("home.collection.p1") }}
      </p>
      <p>
        {{ $t("home.collection.p2") }}
      </p>
    </div>
    <CollectionSlider />
  </div>
</template>

<script>
import CollectionSlider from "./CollectionSlider";
import { getScrollPercentage } from "../../helpers/device";
export default {
  name: "Collection",
  props: {},
  components: {
    CollectionSlider,
  },
  methods: {
    onScroll() {
      const percent = getScrollPercentage();
      if (percent < 0.15) {
        this.scrollPercent = percent;
      }
    },
  },
  mounted() {
    const vm = this;
    window.addEventListener("scroll", vm.onScroll);
  },
  data() {
    return {
      scrollPercent: 0,
    };
  },
};
</script>

<style lang="scss">
.collection {
  background: $navy;
  color: $white;
  margin-top: -0.25rem;
  z-index: 999;
  position: relative;
  h2 {
    font-size: $font-lg;
    text-transform: uppercase;
    .fix {
      margin-right: 1px;
    }
  }
  p {
    font-weight: lighter;
    text-shadow: 0 0 10px black;
  }
  &-bg {
    width: 100%;
    // height: 600px;
    margin-top: 0;
    position: absolute;
    background: linear-gradient(0deg, #001127 0%, rgba($navy, 0) 50%),
      linear-gradient(180deg, #001127 0%, rgba($navy, 0) 25%),
      url("~@/assets/images/herov2/night.png") no-repeat;

    height: 900px;
    background-size: cover;
    background-position: center 0, center 0, center -150px;
    filter: opacity(0.5) blur(10px);
  }
  > .container {
    padding: 2rem;
    text-align: center;
  }
}

@media screen and (min-width: $screen-xs) {
}
@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
  .collection {
    background: $navy;
    color: $white;
    h2 {
      font-size: $font-xxlg;
      text-transform: uppercase;
    }
    p {
      font-weight: lighter;
      font-size: $font-sm;
      margin: 3rem 0;
    }
    &-bg {
      width: 100%;
      height: 1100px;
      background-size: cover;
      background-position: center 0, center 0, center -150px;
    }
    > .container {
      padding: 5rem 2rem;
      // padding-top: 750px;
      text-align: center;
    }
  }
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
