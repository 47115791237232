export const lootboxes = [
    {
        id: 0,
        name: "og",
        order: 9999,
        tokenOutcomes: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], // tokens
    },
    {
        id: 1,
        name: "common",
        order: 1,
        contractOutcomes: [1, 2, 3, 4, 5], // contracts
    },
    {
        id: 2,
        name: "uncommon",
        order: 1,
        // contractOutcomes: [1, 6, 7, 8, 9, 10, 11], // contracts
        hide: true,
    },
    {
        id: 3,
        name: "rare",
        order: 1,
        contractOutcomes: [1, 6, 7, 8, 9, 10, 12], // contracts
        // hide: true,
    },
    {
        id: 4,
        name: "legendary",
        order: 1,
        hide: true,
    },
    {
        id: 5,
        name: "mythic",
        order: 1,
        hide: true,
    },
]

export const lootboxFromId = (id) => lootboxes.filter(l => l.id === id)[0]

export const clientSeed = (chars = 'abcdef0123456789') => [...Array(32)].map(() => chars.charAt(Math.floor(Math.random() * chars.length))).join('');
