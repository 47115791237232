<template>
  <div class="lootbox-roll">
    <Button
      :class="{ minus: true, disabled: quantity - 1 < 1 }"
      @click="decrease()"
      v-long-press="500"
      @long-press-start="longDecrease"
      @long-press-stop="killTimers"
    >
      -
    </Button>
    <Button
      class="buy-now"
      @click="$emit('open', quantity)"
      :disabled="openLimit === 0"
    >
      {{ $t("generic.open") }} {{ quantity }}
      {{ $tc("generic.lootbox", quantity) }}
    </Button>
    <Button
      :class="{ plus: true, disabled: quantity + 1 > openLimit }"
      @click="increase()"
      v-long-press="500"
      @long-press-start="longIncrease"
      @long-press-stop="killTimers"
      >+</Button
    >
  </div>
</template>

<script>
import LongPress from "vue-directive-long-press";
import Button from "../ui/Button";
export default {
  components: { Button },
  name: "LootboxRoll",
  directives: {
    "long-press": LongPress,
  },
  computed: {
    lootbox() {
      const vm = this;
      const lootboxes = (vm.$store.state.user.lootboxes || []).filter(
        (ul) => ul.lootboxId === vm.$store.state.lootbox
      );
      return lootboxes[0] || null;
    },
    openLimit() {
      if (this.lootbox?.lootboxId === 0) {
        return 1;
      }
      return this.lootbox?.quantity || 0;
    },
  },
  watch: {
    openLimit(newLimit) {
      if (newLimit === 0) {
        this.quantity = 1;
      }
    },
  },
  methods: {
    increase() {
      const vm = this;
      if (vm.quantity + 1 <= vm.openLimit) {
        vm.quantity += 1;
        vm.$emit("change", vm.quantity);
      }
    },
    longIncrease() {
      const vm = this;
      vm.killTimers();
      vm.quantityTimer = setInterval(() => vm.increase(), 75);
    },
    decrease() {
      const vm = this;
      if (vm.quantity - 1 >= 1) {
        vm.quantity -= 1;
        vm.$emit("change", vm.quantity);
      }
    },
    longDecrease() {
      const vm = this;
      vm.killTimers();
      vm.quantityTimer = setInterval(() => vm.decrease(), 75);
    },
    killTimers() {
      const vm = this;
      clearInterval(vm.quantityTimer);
      vm.quantityTimer = null;
    },
  },
  data() {
    return {
      quantityTimer: null,
      quantity: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
.lootbox-roll {
  width: 100%;
  display: grid;
  grid-template-columns: 10% 80% 10%;
  margin-bottom: 4rem;

  .minus,
  .plus {
    font-size: $font-xs;
    padding: 0;
    padding: 0rem;
    width: unset;
    height: 35px;
    margin: 0;
    color: $black;
  }

  .buy-now {
    background: $gold;
    margin: 0 1rem;
    font-size: $font-sm;
    // padding: 0rem;
    width: unset;
  }

  &.unpurchasable {
    opacity: 0.5;
    button {
      font-size: $font-xs;
      &.disabled {
        opacity: 1;
      }
    }
  }

  &-100 {
    grid-template-columns: 100%;
    > .buy-now {
      margin: 0;
    }
  }
}
</style>