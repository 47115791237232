import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";

export default {
    // web3
    web3: null,
    provider: null,
    account: null,
    token: null,

    // profile
    user: {
        nfts: null,
        landNfts: null,
        inventory: {
            onchain: null,
            offchain: null,
        },
        tokenInventory: null,
        claim: {
            balance: null,
            rewards: null,
        },
        tokens: null,
        lootboxes: null,
        orders: null,
        socials: null
    },

    // staking
    stakingSelection: [],

    // marketplace
    products: null,
    productSelection: null,

    // modals
    mintModal: false,
    stakeModal: false,
    transactionModal: false,

    // bridge
    bridgingMode: 'slime',

    // lootboxes
    lootbox: 1, // common

    // total minted
    mintStatus: {
        publicOpen: false,
        presaleOpen: false,
        remainingPublic: 5,
        remainingPresale: 1,
        totalMinted: 8888,
        totalStaked: 0,
        bridgeTax: 0,
    },

    // presale merkle proof
    presaleTier: {
        tier: null,
        proof: ["0x"],
        root: null,
        valid: false,
        loaded: false,
    },

    // transaction information
    tx: {
        started: false,
        completed: false,
        failed: false,
        failedMsg: null,
        statusText: null,
        hash: false,
    },

    // web3 modal config
    providerOptions: {
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                infuraId: process.env.VUE_APP_INFURA_ID,
                network: process.env.VUE_APP_CONTRACT_NETWORK,
            },
        },
        walletlink: {
            package: WalletLink,
            options: {
                appName: "Squishiverse",
                infuraId: process.env.VUE_APP_INFURA_ID,
                chainId: process.env.VUE_APP_CONTRACT_NETWORK === "rinkeby" ? 4 : 1,
            },
        },
    },
}