export const getScrollPercentage = () => {
    var h = document.documentElement,
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';
    return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight);
}

export const getDeviceWidth = () => {
    return window.innerWidth > 0 ? window.innerWidth : screen.width;
}