<template>
  <div class="modal">
    <div class="modal-overlay" @click.self="$emit('closePrompt')"></div>
    <div class="modal-container">
      <div class="modal-header">
        <h1>Order Information</h1>
      </div>
      <div class="modal-body">
        <p>This purchase requires some additional information</p>
        <form @submit="formSubmit">
          <div class="form-group">
            <label>Discord Tag</label>
            <input
              v-if="discordUser === null"
              type="text"
              placeholder="Example"
              pattern="^.{2,32}$"
              v-model="discord"
              required
            />
            <input v-else type="text" :value="discordUser" required disabled />
          </div>
          <div class="form-group">
            <label>Recipient Address</label>
            <input
              type="text"
              placeholder="0x..."
              pattern="^(0x){1}[0-9a-fA-F]{40}$"
              v-model="recipient"
              required
            />
          </div>
          <Button>Submit Information</Button>
          <p class="disclaimer">
            DISCLAIMER: NOT FINANCIAL ADVICE, DO YOUR OWN RESEARCH. WE ARE IN NO
            WAY ENDORSING THIS PROJECT OR SAYING YOU SHOULD MINT IT.
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import store from "storejs";
import Button from "../ui/Button.vue";

export default {
  name: "OrderInfoModal",
  props: {},
  components: {
    Button,
  },
  computed: {
    discordUser() {
      const { socials } = this.$store.state.user;
      if (!socials || !socials.discord) {
        return null;
      }
      const { username } = socials.discord;
      return `${username}`;
    },
  },
  methods: {
    formSubmit(e) {
      e.preventDefault();
      const vm = this;
      const discord = vm.discordUser || vm.discord;
      store.set("orderDiscord", discord);
      this.$emit("submit", { recipient: vm.recipient, discord });
    },
  },
  mounted() {},
  data() {
    return {
      discord: store.get("orderDiscord") || "",
      recipient: this.$store.state.account,
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7.5px);
  }
}

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999999;
  color: $black;
  font-weight: 600;

  h1 {
    font-family: $font-primary;
    margin-top: 0;
  }

  &-overlay {
    display: block;
    position: absolute;
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
  }

  &-container {
    width: 100%;
    z-index: 9999;
    max-width: 650px;
    padding: 1rem;
  }

  &-body {
    top: 0;
    left: 0;
    background: $white;
    z-index: 9999;
    margin: auto 0;
    text-align: center;
    @include threedee-box(3px);
    position: relative;
    padding: 2rem 3rem;
    border-radius: $radius-md;

    pre {
      text-align: left;
      background: rgba($black, 0.05);
      // border-radius: 10px;
      padding: 1rem;
      max-height: 300px;
      overflow-y: auto;
    }

    .form-group {
      display: flex;
      flex-direction: column;
      text-align: left;
      label {
        margin: 1rem 0 0.5rem 0;
      }
      input {
        color: $black;
        padding: 0.75rem 1rem;
        border-radius: $radius-md;
        margin-bottom: 0.5rem;
        font-size: $font-xs;
        border: 3px solid $black;
        // @include threedee-box(3px, $black);
        &:disabled {
          background: $grey;
          opacity: 0.8;
          cursor: not-allowed;
        }
      }
    }

    a {
      color: black;
    }

    .disclaimer {
      margin-top: 2rem;
      margin-bottom: 0;
      font-size: $font-xxxs;
      color: $red;
    }

    button {
      &.close {
        margin-top: 2rem;
      }
    }
  }

  &-header {
    text-align: center;
    z-index: 9998;
    position: relative;
    width: 100%;
    h1 {
      font-family: $font-primary;
      font-size: $font-lg;
      margin: 0;
      margin-bottom: 2rem;
      color: $white;
      @include threedee-text(4px);
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .modal {
    &-header {
      h1 {
        font-size: $font-xlg;
        margin-bottom: 0.2rem;
        @include threedee-text-extra(6px, 4px, $black);
      }
    }
  }
}
</style>
