export default {
    // Languages:
    languages: {
        en: "English",
        cn: "中文",
        jp: "日本",
        vn: "Tiếng Việt",
        th: "ไทย",
        es: "Español",
        ru: "Русский",
    },

    // Generic
    generic: {
        connect_wallet: "Подключить кошелёк",
        wallet_not_connected: "Кошелёк не подключен",
        squishie: "Squishie | Squishies",
        land: "Участок | Участки",
        lootbox: "Кейс | Кейсы",
        your: "Ваши",
        balance: "Баланс",
        farmed: "Собрано",
        collection: "Коллекция Squishi<span class='fix'></span>verse",
        footer: "Squishiverse &copy; 2022. Все права защищены.",
        nothing_to_show: "Здесь ничего нет",
        buy_now: "Купить",
        buy_now_qty: "Купить {quantity} штук",
        unavailable: "Не доступно",
        close: "Закрыть",
        settings: "Настройки",
        beta: "Бета",
        items: "Предметы",
        i_understand: "Подтверждаю",
        cancel: "Отмена",
        max: "Максимум",
        claim: "Получить",
        loading: "Загрузка",
        fetch: "Загрузить",
        episode: "Эпизод",
        connect: "Подключить",
        reconnect: "Переподключить",
        open: "Открыть",
    },

    // Navigation
    nav: {
        home: "Главная",
        staking: "Стейкинг",
        marketplace: "Магазин",
        inventory: "Инвентарь",
        lootbox: "Кейс",
        media: "Арт",
    },

    // Transaction
    modal: {
        title: {
            market_purchase: "Покупка в магазине",
            claim_rewards: "Запрос выйгрыша",
            authentication: "Авторизация",
            slime_bridge: "Бридж $SLIME",
            item_bridge: "Бридж вещей",
            lootbox_bridge: "Бридж участков",
            squishie_adventure: "Приключение Squishie",
        },
        body: {
            transaction_failed: 'Транзакция не удалась!',
            transaction_complete: 'Транзакция успешно завершена!',
            transaction_pending: 'Транзакция обрабатывается...',
            transaction_confirming: 'Транакция подтверждается... пожалуйста подождите.',
            transaction_wrong: 'Что-то пошло не так. Попробуйте ещё раз.',
            view_etherscan: "Посмотреть на Etherscan",
            item_bridge_warning: "Напоминание перед обменом: процесс обмена на цепь не может быть остановлен или отменён!",
            transaction_interrupt_retry: "Если транзакция завершилась ошибкой, попробуйте ещё раз с любым колличеством и последний перевод будет повторён.",
            lootbox_bridge_warning: "Напоминание перед обменом: процесс обмена на цепь не может быть остановлен или отменён!",
            fifo_issuance: "NFT хранятся в смарт-контракте и выдаются в порядке поступления транакций.",
            lootbox_nft_unavailable: "Если выбранных вами нфт больше не осталось, транзакция закончится ошибкой, пожалуйста подождите пока мы добавим новые.",
            slime_swap_warning: "Напоминание перед обменом: процесс обмена на цепь не может быть остановлен или отменён!",
            slime_swap: "Ваш баланс $xSLIME будет уменьшен и сигнатура сгенерирована что-бы получить ваши {amount} $SLIME.",
            nothing_to_show_product: "Нечего показать про этот продукт"
        },
    },

    // Homepage
    home: {
        collection: {
            p1: "Добро пожаловать в Squishiverse, где оживают милые, цифровые и коллекционные создания. Вдохновлённый культовыми персонажами слизи из азиатской поп-культуры, наш Веб3 бренд предлагает широкий спектр душевных впечатлений, от коллекционных предметов и историй до мерча и многого другого. Будучи лидером в мета-вселенной, мы стремимся расширить границы Веб3 и обеспечить инновационные впечатления.",
            p2: "Став обладателем Squishie, вы станете частью сплоченного сообщества, которое ценит развитие возможностей и силы. У вас будет доступ к эксклюзивным каналам, мероприятиям, индивидуальным IP-возможностям, вайтлистам, NFT и другим преимуществам в экосистеме Squishiverse. Присоединяйтесь к нам и откройте для себя все, что может предложить Squishiverse.",
        },
        features: {
            play_now: "Начать играть",
            see_more: "Больше",
            learn_more: "Узнать больше",
            official_website: "Официальный сайт",
            instagram: "Инстаграм",
            youtube: "YouTube",
            squishiland: {
                title: "Участки Squishiland",
                subtitle: "Genesis Участки",
                p1: "SquishiLand - это ограниченная коллекция настраиваемых земельных участков, которые составляют основу взаимодействующей мета-вселенной Squishiverse.",
                p2: "Владение участком дает вам полный контроль над его дизайном и конфигурацией, что отражается на всех вторичных рынках. Вы можете использовать свой участок для создания магазина, дома, отеля, музея или любого другого сооружения, которое вы только можете себе представить. Возможности для творчества и развития безграничны с участками SquishiLand."
            },
            metaverse: {
                title: "Мета-вселенная",
                subtitle: "MMORPG METAVERSE",
                p1: "Многие Веб3-игры и мета-вселенные с трудом удерживают базу игроков, потому что им не хватает увлекательного геймплея. В Squishiverse все будет по-другому. Мы закладываем основы для веселого, полезного, социального и совместимого процесса для всех игроков.",
                p2: "Наша платформа Веб3 объединит лучшие элементы успешной MMORPG с помощью передовой технологиии Веб3, предлагая действительно уникальный и приятный опыт с упором на децентрализованное владение ресурсами.",
            },
            ip: {
                title: "Использование ИС",
                subtitle: "Бренд Squishiverse",
                p1: "Наша цель в Squishiverse - расширить возможности нашего сообщества и укрепить использование интеллектуальной собственности (ИС) через создаваемые нами продукты. Держатели Squishi представлены в наших оригинальных мультсериалах, а также в высококачественных гифках и мемах, которые помогают создать индивидуальную ИС каждого Squishi.",
                p2: "Товары, игрушки, плюшевые игрушки, анимация, мемы и т.д. - все это способствует репутации Squishiverse как мощного центра использования ИС."
            },
            descend: {
                title: "Descend (Спуск)",
                subtitle: "Игра - Подземелье",
                p1: "Испытайте высокие риски и потенциал для больших наград в этой 2д игре в жанре rogue-like подземелья с полностью уникальным прохождением каждую игру.",
                p2: "Будучи сезонной игрой GameFi для всех, Descend вознаграждает самых опытных и смелых игроков минимальным призовым фондом в 10k USDC, NFT и SBT."
            }
        },
        team: {
            the_team: "Комманда",
            community_management: "Менеджмент сообщества",
            roles: {
                founder: "Основатель",
                artist: "Художник",
                cofounder: "Со-Основатель",
                developer_lead: "Главный программист",
                web3_developer: "Веб3 Программист",
                metaverse_lead: "Главный разработчик игр",
                metaverse_developer: "Разработчик игр",
                creative_help: "Творческая поддержка",
            },
        },
    },

    // Staking Page
    staking: {
        join_squishies: "Присоеденитесь к {num} Squishies в",
        adventure: "Приключении",
        claim_rewards: "Забрать награду",
        farming_yield_rate: "Фарм: {totalYield} $xSLIME в день",
        switch_to_land: "Перейти к участкам",
        switch_to_squishies: "Перейти к Squishies",
        loading_your: "{what} загружаются, Подождите!",
        dont_own_any: "У вас нет {what}",
    },

    // Marketplace
    marketplace: {
        header: "Магазин",
        categories: "Катерогии",
        loading_marketplace: "Загружаю магазин...",
        filters_all: "Фильтры",
        view_order_history: "История покупок",
        filters: {
            all: "Все",
            active: "Активные",
            newest: "Новые",
            oldest: "Старые",
            lowest_price: "Дешёвые",
            highest_price: "Дорогие",
        },
    },

    // Inventory
    inventory: {
        header: "Инвентарь",
        swap_slime: "Обменять Slime",
        you_will_receive: "Вы получите",
        swap_for: "Обменять из",
        swap_to: "Обменять в",
        same_loot_withdraw_tip: "Подсказка: Вы можете вывести несколько предметов одного вида одновременно!",
        disclaimer: {
            slime: {
                p1: "Если транзакция завершилась ошибкой, попробуйте ещё раз с любым колличеством и последний перевод будет повторён",
                p2: "Комиссия на обмен из $xSLIME в $SLIME: {calculatedFee}",
                p3: "Обмены в $xSLIME могут прийти с задержкой до 10 минут с момента отправки",
            },
            item: {
                p1: "Инвентарь вещей на L1 могут отображаться не верно до 10 минут",
                p2: "Нажмите на кнопку ещё раз для повторной попытки не удавшихся переводов",
            },
            loot: {
                p1: "NFT хранятся в смарт-контракте и выдаются в порядке поступления транакций",
                p2: "Нажмите на кнопку ещё раз для повторной попытки не удавшихся переводов",
            }
        },
        swap_your_items: "Обменять вещи на {layer}",
        no_inventory: "Инвентарь пуст",
    },

    // Lootboxes
    lootbox: {
        header: "Кейсы",
        you_have_won: "Вы выйграли!",
        fairness_roll_hash: "Честный хеш спина",
        lootbox_content_header: "Содержимое кейса",
        lootbox_content_disclaimer: "ВНИМАНИЕ: Показанные NFT являются примером из названной коллекции и не гарантированны при выводе из инвентаря. Наш смарт-контракт переодически наполняется и их вывод происходит в порядке поступления транакций на вывод"
    },

    // Media
    media: {
        header: "Арт",
        gifs: "Гифки",
        animation: "Анимации",
        wallpapers: "Фоны",
        manga: "Мангм",
        extra: "Экстра",
        wrong_input: "С введённми данными что-то не так",
        enter_squishie: "Введите свой номер Squishie (или несколько через запятую)",
        see_more_giphy: "Больше на Giphy!",
    },

    // Settings
    settings: {
        header: "Настройки аккаунта",
        discord_account: "Аккаунт Discord",
        add_discord_account: "Добавить Discord аккаунт",
    },
}
