<template>
  <div class="hero-wrapper">
    <Hero />
    <Collection />
    <Features />
    <Team />
  </div>
</template>

<script>
import { pages } from "../../vue.config";

import Hero from "../components/Hero/Hero.vue";
import Collection from "../components/Collection/Collection.vue";
import Features from "../components/Features/Features.vue";
import Team from "../components/Team/Team.vue";

export default {
  name: "HomeView",
  components: {
    Hero,
    Collection,
    Features,
    Team,
  },
  metaInfo: {
    title: pages.index.title,
    meta: [{ name: "description", content: pages.index.metaDescription }],
  },
};
</script>

<style lang="scss"></style>
