export default {
    // Languages:
    languages: {
        en: "English",
        cn: "中文",
        jp: "日本",
        vn: "Tiếng Việt",
        th: "ไทย",
        es: "Español",
        ru: "Русский",
    },

    // Generic
    generic: {
        connect_wallet: "Kết nối ví",
        wallet_not_connected: "Chưa kết nối ví",
        squishie: "Squishie | Squishies",
        land: "Đất | Đất",
        lootbox: "Rương kho báu | Các rương kho báu",
        your: "Của bạn",
        balance: "Số dư",
        farmed: "Canh tác",
        collection: "Bộ sưu tập Squishi<span class='fix'></span>verse",
        footer: "Squishiverse &copy; 2022. All rights reserved.",
        nothing_to_show: "Không có gì trong này cả",
        buy_now: "Mua ngay",
        buy_now_qty: "Mua {quantity} ngay",
        unavailable: "Không có sẵn",
        close: "Đóng",
        settings: "Cài đặt",
        beta: "Phiên bản thử nghiệm",
        items: "Vật phẩm",
        i_understand: "Tôi hiểu",
        cancel: "Hủy bỏ",
        max: "Tôi đa",
        claim: "Yêu cầu phần thưởng",
        loading: "Đang tải",
        fetch: "Lấy được",
        episode: "Tập",
        connect: "Kết nối",
        reconnect: "Kết nối lại",
        open: "Mở",
    },

    // Navigation
    nav: {
        home: "Trang chủ",
        staking: "Staking",
        marketplace: "Chợ giao dịch",
        inventory: "Kho đồ",
        lootbox: "Rương kho báu",
        media: "Truyền thông",
    },

    // Transaction
    modal: {
        title: {
            market_purchase: "Chợ giao dịch",
            claim_rewards: "Nhận phần thưởng",
            authentication: "Xác thực",
            slime_bridge: "Chuyển đổi $SLIME",
            item_bridge: "Chuyển đổi vật phẩm",
            lootbox_bridge: "Chuyển đổi rương kho báu",
            squishie_adventure: "Squishie phiêu lưu",
        },
        body: {
            transaction_failed: 'Giao dịch không thành công!',
            transaction_complete: 'Giao dịch hoàn tất!',
            transaction_pending: 'Giao dịch đang chờ xử lý...',
            transaction_confirming: 'Đang xác nhận giao dịch... Vui lòng đợi.',
            transaction_wrong: 'Đã xảy ra sự cố. Vui lòng thử lại.',
            view_etherscan: "Xem trên Etherscan",
            item_bridge_warning: "Trước khi bạn hoán đổi, đây là một lời nhắc thân thiện: khi hoán đổi vật phẩm lên blockchain, bạn sẽ không thể hủy quá trình này.",
            transaction_interrupt_retry: "Nếu giao dịch không thành công, hãy thử lại sau vài giây.",
            lootbox_bridge_warning: "Trước khi bạn hoán đổi, đây là một lời nhắc thân thiện: khi hoán đổi vật phẩm lên blockchain, bạn sẽ không thể hủy quá trình này.",
            fifo_issuance: "Hợp đồng thông minh của chúng tôi chứa các NFTs và phục vụ trên nguyên tắc ai đến trước phục vụ trước (first-come first-serve).",
            lootbox_nft_unavailable: "Bạn không thể giao dịch thành công khi chúng tôi chưa có sẵn NFT, hãy chờ đợi cho đến khi chúng tôi bổ sung.",
            slime_swap_warning: "Trước khi bạn hoán đổi $xSLIME thành $SLIME, đây là một lời nhắc thân thiện: bạn sẽ không thể hủy quá trình này.",
            slime_swap: "Số dư $xSLIME của bạn sẽ giảm và chữ ký xác nhận quyền sở hữu sẽ được tạo, chữ ký này được sử dụng để đổi lấy {amount} $SLIME.",
            nothing_to_show_product: "Không có gì để xem đối với sản phẩm này"
        },
    },

    // Homepage
    home: {
        collection: {
            p1: "Chào mừng bạn đến với Squishiverse, nơi của những bộ sưu tập kỹ thuật số dễ thương và sống động. Lấy cảm hứng từ các nhân vật slime mang tính biểu tượng tại châu Á. Thương hiệu Web-3 Squishiverse của chúng tôi mang đến nhiều trải nghiệm ấm áp, từ bộ sưu tập đến các câu chuyện truyền cảm hứng và nhiều sản phẩm thú vị khác,... Với tư cách là công ty hàng đầu trong lĩnh vực Metaverse, chúng tôi tận tâm thúc đẩy các ranh giới của Web3 và mang đến những trải nghiệm mới mẻ cho mọi người.",
            p2: "Với tư cách là người nắm giữ Squishie, bạn trở thành một phần của cộng đồng gắn bó chặt chẽ, bạn sẽ nhận được các giá trị cốt lõi từ cộng đồng và được trao quyền truy cập vào các khu vực đặc biệt: sự kiện, cơ hội sở hữu quyền sở hữu trí tuệ từ các sản phẩm riêng lẻ, danh sách trắng, NFT và các lợi ích khác trong hệ sinh thái Squishiverse. Tham gia với chúng tôi và khám phá tất cả những gì Squishiverse cung cấp.",
        },
        features: {
            play_now: "Chơi ngay",
            see_more: "Xem thêm",
            learn_more: "Đọc thêm",
            official_website: "Website chính thức",
            instagram: "Instagram",
            youtube: "YouTube",
            squishiland: {
                title: "Squishiland",
                subtitle: "Lô đất Genesis",
                p1: "SquishiLand là một bộ sưu tập đất đặc biệt có số lượng giới hạn và cực kỳ khan hiếm, SquishiLand bao gồm các lô đất có thể tùy chỉnh, sử dụng được trong thế giới metaverse và có thể tương tác với các nhân vật trong Squishiverse.",
                p2: "Sở hữu một lô đất cho phép bạn kiểm soát được mọi thứ từ việc thiết kế và tùy chỉnh tính năng trên lô đất, những điều này sẽ được mô tả chi tiết trên các chợ giao dịch. Bạn có thể sử dụng cốt truyện của mình để tạo cửa hàng, nhà ở, khách sạn, bảo tàng hoặc bất kỳ công trình kiến trúc nào khác mà bạn có thể tưởng tượng ra. Cơ hội sáng tạo và phát triển là vô tận với SquishiLand."
            },
            metaverse: {
                title: "Vũ trụ ảo",
                subtitle: "MMORPG METAVERSE",
                p1: "Nhiều trò chơi Web3 và metaverse gặp khó khăn trong việc duy trì số lượng người chơi vì lối chơi kém hấp hẫn. Squishiverse sẽ khác. Chúng tôi đang tập trung xây dựng nền tảng cho những trải nghiệm thú vị, bổ ích, mang tính xã hội và tương tác cho tất cả người chơi.",
                p2: "Nền tảng Web3 của chúng tôi sẽ kết hợp các yếu tố tốt nhất của một MMORPG game thành công với công nghệ tiên tiến của Web3, mang đến trải nghiệm thực sự độc đáo và thú vị, tập trung vào quyền sở hữu tài sản phi tập trung.",
            },
            ip: {
                title: "Sử dụng quyền sở hữu trí tuệ",
                subtitle: "Xây dựng thương hiệu Squishiverse",
                p1: "Tại Squishiverse, mục tiêu của chúng tôi là trao quyền cho cộng đồng và tăng cường việc sử dụng quyền sở hữu trí tuệ thông qua các sản phẩm chúng tôi tạo ra. Những Squishie được giới thiệu trong các phim hoạt hình của chúng tôi, cũng như trong ảnh GIF và meme với chất lượng cao giúp tạo nên quyền sở hữu trí tuệ riêng của từng Squishie.",
                p2: "Các loại hàng hóa như: đồ chơi, thú nhồi bông, hoạt hình, meme, v.v., tất cả những thứ này đều góp phần xây dựng thương hiệu của Squishiverse giúp mọi người trở thành một phần của cộng đồng và trở thành một cường quốc sử dụng quyền sở hữu trí tuệ của các sản phẩm."
            },
            descend: {
                title: "CHIẾN ĐẤU (Descend)",
                subtitle: "Dungeon Crawler Game",
                p1: "Trải nghiệm việc đặt cược và khả năng nhận phần thưởng lớn với Dungeon Crawler Game (2D), bạn sẽ trải nghiệm các màn chơi độc đáo ở mỗi lượt chơi.",
                p2: "Là một trò chơi dành cho tất cả mọi người, phần thường cho những người chơi có kỹ năng và sự táo bạo nhất với tổng giải thưởng tối thiểu là 10 nghìn USDC, NFTs và SBTs."
            }
        },
        team: {
            the_team: "Đội ngũ phát triển",
            community_management: "Quản lý cộng đồng",
            roles: {
                founder: "Người sáng lập",
                artist: "Họa sĩ",
                cofounder: "Đồng sáng lập",
                developer_lead: "Trưởng nhóm phát triển",
                web3_developer: "Nhà phát triển Web3",
                metaverse_lead: "Trưởng nhóm Game",
                metaverse_developer: "Nhà phát triển Game",
                creative_help: "Hỗ trợ sáng tạo",
            },
        },
    },

    // Staking Page
    staking: {
        join_squishies: "Tham gia {num} Squishies vào A",
        adventure: "Phiêu lưu",
        claim_rewards: "Yêu cầu phần thưởng",
        farming_yield_rate: "Canh tác {totalYield} $xSLIME / DAY",
        switch_to_land: "Chuyển sang đất",
        switch_to_squishies: "Chuyển sang Squishies",
        loading_your: "Đang tải {what} của bạn, vui lòng đợi!",
        dont_own_any: "Bạn không sở hữu bất kỳ {what} nào",
    },

    // Marketplace
    marketplace: {
        header: "Chợ giao dịch",
        categories: "Chuyên mục",
        loading_marketplace: "Đang tải chợ giao dịch",
        filters_all: "Bộ lọc",
        view_order_history: "Xem lịch sử giao dịch",
        filters: {
            all: "Tất cả",
            active: "Hoạt động",
            newest: "Mới nhất",
            oldest: "Cũ nhất",
            lowest_price: "Giá thấp nhất",
            highest_price: "Giá cao nhất",
        },
    },

    // Inventory
    inventory: {
        header: "Kho đồ",
        swap_slime: "Hoán đổi Slime",
        you_will_receive: "Bạn sẽ nhận được",
        swap_for: "Hoán đổi cho",
        swap_to: "Hoán đổi thành",
        same_loot_withdraw_tip: "TIP: Bạn có thể rút nhiều vật phẩm cùng loại",
        disclaimer: {
            slime: {
                p1: "Nếu bạn giao dịch không thành công, hãy thử lại với một số khác và hệ thống sẽ cố gắng xử lý yêu cầu mới của bạn",
                p2: "Áp dụng phí giao dịch của {calculatedFee} khi bạn hoán đổi từ $xSLIME to $SLIME",
                p3: "Việc hoán đổi thành $xSLIME có thể sẽ mất tối đa 10 phút hoặc sớm hơn",
            },
            item: {
                p1: "Các mặt hàng L1 có thể mất tối đa 10 phút để cập nhật chính xác",
                p2: "Nhấp vào nút hoán đổi một lần nữa để thử lại các giao dịch thất bại hoặc bị hủy",
            },
            loot: {
                p1: "Một hợp đồng thông minh chứa các NFTs và phục vụ trên nguyên tắc ai đến trước phục vụ trước (first-come first-serve)",
                p2: "Nhấp vào nút hoán đổi một lần nữa để thử lại các giao dịch thất bại hoặc bị hủy",
            }
        },
        swap_your_items: "Hoán đổi {layer} vật phẩm của bạn",
        no_inventory: "Không có kho đồ",
    },

    // Lootboxes
    lootbox: {
        header: "Rương kho báu",
        you_have_won: "Bạn đã chiến thắng!",
        fairness_roll_hash: "Fairness Roll Hash",
        lootbox_content_header: "Bên trong rương kho báu",
        lootbox_content_disclaimer: "TUYÊN BỐ MIỄN TRỪ TRÁCH NHIỆM: NFTs hiển thị có thể không giống với NFTs bạn nhận được sau khi rút về ví của mình. Các hợp đồng thông minh của chúng tôi được thiết lập sẵn và việc rút NFT dựa trên nguyên tắc ai tới trước phục vụ trước."
    },

    // Media
    media: {
        header: "Kênh truyền thông",
        gifs: "GIFs",
        animation: "Hoạt hình",
        wallpapers: "Hình nền",
        manga: "Truyện",
        extra: "Thêm",
        wrong_input: "Đã xảy ra lỗi với những thông tin của bạn, vui lòng thử lại.",
        enter_squishie: "Nhập mã số Squishie của bạn (sử dụng dấu phẩy để tải nhiều Squishie)",
        see_more_giphy: "Xem thêm trên Giphy",
    },

    // Settings
    settings: {
        header: "Cài đặt tài khoản",
        discord_account: "Tài khoản Discord",
        add_discord_account: "Thêm tài khoản Discord của bạn",
    },
}