<template>
  <div class="feature-carousel">
    <CatCarousel
      :items="items"
      :item-per-page="1"
      :indicators-config="{ hideIndicators: true }"
    >
      <template slot="item" slot-scope="{ data }">
        <video
          v-if="data.includes('mp4')"
          ref="chest"
          autoplay
          muted
          loop
          playsinline
        >
          <source :src="data" type="video/mp4" />
        </video>
        <img
          v-else
          :src="data"
          draggable="false"
          v-bind:class="{ pixelated: data.includes('descend-2') }"
        />
      </template>
    </CatCarousel>
  </div>
</template>

<script>
import { CatCarousel } from "vue-cat-carousel";
export default {
  name: "FeatureCarousel",
  props: {
    images: Array,
  },
  methods: {},
  computed: {
    items() {
      return this.images.map((i) =>
        require(`@/assets/images/herov2/previews/` + i)
      );
    },
  },
  components: {
    CatCarousel,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.feature-carousel {
  transform: scale(1.01);
  .cat-carousel__content__wrapper__item {
    padding: 0;
  }
  .cat-carousel__content {
    max-height: 300px;
  }
  .cat-carousel__default-nav {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    > img {
      filter: brightness(0) invert(1);
    }
  }
  .pixelated {
    image-rendering: pixelated;
  }
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: scale(1.01);
  }
}

@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
