<template>
  <div class="staking-selection">
    <div class="container">
      <h2>{{ $t("generic.your") }} {{ selectedNftName() }}</h2>
      <div class="labels" v-if="$store.state.account !== null">
        <div class="yield">
          {{ $t("staking.farming_yield_rate", { totalYield }) }}
        </div>
        <Button @click="switchStaking" size="sm">
          {{
            $t(
              stakingLand
                ? "staking.switch_to_squishies"
                : "staking.switch_to_land"
            )
          }}
        </Button>
      </div>
      <div class="nft-note" v-if="$store.state.account === null">
        <h3>{{ $t("generic.wallet_not_connected") }}</h3>
      </div>
      <div class="nft-note" v-else-if="nfts === null">
        <h3>{{ $t("staking.loading_your", { what: selectedNftName() }) }}</h3>
      </div>
      <div class="nft-note" v-else-if="nfts !== null && nfts.length < 1">
        <h3>{{ $t("staking.dont_own_any", { what: selectedNftName() }) }}</h3>
      </div>
      <div v-else>
        <div class="nft-grid" v-if="stakingLand === true">
          <Nft
            v-for="(nft, idx) in nfts"
            :key="idx"
            :nft="nft"
            :staked="nft.staked"
            :legacy="nft.legacy"
            :selected="selectedNft(nft)"
            @click="onSelect"
          />
        </div>
        <div class="nft-grid" v-else>
          <SoftNft
            v-for="nft in nfts"
            :key="nft.id"
            :nft="nft"
            :staked="nft.staked"
          />
        </div>

        <div class="notice" v-if="!stakingLand">
          <p>
            NOTE: Your Squishiverse NFTs are automatically staked as long as
            they are not listed on any NFT marketplace.
          </p>
        </div>

        <div class="dialog container" v-if="selection.length">
          <Card>
            <Button @click="beginStaking">{{ stakingText }}</Button>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BigFloat } from "bigfloat.js";

import Nft from "./Nft";
import SoftNft from "./SoftNft";
import Card from "../ui/Card.vue";
import Button from "../ui/Button.vue";

import SquishilandAbi from "../../data/abi/SquishilandAbi.json";
import StakeLandsAbi from "../../data/abi/StakeLands.json";

export default {
  name: "StakingSelection",
  props: {},
  computed: {
    nfts() {
      const vm = this;
      return vm.stakingLand
        ? vm.$store.state.user.landNfts
        : vm.$store.state.user.nfts;
    },
    selection() {
      return this.$store.state.stakingSelection;
    },
    unstakingCount() {
      const vm = this;
      return vm.selection.filter((nft) => nft.staked === true).length;
    },
    unstakingLegacyCount() {
      const vm = this;
      return vm.selection.filter(
        (nft) => nft.staked === true && nft.legacy === true
      ).length;
    },
    legacyCount() {
      const vm = this;
      return (vm.nfts || []).filter((n) => n.legacy === true).length;
    },
    stakingCount() {
      const vm = this;
      return vm.selection.length - vm.unstakingCount;
    },
    stakingText() {
      const vm = this;
      if (vm.stakingCount > 0 && vm.unstakingCount > 0) {
        return (
          `Stake ${vm.stakingCount} ${vm.selectedNftName(
            vm.stakingCount !== 1
          )}, And ` +
          ` Unstake ${vm.unstakingCount} ${vm.selectedNftName(
            vm.unstakingCount !== 1
          )}`
        );
      } else if (vm.stakingCount > 0) {
        return `Stake ${vm.stakingCount} ${vm.selectedNftName(
          vm.stakingCount !== 1
        )}`;
      } else {
        return `Unstake ${vm.unstakingCount} ${vm.selectedNftName(
          vm.unstakingCount !== 1
        )}`;
      }
    },
    totalYield() {
      const vm = this;
      let total;
      try {
        const { landNfts, nfts } = vm.$store.state.user;
        total = [...(landNfts || []), ...(nfts || [])]
          .filter((nft) => nft.staked === true)
          .reduce((a, b) => a + b.rate, 0);
      } catch (err) {
        total = 0;
      }
      return total.toLocaleString();
    },
    stakingContract() {
      const vm = this;
      return new vm.$store.state.web3.eth.Contract(
        StakeLandsAbi,
        process.env.VUE_APP_STAKE_LAND_CONTRACT_ADDRESS
      );
    },
    nftContract() {
      const vm = this;
      return new vm.$store.state.web3.eth.Contract(
        SquishilandAbi,
        process.env.VUE_APP_LAND_CONTRACT_ADDRESS
      );
    },
  },
  methods: {
    selectedNftName(plural = true) {
      return !this.stakingLand
        ? this.$tc("generic.squishie", plural ? 2 : 1)
        : this.$tc("generic.land", plural ? 2 : 1);
    },
    async switchStaking() {
      const vm = this;
      if (
        (vm.stakingLand = !vm.stakingLand) === true &&
        vm.$store.state.user.landNfts === null
      ) {
        vm.$store.dispatch("fetchUserLandNfts", vm.$store.state.account);
      }
      vm.$store.state.stakingSelection = [];
    },
    async beginStaking() {
      const vm = this;
      const sender = vm.$store.state.account;

      // enable modal
      vm.$store.state.stakeModal = true;

      // contract address
      const { nftContract, stakingContract } = vm;

      // user nft before
      const userNftsBefore = [...(vm.selection ?? [])];

      // legacy unstake nfts
      const legacyStaked = userNftsBefore.filter(
        (nft) => nft.staked === true && nft.legacy === true
      );
      if (legacyStaked.length > 0) {
        let text = "Requesting legacy withdraw.";
        // check if they have claimed their rewards
        if (vm.$store.state.user.claim.rewards >= 100) {
          vm.$store.state.transactionModal = "rewards";
          if ((await vm.$store.dispatch("claimRewards")) === false) {
            text = `${text}\n\nWARNING: You will lose unclaimed rewards!`;
          }
          while (vm.$store.state.transactionModal !== false) {
            console.log("Waiting To Close Transaction Modal");
            await new Promise((r) => setTimeout(r, 500));
          }
        } else {
          text = `${text}\n\nNOTE: Unclaimed rewards on your ${vm.selectedNftName(
            legacyStaked.length !== 1
          )} may be lost.`;
        }
        await vm.$store.commit("setTransactionState", { statusText: text });
      }

      // skip authorizing if just legacy withdrawal
      const unstaked = userNftsBefore.filter(
        (nft) =>
          vm.selectedNft(nft) && nft.staked === false && nft.legacy === false
      );
      const staked = userNftsBefore.filter(
        (nft) =>
          vm.selectedNft(nft) && nft.staked === true && nft.legacy === false
      );
      if (unstaked.length + staked.length === 0) {
        return;
      }

      // authorize staking contract to use the nft
      await vm.$store.commit("setTransactionState", {
        hash: false,
        completed: false,
        statusText: "Checking for staking authorisation...",
      });
      if (
        (await vm.authorizeNftTransfers(
          nftContract,
          sender,
          stakingContract._address
        )) === false
      ) {
        return;
      } else {
        await vm.$store.commit("setTransactionState", {
          statusText: "Successfully authorised staking",
        });
      }

      // stake nfts
      if (unstaked.length > 0) {
        await vm.$store.commit("setTransactionState", {
          statusText: `Depositing ${unstaked.length}x ${vm.selectedNftName(
            unstaked.length !== 1
          )}...`,
        });
        const status = await vm.performStakingAction(
          stakingContract,
          sender,
          unstaked,
          "deposit"
        );
        if (status === false) {
          return;
        }
        await vm.$store.commit("setTransactionState", {
          statusText: `Successfully deposited ${
            unstaked.length
          }x ${vm.selectedNftName(unstaked.length !== 1)}...`,
        });
      }

      // unstake nfts
      if (staked.length > 0) {
        let text = `Withdrawing ${staked.length}x ${vm.selectedNftName(
          staked.length !== 1
        )}...`;
        // check if they have claimed their rewards
        if (vm.$store.state.user.claim.rewards >= 100) {
          vm.$store.state.transactionModal = "rewards";
          if ((await vm.$store.dispatch("claimRewards")) === false) {
            text = `${text}\n\nWARNING: You will lose unclaimed rewards!`;
          }
          while (vm.$store.state.transactionModal !== false) {
            console.log("Waiting To Close Transaction Modal");
            await new Promise((r) => setTimeout(r, 500));
          }
        } else {
          text = `${text}\n\nNOTE: Unclaimed rewards on your ${vm.selectedNftName(
            staked.length !== 1
          )} may be lost.`;
        }
        await vm.$store.commit("setTransactionState", { statusText: text });
        const status = await vm.performStakingAction(
          stakingContract,
          sender,
          staked,
          "withdraw"
        );
        if (status === false) {
          return;
        }
        await vm.$store.commit("setTransactionState", {
          statusText: `Successfully withdrawn ${
            staked.length
          }x ${vm.selectedNftName(staked.length !== 1)}...`,
        });
      }
    },
    async performStakingAction(
      stakingContract,
      sender,
      nfts,
      fn,
      legacy = false
    ) {
      const vm = this;
      vm.$store.commit("setTransactionState", {
        hash: false,
        completed: false,
        started: true,
      });
      const followingInstructions =
        vm.unstakingCount > 0 &&
        vm.stakingCount > 0 &&
        vm.unstakingCount !== vm.unstakingLegacyCount &&
        fn !== "withdraw"
          ? legacy === true
            ? "\n\nLegacy withdraw is now waiting. Follow the prompt in your wallet."
            : "\n\nWithdraw is now waiting. Follow the prompt in your wallet."
          : "";
      const nftIds = nfts.map((e) => e.id);
      const gasPrice = await vm.$store.state.web3.eth.getGasPrice();
      let contractFunction = stakingContract.methods[fn](nftIds);
      try {
        const gas = await contractFunction.estimateGas({ from: sender });
        const gasAmped = new BigFloat(gasPrice)
          .mul(vm.$gasPriceBoost)
          .floor()
          .toString();
        console.log({ gasPrice, gas, gasAmped });
        return await new Promise((resolve) => {
          contractFunction
            .send({
              from: sender,
              gas: parseInt(vm.$gasLimitBoost * gas),
              gasPrice: gasAmped,
            })
            .on("transactionHash", function (hash) {
              vm.$store.commit("setTransactionState", {
                hash,
                statusText: legacy
                  ? `Requested legacy ${fn}.`
                  : `Requested ${fn}.`,
              });
            })
            .on("receipt", function (receipt) {
              console.log("TX RECEIPT");
              console.log(receipt);
              vm.$store.commit("setTransactionState", {
                completed: true,
                statusText:
                  `Request to ${fn} confirmed.` + followingInstructions,
              });
              vm.$store.commit("updateSpecificNftAttributes", {
                stakingLand: vm.stakingLand,
                nftIds,
                resolve: (n) => ({
                  staked: !n.staked,
                  legacy: legacy === true ? !n.legacy : false,
                }),
              });
              resolve(true);
            })
            .on("error", function (error) {
              vm.$store.state.tx.failed = true;
              vm.$store.state.tx.failedMsg =
                (error.message || "Transaction Failed. Please try again.") +
                followingInstructions;
              resolve(false);
            });
        });
      } catch (err) {
        vm.$store.state.tx.failed = true;
        try {
          const message = /execution reverted:\s(.*)/.exec(err.message)[1];
          vm.$store.state.tx.failedMsg = message;
        } catch (regerr) {
          const possibleError = err.message || "";
          if (possibleError.includes("insufficient funds")) {
            vm.$store.state.tx.failedMsg = "Not enough funds";
          } else {
            vm.$store.state.tx.failedMsg = "Transaction failed, unknown error";
          }
        }
        return false;
      }
    },
    async authorizeNftTransfers(nftContract, sender, approvingAddress) {
      const vm = this;
      try {
        await vm.$store.commit("setTransactionState", {
          started: true,
          statusText:
            "Waiting to authorize staking, please wait till it is completed.",
        });
        let successMessage;
        const response = await vm.$store.dispatch("setNftApprovalForAll", {
          contract: nftContract,
          sender,
          approvingAddress,
        });
        if (response === true) {
          successMessage = "Successfully authorized staking contract.";
        } else {
          successMessage = "Staking has been authorized.";
        }
        await vm.$store.commit("setTransactionState", {
          statusText: `${successMessage}\n\nPlease follow the prompt in your wallet.`,
        });
        return true;
      } catch (err) {
        vm.$store.commit("setTransactionState", {
          failed: true,
          statusText: "Failed to increase token allowance.",
        });
        console.log("Failed to increase token allowance", err);
        vm.$store.state.stakingSelection = [];
        return false;
      }
    },
    onSelect(nft) {
      const vm = this;
      if (vm.selectedNft(nft)) {
        vm.$store.commit(
          "setStakingSelection",
          [...vm.selection].filter((e) => e.id !== nft.id)
        );
      } else {
        vm.$store.commit("setStakingSelection", [...vm.selection, nft]);
      }
    },
    selectedNft(nft) {
      const vm = this;
      return vm.selection.map((e) => e.id).includes(nft.id);
    },
  },
  components: {
    Nft,
    SoftNft,
    Button,
    Card,
  },
  data() {
    return {
      stakingLand: false,
    };
  },
};
</script>

<style lang="scss">
.staking-selection {
  padding: 3rem 0 5rem 0;
  position: relative;
  overflow: hidden;
  background: $creme;
  z-index: 99999;
  margin-top: -10px;
  border-top: 5px solid $black;

  h2 {
    font-size: $font-xlg;
    text-align: center;
  }

  .labels {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 5rem;
    font-weight: 600;
    .yield {
      display: inline-block;
      padding: 1rem 1.5rem;
      @include threedee-box(2px, $black);
      border-radius: 25px;
      background: $green;
      text-transform: uppercase;
    }
    button {
      display: inline-block;
      margin-top: 2rem;
      width: unset;
    }
  }

  &:before {
    content: " ";
    height: 100%;
    width: 100%;
  }

  > .container {
    position: relative;
    padding: 0 2rem;
    .nft-note {
      display: flex;
      justify-content: center;
      text-align: center;
    }
    .nft-grid {
      display: grid;
      // grid-template-columns: repeat(2, 1fr);
      // gap: 1rem;
    }

    .notice {
      font-weight: 600;
      text-align: center;
      margin: 2rem 0;
      font-size: $font-xs;
    }

    .dialog {
      position: fixed;
      bottom: 2rem;
      left: 0;
      right: 0;
      z-index: 9999;
      pointer-events: none;
      .card {
        width: calc(100% - 4rem);
        margin: 0 2rem;
        pointer-events: all;

        &-body {
          background: rgba($pink, 0.5);
          // background: rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(5px);
          @include threedee-box(3px, $pink-dark);
        }

        button {
          margin-top: 0;
          margin-bottom: 0.5rem;
          background: $pink-hot;
          @include threedee-box(3px, $pink-dark);
          color: $white;
          &:hover {
            transform: scale(1.025);
          }
        }
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .staking-selection {
    padding: 2rem 0 7rem 0;
    h2 {
    }
    > .container {
      .nft-note {
      }
      .nft-grid {
        grid-template-columns: repeat(2, 1fr);
      }
      .dialog {
        bottom: 6rem;
        .card {
          width: 600px;
          margin: 0 auto;
        }
      }
    }
  }
}
@media screen and (min-width: $screen-md) {
  .staking-selection {
    h2 {
    }
    > .container {
      .nft-note {
      }
      .nft-grid {
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem 3rem;
      }
    }
  }
}
@media screen and (min-width: $screen-lg) {
  .staking-selection {
    h2 {
    }
    > .container {
      padding: 0;
      .nft-note {
      }
      .nft-grid {
      }
    }
  }
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
