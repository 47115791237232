<template>
  <div class="bridge">
    <BridgeOutModal
      v-if="bridgeOutModal !== null"
      :amount="computedAmount"
      @understood="bridgeOutUnderstood"
      @closePrompt="bridgeOutModal = null"
    />
    <BridgeItemOutModal
      v-if="bridgeItemOutModal !== null"
      @understood="bridgeItemOutUnderstood"
      @closePrompt="bridgeItemOutModal = null"
    />
    <div class="container">
      <div v-if="$store.state.bridgingMode === 'slime'">
        <div class="swap">
          <Card :title="$t('inventory.swap_slime')">
            <div class="coin-container">
              <img
                src="@/assets/images/bridge/coin.png"
                class="coin"
                draggable="false"
              />
            </div>
            <form @submit="swapOffchain">
              <p class="balance-up" v-if="swapToOnchain">
                {{ $t("generic.balance") }}: {{ offchainBalance }} $xSLIME
                <span class="l2">(L2)</span>
              </p>
              <p class="balance-up" v-else>
                {{ $t("generic.balance") }}: {{ onchainBalance }} $SLIME
                <span class="l1">(L1)</span>
              </p>
              <div class="form-group">
                <div class="form-input">
                  <input
                    type="number"
                    :placeholder="`... $${swapToOnchain ? 'xSLIME' : 'SLIME'}`"
                    :disabled="$store.state.account === null"
                    min="1"
                    step="1"
                    v-model.number="computedAmount"
                    required
                  />
                  <div
                    class="max"
                    @click="setMaxAmount"
                    v-if="$store.state.account !== null && !isMaxed"
                  >
                    {{ $t("generic.max") }}
                  </div>
                </div>
                <!-- <div class="chain-select">
                  <Button
                    type="button"
                    class="chain"
                    @click="changeChain"
                    :disabled="$store.state.account === null"
                  >
                    &#8645;
                  </Button>
                </div> -->
              </div>
              <div class="arrow"><span>&#8595;</span></div>
              <p class="receive-down">
                {{ $t("inventory.you_will_receive") }}
                <span v-if="swapToOnchain"
                  >{{ amountFormatted }} $SLIME <span class="l1">(L1)</span>
                </span>
                <span v-else
                  >{{ amountFormatted }} $xSLIME <span class="l2">(L2)</span>
                </span>
              </p>
              <p class="balance-down">
                {{ $t("generic.balance") }}:
                <span v-if="swapToOnchain"
                  >{{ onchainBalance }} $SLIME <span class="l1">(L1)</span>
                </span>
                <span v-else
                  >{{ offchainBalance }} $xSLIME <span class="l2">(L2)</span>
                </span>
              </p>
              <Button
                v-if="$store.state.account === null"
                type="button"
                :disabled="$store.state.account === null"
              >
                {{ $t("inventory.swap_for") }}
                <span v-if="swapToOnchain">$SLIME</span>
                <span v-else>$xSLIME</span>
              </Button>
              <Button v-else :disabled="$store.state.account === null">
                {{ $t("inventory.swap_for") }}
                <span v-if="swapToOnchain">$SLIME</span>
                <span v-else>$xSLIME</span>
              </Button>
            </form>
          </Card>
        </div>
        <div v-if="swapToOnchain" class="disclaimer">
          <p>&middot; {{ $t("inventory.disclaimer.slime.p1") }}</p>
          <p>
            &middot;
            {{ $t("inventory.disclaimer.slime.p2", { calculatedFee }) }}
          </p>
        </div>
        <div v-else class="disclaimer">
          <p>&middot; {{ $t("inventory.disclaimer.slime.p3") }}</p>
        </div>
      </div>
      <div v-else-if="$store.state.bridgingMode === 'items'">
        <div class="swap swap-items">
          <Card
            :title="
              $t('inventory.swap_your_items', {
                layer: swapToOnchain ? 'L2' : 'L1',
              })
            "
          >
            <div class="item-container">
              <div class="onchain">
                <BridgeItem
                  v-for="(item, idx) in inventory"
                  :key="idx"
                  :item="item"
                  :selected="selectedItem(item)"
                  @click="selectItem"
                />
                <div class="no-items" v-if="!inventory.length">
                  {{ $t("inventory.no_inventory") }}
                </div>
              </div>
            </div>
            <form @submit="swapOffChainItems">
              <div class="swap-cta">
                <Button
                  v-if="$store.state.account === null"
                  type="button"
                  :disabled="true"
                >
                  {{ $t("inventory.swap_to") }}
                  <span v-if="swapToOnchain">L1</span>
                  <span v-else>L2</span>
                </Button>
                <Button v-else>
                  {{ $t("inventory.swap_to") }}
                  <span v-if="swapToOnchain">L1</span>
                  <span v-else>L2</span>
                </Button>
                <Button
                  type="button"
                  class="chain"
                  @click="changeChain"
                  :disabled="$store.state.account === null"
                >
                  &#8645;
                </Button>
              </div>
            </form>
          </Card>
        </div>
        <div class="disclaimer">
          <p>&middot; {{ $t("inventory.disclaimer.item.p1") }}</p>
          <p>&middot; {{ $t("inventory.disclaimer.item.p2") }}</p>
        </div>
      </div>
      <div v-else-if="$store.state.bridgingMode === 'lootbox'">
        <BridgeLoot />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "../ui/Card.vue";
import Button from "../ui/Button.vue";
import BridgeOutModal from "../MintModal/BridgeOutModal.vue";
import BridgeItemOutModal from "../MintModal/BridgeItemOutModal.vue";
import BridgeItem from "./BridgeItem.vue";
import BridgeLoot from "./BridgeLoot/BridgeLoot.vue";

import BridgeAbi from "../../data/abi/BridgeSlime.json";
import BridgeSquishiverseItem from "../../data/abi/BridgeSquishiverseItem.json";
import { BigFloat } from "bigfloat.js";

export default {
  name: "Bridge",
  props: {},
  computed: {
    offchainBalance() {
      const vm = this;
      return parseInt(vm.$store.state.user.claim.balance || 0).toLocaleString();
    },
    onchainBalance() {
      const vm = this;
      return parseInt(vm.$store.state.user.tokens || 0).toLocaleString();
    },
    amountFormatted() {
      try {
        return parseInt(this.amount || 0).toLocaleString();
      } catch (err) {
        return 0;
      }
    },
    computedAmount: {
      get: function () {
        return this.amount;
      },
      set: function (newValue) {
        try {
          this.amount = parseInt(newValue || 0);
        } catch (err) {
          this.amount = 0;
        }
      },
    },
    calculatedFee() {
      const vm = this;
      return (vm.$store.state.mintStatus.bridgeTax * 100).toFixed(3) + "%";
    },
    isMaxed() {
      const vm = this;
      return vm.swapToOnchain
        ? vm.amount >= vm.$store.state.user.claim.balance
        : vm.amount >= Math.floor(vm.$store.state.user.tokens);
    },
    inventory() {
      const vm = this;
      const inventory = vm.swapToOnchain
        ? vm.$store.state.user.inventory.offchain
        : vm.$store.state.user.inventory.onchain;
      return inventory || [];
    },
  },
  methods: {
    changeChain() {
      const vm = this;
      vm.swapToOnchain = !vm.swapToOnchain;
      vm.amount = "";
      vm.selection = [];
    },
    setMaxAmount() {
      const vm = this;
      vm.amount = vm.swapToOnchain
        ? vm.$store.state.user.claim.balance
        : Math.floor(vm.$store.state.user.tokens);
    },
    bridgeOutUnderstood() {
      const vm = this;
      vm.bridgeOutModal = null;
      vm.swapOffchainToOnchain();
    },
    bridgeItemOutUnderstood() {
      const vm = this;
      vm.bridgeItemOutModal = null;
      console.log("BridgeItemOutModal");
      vm.swapOffchainItemToOnchain();
    },
    async swapOffchain(e) {
      e.preventDefault();
      const vm = this;
      if (vm.swapToOnchain) {
        vm.bridgeOutModal = true;
      } else {
        vm.swapOnchainToOffchain();
      }
    },
    async swapOffChainItems(e) {
      e.preventDefault();
      const vm = this;
      if (vm.swapToOnchain) {
        vm.bridgeItemOutModal = true;
      } else {
        vm.swapOnchainItemToOffchain();
      }
    },
    async swapOffchainToOnchain() {
      console.log("Swap Off-Chain");
      const vm = this;

      // show modal
      vm.$store.state.transactionModal = "bridge";
      await vm.$store.commit("setTransactionState", {
        started: true,
        statusText: "Retrieving signature...",
      });

      try {
        // try to fetch the signature and stuff
        const { data: bridgeOutwardData } = await axios.post(
          `${vm.$apiUrl}/bridge/outward`,
          { amount: vm.amount },
          { headers: { Authorization: `Bearer ${vm.$store.state.token}` } }
        );
        const bridgeOutward = bridgeOutwardData.message;

        if (bridgeOutward.old === true) {
          await vm.$store.commit("setTransactionState", {
            statusText: `A claim for ${bridgeOutward.amount} $SLIME was made previously. Please claim it.`,
          });
        } else {
          // reduce balance since signature costs token
          vm.$store.state.user.claim.balance -= vm.amount;
          await vm.$store.commit("setTransactionState", {
            statusText: `Claiming ${vm.amount} $SLIME...`,
          });
        }

        // begin txn
        const sender = vm.$store.state.account;
        const contract = new vm.$store.state.web3.eth.Contract(
          BridgeAbi,
          process.env.VUE_APP_BRIDGE_CONTRACT_ADDRESS
        );
        const gasPrice = await vm.$store.state.web3.eth.getGasPrice();
        let contractFunction = contract.methods.claim(
          sender,
          new BigFloat(bridgeOutward.amount)
            .mul(10 ** 18)
            .floor()
            .toString(),
          bridgeOutward.oldBlock,
          bridgeOutward.newBlock,
          bridgeOutward.signature
        );
        const gas = await contractFunction.estimateGas({ from: sender });
        const gasAmped = new BigFloat(gasPrice)
          .mul(vm.$gasPriceBoost)
          .floor()
          .toString();
        console.log({ gasPrice, gas, gasAmped });
        await new Promise((resolve) => {
          contractFunction
            .send({
              from: sender,
              gas: parseInt(vm.$gasLimitBoost * gas),
              gasPrice: gasAmped,
            })
            .on("transactionHash", function (hash) {
              vm.$store.commit("setTransactionState", {
                statusText: `Claiming ${bridgeOutward.amount} $SLIME...`,
                hash,
              });
            })
            .on("receipt", function (receipt) {
              console.log("TX RECEIPT");
              console.log(receipt);
              vm.$store.state.tx.completed = true;
              vm.$store.dispatch("fetchUserRewards", vm.$store.state.account);
              console.log({
                tokens: vm.$store.state.user.tokens,
                bridgeOutward,
              });
              vm.$store.state.user.tokens +=
                bridgeOutward.amount * (1 - vm.taxRate);
              vm.amount = "";
              resolve(true);
            })
            .on("error", function (error) {
              vm.$store.state.tx.failed = true;
              vm.$store.state.tx.failedMsg =
                error.message || "Transaction Failed. Please try again.";
              resolve(false);
            });
        });
      } catch (err) {
        vm.handleTransactionError(err);
      }
    },
    async swapOnchainToOffchain() {
      const vm = this;
      const amount = vm.amount;
      console.log("Swap On-Chain");

      // show modal
      vm.$store.state.transactionModal = "bridge";
      await vm.$store.commit("setTransactionState", {
        started: true,
        statusText: ``,
      });
      try {
        // Authorizing $SLIME
        await vm.$store.commit("setTransactionState", {
          started: true,
          statusText: `Increasing token allowance...`,
        });
        let authorisationState = "Successfully authorised tokens";
        if (
          (await vm.$store.dispatch("increaseTokenAllowance", { amount })) !==
          true
        ) {
          authorisationState = "Tokens have been authorised";
        }

        // get the bridge inward data
        await vm.$store.commit("setTransactionState", {
          statusText: `${authorisationState}. Generating nonce...`,
        });
        const { data: bridgeInwardData } = await axios.post(
          `${vm.$apiUrl}/bridge/inward`,
          { amount },
          { headers: { Authorization: `Bearer ${vm.$store.state.token}` } }
        );
        await vm.$store.commit("setTransactionState", {
          statusText: `Nonce generated. Beginning swap for $xSLIME.`,
        });

        // begin txn
        const { id: nonce } = bridgeInwardData.message;
        const sender = vm.$store.state.account;
        const contract = new vm.$store.state.web3.eth.Contract(
          BridgeAbi,
          process.env.VUE_APP_BRIDGE_CONTRACT_ADDRESS
        );
        const gasPrice = await vm.$store.state.web3.eth.getGasPrice();
        let contractFunction = contract.methods.swap(
          new BigFloat(amount)
            .mul(10 ** 18)
            .floor()
            .toString(),
          nonce
        );
        const gas = await contractFunction.estimateGas({ from: sender });
        const gasAmped = new BigFloat(gasPrice)
          .mul(vm.$gasPriceBoost)
          .floor()
          .toString();
        console.log({ gasPrice, gas, gasAmped });
        await new Promise((resolve) => {
          contractFunction
            .send({
              from: sender,
              gas: parseInt(vm.$gasLimitBoost * gas),
              gasPrice: gasAmped,
            })
            .on("transactionHash", function (hash) {
              vm.$store.commit("setTransactionState", {
                statusText: `Swapping for ${amount} $xSLIME...`,
                hash,
              });
            })
            .on("receipt", function (receipt) {
              console.log("TX RECEIPT");
              console.log(receipt);
              vm.$store.commit("setTransactionState", {
                completed: true,
                statusText: `Swapped for ${amount} $xSLIME!\n\n$xSLIME may take up to 10 minutes to arrive.`,
              });
              vm.$store.state.user.tokens -= amount;
              vm.amount = "";
              resolve(true);
            })
            .on("error", function (error) {
              vm.$store.state.tx.failed = true;
              vm.$store.state.tx.failedMsg =
                error.message || "Transaction Failed. Please try again.";
              resolve(false);
            });
        });
      } catch (err) {
        vm.handleTransactionError(err);
      }
    },
    async swapOffchainItemToOnchain() {
      console.log("Swap Item Off-Chain");
      const vm = this;

      // show modal
      vm.$store.state.transactionModal = "bridge_item";
      await vm.$store.commit("setTransactionState", {
        started: true,
        statusText: "Retrieving signature...",
      });

      try {
        // tokens quantity
        console.log(vm.selection);

        // try to fetch the signature and stuff
        const { data: bridgeOutwardData } = await axios.post(
          `${vm.$apiUrl}/bridge/item/outward`,
          {
            tokens: vm.selection.map((e) => e.tokenId),
            quantity: vm.selection.map((e) => e.quantity),
          },
          { headers: { Authorization: `Bearer ${vm.$store.state.token}` } }
        );
        const bridgeOutward = bridgeOutwardData.message;

        if (bridgeOutward.old === true) {
          await vm.$store.commit("setTransactionState", {
            statusText: `An item claim was made previously. Please claim it.`,
          });
        } else {
          // reduce balance since signature costs token
          // vm.$store.state.user.claim.balance -= vm.amount; // @todo
          await vm.$store.commit("setTransactionState", {
            statusText: `Claiming items...`,
          });
        }

        // begin txn
        const sender = vm.$store.state.account;
        const contract = new vm.$store.state.web3.eth.Contract(
          BridgeSquishiverseItem,
          process.env.VUE_APP_BRIDGE_ITEM_CONTRACT_ADDRESS
        );
        const gasPrice = await vm.$store.state.web3.eth.getGasPrice();
        let contractFunction = contract.methods.claim(
          sender,
          bridgeOutward.tokens,
          bridgeOutward.quantity,
          bridgeOutward.oldBlock,
          bridgeOutward.newBlock,
          bridgeOutward.signature
        );
        const gas = await contractFunction.estimateGas({ from: sender });
        const gasAmped = new BigFloat(gasPrice)
          .mul(vm.$gasPriceBoost)
          .floor()
          .toString();
        console.log({ gasPrice, gas, gasAmped });
        await new Promise((resolve) => {
          contractFunction
            .send({
              from: sender,
              gas: parseInt(vm.$gasLimitBoost * gas),
              gasPrice: gasAmped,
            })
            .on("transactionHash", function (hash) {
              vm.$store.commit("setTransactionState", {
                statusText: `Claiming items...`,
                hash,
              });
            })
            .on("receipt", function (receipt) {
              console.log("TX RECEIPT");
              console.log(receipt);
              vm.$store.commit("setTransactionState", {
                statusText: `${bridgeOutward.quantity} items have been claimed...`,
                completed: true,
              });
              vm.$store.dispatch("fetchUserInventory");
              vm.amount = "";
              vm.selection = [];
              resolve(true);
            })
            .on("error", function (error) {
              vm.$store.state.tx.failed = true;
              vm.$store.state.tx.failedMsg =
                error.message || "Transaction Failed. Please try again.";
              resolve(false);
            });
        });
      } catch (err) {
        console.log(err);
        vm.handleTransactionError(err);
      }
    },
    async swapOnchainItemToOffchain() {
      const vm = this;
      console.log("Swap Item On-Chain");

      // show modal
      vm.$store.state.transactionModal = "bridge_item";
      await vm.$store.commit("setTransactionState", {
        started: true,
        statusText: ``,
      });
      try {
        // Authorizing $SLIME
        await vm.$store.commit("setTransactionState", {
          started: true,
          statusText: `Authorising bridge to transfer items...`,
        });
        let authorisationState = "Successfully authorised bridge";
        if ((await vm.$store.dispatch("authoriseBurn")) !== true) {
          authorisationState = "Bridge has been authorised";
        }

        // tokens and items
        const tokens = vm.selection.map((e) => e.tokenId);
        const quantity = vm.selection.map((e) => e.quantity);

        // get the bridge inward data
        await vm.$store.commit("setTransactionState", {
          statusText: `${authorisationState}. Generating nonce...`,
        });
        const { data: bridgeInwardData } = await axios.post(
          `${vm.$apiUrl}/bridge/item/inward`,
          { tokens, quantity },
          { headers: { Authorization: `Bearer ${vm.$store.state.token}` } }
        );
        await vm.$store.commit("setTransactionState", {
          statusText: `Nonce generated. Beginning swap for items.`,
        });

        // begin txn
        const { id: nonce } = bridgeInwardData.message;
        const sender = vm.$store.state.account;
        const contract = new vm.$store.state.web3.eth.Contract(
          BridgeSquishiverseItem,
          process.env.VUE_APP_BRIDGE_ITEM_CONTRACT_ADDRESS
        );
        const gasPrice = await vm.$store.state.web3.eth.getGasPrice();
        let contractFunction = contract.methods.swap(tokens, quantity, nonce);
        const gas = await contractFunction.estimateGas({ from: sender });
        const gasAmped = new BigFloat(gasPrice)
          .mul(vm.$gasPriceBoost)
          .floor()
          .toString();
        console.log({ gasPrice, gas, gasAmped });
        await new Promise((resolve) => {
          contractFunction
            .send({
              from: sender,
              gas: parseInt(vm.$gasLimitBoost * gas),
              gasPrice: gasAmped,
            })
            .on("transactionHash", function (hash) {
              vm.$store.commit("setTransactionState", {
                statusText: `Swapping items...`,
                hash,
              });
            })
            .on("receipt", function (receipt) {
              console.log("TX RECEIPT");
              console.log(receipt);
              vm.$store.commit("setTransactionState", {
                completed: true,
                statusText: `Swapped ${tokens.length} items!\n\nItems may take up to 10 minutes to arrive.`,
              });
              vm.selection = [];
              vm.$store.dispatch("fetchUserInventory");

              resolve(true);
            })
            .on("error", function (error) {
              vm.$store.state.tx.failed = true;
              vm.$store.state.tx.failedMsg =
                error.message || "Transaction Failed. Please try again.";
              resolve(false);
            });
        });
      } catch (err) {
        console.log(err);
        vm.handleTransactionError(err);
      }
    },
    handleTransactionError(err) {
      const vm = this;
      vm.$store.state.tx.failed = true;
      if (err.response) {
        const { message } = err.response.data;
        vm.$store.state.tx.failedMsg =
          message || "Transaction failed, unknown error";
        return;
      }
      try {
        const message = /execution reverted:\s(.*)/.exec(err.message)[1];
        vm.$store.state.tx.failedMsg = message;
      } catch (regerr) {
        const possibleError = err.message || "";
        if (possibleError.includes("insufficient funds")) {
          vm.$store.state.tx.failedMsg = "Not enough funds";
        } else {
          vm.$store.state.tx.failedMsg = "Transaction failed, unknown error";
        }
      }
    },
    selectItem(item) {
      const vm = this;
      if (vm.selectedItem(item)) {
        vm.selection = [...vm.selection].filter(
          (e) => e.tokenId !== item.tokenId
        );
      } else {
        vm.selection = [...vm.selection, item];
      }
    },
    selectedItem(item) {
      const vm = this;
      return vm.selection.map((e) => e.tokenId).includes(item.tokenId);
    },
  },
  components: {
    Card,
    Button,
    BridgeOutModal,
    BridgeItemOutModal,
    BridgeItem,
    BridgeLoot,
  },
  data() {
    return {
      amount: "",
      taxRate: 0.07,

      swapToOnchain: false,
      bridgeOutModal: null,

      selection: [],
      bridgeItemOutModal: null,
    };
  },
};
</script>

<style lang="scss">
.bridge {
  min-height: calc(100vh - 200px);

  > .container {
    padding: 2rem;
    h3 {
      margin: 0 0 2rem 0;
    }
    .disclaimer {
      margin-top: 2rem;
      text-align: center;
      font-weight: 600;
      font-size: $font-xxxs;
    }
    .item-container {
      .onchain,
      .offchain {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 2rem;
        gap: 1.5rem;
      }
      .no-items {
        grid-column: 1 / span 4;
        text-align: center;
        margin: 4rem 0 3rem 0;
      }
      h3 {
        grid-column: 1 / span 4;
        text-align: center;
      }
    }
    .swap {
      display: grid;
      width: 100%;

      .card {
        .card-title {
          font-size: $font-lg;
          color: $green;
        }
      }

      .coin-container {
        text-align: center;
        margin-bottom: 1rem;
        .coin {
          width: 64px;
        }
      }
      .form-group {
        display: flex;
        flex-direction: row;
        text-align: left;

        label {
          margin: 0.5rem 0 0.5rem 0;
        }

        input:disabled {
          opacity: 0.8;
          cursor: not-allowed;
        }

        input[type="number"] {
          -moz-appearance: textfield;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }

        button {
          height: 50px;
          border: 3px solid $black;
          box-shadow: none !important;
          // @include threedee-box(3px, transparent);
        }

        .form-input {
          width: 100%;
          position: relative;
          margin-right: 0.5rem;
          input {
            width: 100%;
            color: $black;
            padding: 0.75rem 1rem;
            border-radius: $radius-md;
            font-size: $font-xs;
            border: 3px solid $black;
            box-sizing: border-box;
          }
          .max {
            position: absolute;
            right: 1rem;
            bottom: 0.75em;
            cursor: pointer;
            opacity: 0.8;
          }
        }
      }

      .chain-select {
        // width: 100%;
        width: 100px;
        text-align: center;
      }

      .arrow {
        font-size: $font-md;
        text-align: center;
        margin-top: 1rem;

        > span {
          color: $black;
          display: inline-block;
        }
      }

      .balance-down,
      .receive-down {
        margin-top: 1rem;
      }

      .balance-down,
      .balance-up {
        opacity: 0.75;
        font-weight: normal;
      }

      p {
        text-align: center;
      }

      .l1 {
        color: #37367b;
      }

      .l2 {
        color: $pink-hot;
      }

      button {
        margin-bottom: 1rem;
        background: $blue;
        &.chain {
          background: $yellow;
          padding: 0;
          font-size: $font-xs;
          font-weight: 600;
          @include threedee-box(2px, $black);
          // display: inline-block;
          // max-width: 50px;
          white-space: nowrap;
          margin: 0;
        }
      }

      .swap-cta {
        display: grid;
        grid-template-columns: 80% 20%;
        button {
          @include threedee-box(3px, $black);
          margin: 1rem 0;
          width: unset;
          &:first-child {
            margin-right: 1rem;
          }
          &:hover {
            transform: scale(1.02);
          }
        }
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .bridge {
    > .container {
      .item-container {
        .onchain,
        .offchain {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
  }
}
@media screen and (min-width: $screen-md) {
  .bridge {
    > .container {
      .swap {
        grid-template-columns: repeat(3, 1fr);
        .card {
          grid-column-start: 2;
        }

        &.swap-items {
          grid-template-columns: repeat(6, 1fr);
          .card {
            grid-column: 2 / span 4;
          }
        }
      }
    }
  }
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>