<template>
  <div class="marketplace">
    <WhitelistModal
      v-if="whitelistModal !== null"
      :project="whitelistModal"
      @closePrompt="whitelistModal = null"
    />
    <OrderInfoModal
      v-if="orderInfoModal !== null"
      @closePrompt="orderInfoModal = null"
      @submit="submittedOrderInfo"
    />
    <div class="container">
      <div class="sidebar">
        <h3 class="categories">{{ $t("marketplace.categories") }}</h3>
        <Card type="list">
          <div
            class="list-item"
            v-for="(category, index) in filteredCategories"
            v-bind:class="{ active: category.id === selectedCategory }"
            @click="selectedCategory = category.id"
            :key="index"
          >
            {{ category.name }}
            <span class="badge" v-if="category.active > 0">
              {{ category.active }}
            </span>
          </div>
        </Card>
        <h3 class="filters">{{ $t("marketplace.filters_all") }}</h3>
        <Card type="list">
          <div
            class="list-item"
            v-bind:class="{ active: selectedFilter === 'all' }"
            @click="selectedFilter = 'all'"
          >
            {{ $t("marketplace.filters.all") }}
          </div>
          <div
            class="list-item"
            v-bind:class="{ active: selectedFilter === 'active' }"
            @click="selectedFilter = 'active'"
          >
            {{ $t("marketplace.filters.active") }}
          </div>
          <div
            class="list-item"
            v-bind:class="{ active: selectedFilter === 'newest' }"
            @click="selectedFilter = 'newest'"
          >
            {{ $t("marketplace.filters.newest") }}
          </div>
          <div
            class="list-item"
            v-bind:class="{ active: selectedFilter === 'oldest' }"
            @click="selectedFilter = 'oldest'"
          >
            {{ $t("marketplace.filters.oldest") }}
          </div>
          <div
            class="list-item"
            v-bind:class="{ active: selectedFilter === 'priceup' }"
            @click="selectedFilter = 'priceup'"
          >
            {{ $t("marketplace.filters.lowest_price") }}
          </div>
          <div
            class="list-item"
            v-bind:class="{ active: selectedFilter === 'pricedown' }"
            @click="selectedFilter = 'pricedown'"
          >
            {{ $t("marketplace.filters.highest_price") }}
          </div>
        </Card>
      </div>
      <div class="products">
        <!-- <div class="breadcrumbs">
          <h3 class="categories">{{ categorySelected.name }}</h3>
        </div> -->
        <div v-if="products === null" class="product-loading">
          <h3>{{ $t("marketplace.loading_marketplace") }}</h3>
        </div>
        <div v-else-if="filteredProducts.length <= 0" class="product-loading">
          <h3>{{ $t("generic.nothing_to_show") }}</h3>
        </div>
        <div v-else class="product-grid">
          <ProductItem
            v-for="product in filteredProducts"
            :product="product"
            :key="product.id"
            @click="onSelect"
            @whitelistOf="whitelistOf"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Card from "../ui/Card.vue";
import ProductItem from "./ProductItem.vue";
import WhitelistModal from "../MintModal/WhitelistModal.vue";
import OrderInfoModal from "../MintModal/OrderInfoModal.vue";
import Categories from "../../data/Categories.json";

export default {
  name: "Marketplace",
  props: {},
  computed: {
    filteredCategories() {
      const vm = this;
      return vm.categories.sort((a, b) => a.priority - b.priority);
    },
    products() {
      return this.$store.state.products;
    },
    categorySelected() {
      const vm = this;
      return vm.filteredCategories.filter(
        (c) => c.id === vm.selectedCategory
      )[0];
    },
    selection() {
      return this.$store.state.productSelection;
    },
    filteredProducts() {
      let vm = this;
      let products = vm.products || [];
      if (vm.selectedFilter === "active") {
        products = products.filter((p) => vm.isActive(p));
      } else if (vm.selectedFilter === "newest") {
        products = products.sort((p1, p2) => {
          if (new Date(p1.createdAt) - new Date(p2.createdAt) >= 0) {
            return -1;
          }
          return 1;
        });
      } else if (vm.selectedFilter === "oldest") {
        products = products.sort((p1, p2) => {
          if (new Date(p1.createdAt) - new Date(p2.createdAt) >= 0) {
            return 1;
          }
          return -1;
        });
      } else if (vm.selectedFilter === "pricedown") {
        products = products.sort((a, b) => b.price - a.price);
      } else if (vm.selectedFilter === "priceup") {
        products = products.sort((a, b) => a.price - b.price);
      } else {
        products = products.sort(
          (a, b) => new Date(a.expiresAt) - new Date(b.expiresAt)
        );
      }
      // active should always be first
      return products
        .sort((a, b) =>
          vm.isActive(a) === vm.isActive(b) ? 0 : vm.isActive(a) ? -1 : 1
        )
        .filter((p) =>
          p.categories.map((c) => c.id).includes(vm.selectedCategory)
        );
    },
    // calculateCost() {
    //   const vm = this;
    //   return (vm.products || [])
    //     .filter((p) => vm.selectedProduct(p))
    //     .reduce((s, a) => s + a.price, 0);
    // },
  },
  methods: {
    selectedProduct(product) {
      const vm = this;
      return vm.selection && vm.selection.id === product.id;
    },
    onSelect(product, quantity) {
      const vm = this;
      vm.$store.commit("setProductSelection", product);
      vm.purchase(quantity);
    },
    submittedOrderInfo(data) {
      const vm = this;
      vm.orderInfo = data;
      vm.orderInfoModal = null;
    },
    async waitForOrderInfo() {
      const vm = this;
      vm.orderInfoModal = true;
      while (vm.orderInfo === null && vm.orderInfoModal === true) {
        // console.log("Waiting For Order Info");
        await new Promise((r) => setTimeout(r, 500));
      }
      return vm.orderInfo !== null;
    },
    isWhitelistProduct(product) {
      return [1, 2, 3, 4, 5, 6] // hardcoded category id
        .some((c) => product.categories.map((pc) => pc.id).includes(c));
    },
    async purchase(quantity) {
      const vm = this;

      // enable modal
      vm.$store.state.transactionModal = "marketplace";
      vm.$store.state.tx.started = true;

      if (vm.$store.state.token === null) {
        vm.$store.state.tx.statusText = "Authenticating your wallet...";
      } else {
        vm.$store.state.tx.statusText =
          "Please sign the purchase in your wallet";
      }

      try {
        if (
          !(await vm.$store.dispatch("authenticate", { action: "marketplace" }))
        ) {
          throw { message: "Unable to authenticate wallet" };
        }

        // wait for order info
        if (
          vm.isWhitelistProduct(vm.selection) &&
          !(await vm.waitForOrderInfo())
        ) {
          throw { message: "Did not fill order information" };
        }

        // calculate the cost
        const cost = vm.selection.price * quantity;

        // sign a signature that will make them spend
        vm.$store.state.tx.statusText =
          "Successful authentication!\n\nPlease sign the purchase in your wallet";
        const msg = `I want to make a purchase for ${cost} $xSLIME.`;
        const signature = await vm.$store.state.web3.eth.personal.sign(
          msg,
          vm.$store.state.account,
          ""
        );

        // post the order
        const { data } = await axios.post(
          `${vm.$apiUrl}/order`,
          {
            product: vm.selection.id,
            quantity,
            signature,
            ...vm.orderInfo,
          },
          { headers: { Authorization: `Bearer ${vm.$store.state.token}` } }
        );

        const { message: order } = data;
        vm.$store.state.user.claim.balance -= order.total;
        vm.$store.state.productSelection = null;
        vm.$store.state.tx.completed = true;
        vm.$store.state.tx.statusText = `Purchase for ${order.total} $xSLIME successfully processed!\n\nOrder ID: ${order.id}`;
        vm.$store.dispatch("loadProducts");
      } catch (err) {
        vm.$store.state.tx.failed = true;
        if (err.response) {
          const { message } = err.response.data;
          vm.$store.state.tx.failedMsg = message;
        } else {
          vm.$store.state.tx.failedMsg =
            err.message || "Transaction failed, unknown error";
        }
        console.log(err);
      }
      vm.orderInfo = null;
      vm.orderInfoModal = null;
    },
    whitelistOf(product) {
      const vm = this;
      vm.whitelistModal = product;
    },
    isActive(product) {
      const hasExpired =
        product.expiresAt !== null &&
        new Date(product.expiresAt) - new Date() <= 0;
      return (product.supply === null || product.remaining > 0) && !hasExpired;
    },
    async calculateActiveProducts() {
      const vm = this;
      vm.categories = vm.categories.map((c) => {
        const active = vm.products.filter(
          (p) =>
            vm.isActive(p) && p.categories.map((pc) => pc.id).includes(c.id)
        ).length;
        return Object.assign(c, { active });
      });
      console.log(vm.categories);
    },
  },
  components: {
    Card,
    ProductItem,
    WhitelistModal,
    OrderInfoModal,
  },
  async mounted() {
    const vm = this;
    await vm.$store.dispatch("loadProducts");
    vm.calculateActiveProducts();
  },
  data() {
    return {
      categories: Categories,

      selectedCategory:
        Categories.sort((a, b) => a.priority - b.priority)[0]?.id || 1,
      selectedFilter: "all",

      whitelistModal: null,

      orderInfoModal: null,
      orderInfo: null,
    };
  },
};
</script>

<style lang="scss">
.marketplace {
  min-height: 100vh;

  > .container {
    display: grid;
    padding: 2rem;
    h3 {
      margin: 0 0 2rem 0;
    }
    .sidebar {
      padding-bottom: 2rem;
      h3 {
        text-align: center;
        &.categories {
          margin-bottom: 1rem;
          color: $white;
        }
        &.filters {
          margin-top: 2rem;
          margin-bottom: 1rem;
        }
      }
    }
    .products {
      margin-bottom: 5rem;
      .product-loading {
        text-align: center;
      }
      .product-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
        .card {
          padding: 0;
        }
      }
    }
    .dialog {
      position: fixed;
      bottom: 2rem;
      left: 0;
      right: 0;
      z-index: 9999;
      pointer-events: none;
      .card {
        width: calc(100% - 4rem);
        margin: 0 2rem;
        pointer-events: all;

        &-body {
          background: rgba($pink, 0.5);
          // background: rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(5px);
          @include threedee-box(3px, $pink-dark);
        }

        button {
          margin-top: 0;
          margin-bottom: 0.5rem;
          background: $pink-hot;
          @include threedee-box(3px, $pink-dark);
          color: $white;
          &:hover {
            transform: scale(1.025);
          }
        }
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .marketplace {
    > .container {
      h3 {
      }
      .sidebar {
        h3.categories {
        }
      }
      .products {
        .product-grid {
          grid-template-columns: repeat(2, 1fr);
          .card {
          }
        }
      }
      .dialog {
        bottom: 6rem;
        .card {
          width: 600px;
          margin: 0 auto;
        }
      }
    }
  }
}
@media screen and (min-width: $screen-md) {
  .marketplace {
    padding-top: 2rem;
    > .container {
      padding: 0;
      grid-template-columns: 20% 80%;
      h3 {
      }
      .sidebar {
        padding-right: 1.5rem;
        h3 {
          text-align: left;
          &.categories {
          }
          &.filters {
          }
        }
      }
      .products {
        padding-left: 1.5rem;
        .product-loading {
        }
        .product-grid {
          grid-template-columns: repeat(3, 1fr);
          .card {
          }
        }
      }
      .dialog {
        .card {
          &-body {
          }
          button {
            &:hover {
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: $screen-lg) {
  .marketplace {
    > .container {
      h3 {
      }
      .sidebar {
        h3.categories {
        }
        h3.filters {
        }
      }
      .products {
        .product-loading {
        }
        .product-grid {
          grid-template-columns: repeat(4, 1fr);
          .card {
          }
        }
      }
      .dialog {
        .card {
          &-body {
          }
          button {
            &:hover {
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>